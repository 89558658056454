import ReactSelect from "react-select";
import { useFormContext } from "react-hook-form";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { channel_of_contact, CreateCorporateLeadType } from "./schema";
import { DateTimePicker } from "pages/Opportunities/dateandtime";
import { useEffect, useState } from "react";
import { AxiosRequestConfig } from "axios";
import { getAllStaff } from "service/apis/StaffDetailsapi";
import {
  getContactDetailById,
  getContactsPartnerList,
} from "service/apis/Opportunities";
import { useLocation } from "react-router-dom";
import { Option } from "pages/Opportunities/types";

const channelInteractionMap = {
  i_contacted: {
    meeting: 3,
    email: 2,
    phone: 1,
    in_person: 13,
  },
  corporate_contacted: {
    phone: 5,
    email: 6,
    in_person: 7,
  },
};

const Step3 = () => {
  const location = useLocation();
  const [linkedContactList, setLinkedContactList] = useState<Option[]>([]);
  const [date, setDate] = useState<Date>();
  const [staffs, setStaffs] = useState<Option[]>([]);
  const [contactPartner, setContactPartner] = useState<Option[]>([]);
  const methods = useFormContext<CreateCorporateLeadType>();

  const {
    corporate_type,
    contacted_type,
    channelofcontact_type,
    meeting_time,
    channel_of_interaction_id,
    meeting_members,
  } = methods.watch();

  useEffect(() => {
    const fetchData = async (apiFunc, setOptions, mapFunc) => {
      console.log("Fetching data for API:", apiFunc.name);
      const auth = localStorage.getItem("authToken");
      if (!auth) return;
      const authToken = JSON.parse(auth);
      const payload: AxiosRequestConfig = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
      try {
        const res = await apiFunc(payload);
        console.log(`Response from ${apiFunc.name}:`, res.data);
        if (res.data) setOptions(res.data.map(mapFunc));
      } catch (error) {
        console.error(`Error fetching data from ${apiFunc.name}:`, error);
      }
    };

    fetchData(getAllStaff, setStaffs, (staff) => ({
      label: `${staff.fname}`,
      value: staff.id,
    }));
    fetchData(getContactsPartnerList, setContactPartner, (contact) => ({
      label: contact.contactName,
      value: contact.id,
    }));
  }, []);

  useEffect(() => {
    // Set default contacted_type to "i_contacted"
    if (!contacted_type) {
      methods.setValue("contacted_type", "i_contacted", {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
    // Set default channelofcontact_type to "meeting"
    if (!channelofcontact_type) {
      const defaultChannelId = channelInteractionMap["i_contacted"]["meeting"];
      methods.setValue("channelofcontact_type", "meeting", {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      methods.setValue(
        "channel_of_interaction_id",
        defaultChannelId.toString(),
        {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        },
      );
    }
  }, []);

  const handleChannelChange = (tab: string) => {
    if (channelofcontact_type !== "meeting") {
      setDate(undefined);
      methods.setValue("meeting_time", "", {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      methods.setValue("meeting_members", [], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      methods.setValue("contacted_to", "", {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
    const channelId = channelInteractionMap[contacted_type]?.[tab] || 0;
    methods.setValue("channelofcontact_type", tab, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    methods.setValue("channel_of_interaction_id", channelId, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      const queryParams = new URLSearchParams(location.search);
      const c_id = queryParams.get("c_id");

      if (!c_id) return;

      const authToken = localStorage.getItem("authToken");
      if (!authToken) return;

      const payload = {
        headers: { Authorization: `Bearer ${JSON.parse(authToken)}` },
        data: { id: c_id },
      };

      try {
        const response = await getContactDetailById(payload);
        if (response?.data) {
          const finalUpdated = {
            label: response?.data?.contactName,
            value: response?.data?.id,
          };

          setLinkedContactList([finalUpdated]);
        }
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchCompanyDetails();
  }, [location.search]);

  return (
    <form className="max-w-[500px]">
      <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full">
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <RadioGroup
            className="flex w-full items-center gap-4"
            onValueChange={(value) => {
              methods.setValue(
                "contacted_type",
                value as "i_contacted" | "corporate_contacted",
                {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                },
              );
            }}
            value={contacted_type}
          >
            <label
              htmlFor="i_contacted"
              className={`flex w-full items-center rounded-lg border p-3 space-x-2 ${
                contacted_type === "i_contacted"
                  ? "focus-within:border-blue-700"
                  : ""
              }`}
            >
              <RadioGroupItem
                value="i_contacted"
                id="i_contacted"
                className="w-4 h-4"
              />
              <p>I contacted</p>
            </label>
            <label
              htmlFor="corporate_contacted"
              className={`flex w-full items-center rounded-lg border p-3 space-x-2 ${
                contacted_type === "corporate_contacted"
                  ? "focus-within:border-blue-700"
                  : ""
              }`}
            >
              <RadioGroupItem
                value="corporate_contacted"
                id="corporate_contacted"
                className="w-4 h-4"
              />
              <p>Corporate contacted</p>
            </label>
          </RadioGroup>
        </div>

        {contacted_type && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <Tabs
              value={channelofcontact_type}
              onValueChange={handleChannelChange}
            >
              <TabsList className="sm:flex-wrap gap-3 sm:mb-3 sm:justify-start">
                {channel_of_contact[contacted_type]?.map((tab) => (
                  <TabsTrigger
                    key={tab.value}
                    value={tab.value}
                    className={`py-1.5 px-[18px] h-[32px] rounded-lg ${
                      tab.value === channelofcontact_type
                        ? "bg-navbar-menu-bg text-blue-700"
                        : "bg-[#F1F1F1]"
                    }`}
                  >
                    {tab.label}
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>
          </div>
        )}

        {contacted_type &&
          channelofcontact_type === "meeting" &&
          corporate_type && (
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <label htmlFor="date" className="text-sm text-[#6B7280]">
                Select date and time
              </label>
              <DateTimePicker
                date={date}
                setDate={(newDate) => {
                  console.log("DateTimePicker - Date selected:", newDate);
                  setDate(newDate);
                  methods.setValue(
                    "meeting_time",
                    newDate?.toISOString() as string,
                    {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    },
                  );
                }}
              />

              <label
                htmlFor="meeting_members"
                className="text-sm text-[#6B7280]"
              >
                Search & add team members
              </label>
              <ReactSelect
                options={staffs}
                menuPlacement="auto"
                onChange={(selected) => {
                  const selectedIds = selected?.map((option) => option.value);
                  methods.setValue("meeting_members", selectedIds, {
                    // Save as array of IDs
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                }}
                value={staffs.filter((staff) =>
                  meeting_members?.includes(staff.value),
                )}
                placeholder="Add team members"
                isMulti
                className="w-full"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    zIndex: 0,
                    borderRadius: ".5rem",
                    padding: ".3rem",
                    borderColor: state.isFocused ? "#165FE3" : "#333333",
                    boxShadow: "none",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 1000,
                  }),
                }}
              />

              <label
                htmlFor="contacted_with"
                className="text-sm text-[#6B7280]"
              >
                Contacted With
              </label>
              <ReactSelect
                options={linkedContactList}
                menuPlacement="auto"
                onChange={(selected) => {
                  methods.setValue("contacted_to", selected?.value as string, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                }}
                value={linkedContactList.find(
                  (contact) => contact.value === methods.watch("contacted_to"),
                )}
                placeholder="Select contact"
                className="w-full"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    zIndex: 0,
                    borderRadius: ".5rem",
                    padding: ".3rem",
                    borderColor: state.isFocused ? "#165FE3" : "#333333",
                    boxShadow: "none",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 1000,
                  }),
                }}
              />
            </div>
          )}

        <label htmlFor="notes" className="text-sm font-medium text-black-label">
          Internal notes (optional)
        </label>
        <textarea
          name="notes"
          placeholder="Enter note"
          className={`border ${
            methods.formState.errors.root
              ? "border-red-500"
              : "border-black-label"
          } rounded-lg p-4 w-full`}
          onChange={(e) => {
            methods.setValue("notes", e.target.value, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
          }}
        />
      </div>
    </form>
  );
};

export default Step3;
