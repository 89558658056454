import React from "react";

import { Button, Img, Text } from "components";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const LogoutPage: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    cookies.remove("authToken");

    localStorage.removeItem("authToken");
    localStorage.removeItem("channel_id");
    localStorage.removeItem("child_channel_id");
    localStorage.removeItem("staff_id");
    localStorage.removeItem("owner_name");
    localStorage.removeItem("name");
    localStorage.removeItem("level");
    localStorage.removeItem("isEmployee");
    localStorage.removeItem("email");
    localStorage.removeItem("branch");
    localStorage.removeItem("password");
    localStorage.removeItem("logosrc");
  }, []);

  const handleLogout = () => {
    cookies.remove("authToken");
    localStorage.removeItem("authToken");
    localStorage.removeItem("channel_id");
    localStorage.removeItem("child_channel_id");
    localStorage.removeItem("staff_id");
    localStorage.removeItem("owner_name");
    localStorage.removeItem("name");
    localStorage.removeItem("level");
    localStorage.removeItem("isEmployee");
    localStorage.removeItem("email");
    localStorage.removeItem("branch");
    localStorage.removeItem("password");
    localStorage.removeItem("logosrc");
    navigate("/");
  };
  return (
    <>
      <div className="bg-gray-100 flex flex-col items-center justify-start mx-auto p-[141px] md:px-10 sm:px-5 w-[85%]">
        <div className="bg-white-A700 rounded-xl flex flex-col gap-[53px] items-center justify-start max-w-[944px] mx-auto p-[106px] md:px-10 sm:px-5 w-full">
          <Img
            className="h-[108px] w-[108px]"
            src="images/img_iconlock.svg"
            alt="iconlock"
          />
          <Text className="text-5xl sm:text-[38px] md:text-[44px] text-black-900 text-center">
            Successfully Logged Out
          </Text>
          <Button
            className="bg-transparent cursor-pointer font-inter mb-[7px] min-w-[96px] text-base text-blue-700_01 text-center"
            onClick={handleLogout}
          >
            Sign In again
          </Button>
        </div>
      </div>
    </>
  );
};

export default LogoutPage;
