import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreatePartnerLeadType } from "pages/CreatePartnerLead/schema";
import {
  partnerAcquisitionOpportunity,
  PartnerAcquisitionReqType,
} from "service/apis/Opportunities";
import { AppDispatch } from "redux/store";
import { AxiosError } from "axios";

interface PartnerAcquisitionState {
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: PartnerAcquisitionState = {
  loading: false,
  error: null,
  success: false,
};

const partnerAcquisitionSlice = createSlice({
  name: "partnerAcquisition",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
      state.loading = false;
      state.success = false;
    },
    setSuccess: (state, action: PayloadAction<boolean>) => {
      state.success = action.payload;
      state.loading = false;
      state.error = null;
    },
  },
});

export const { setLoading, setError, setSuccess } =
  partnerAcquisitionSlice.actions;

// Async thunk for submitting partner acquisition
export const submitPartnerAcquisition = createAsyncThunk(
  "partnerAcquisition/submit",
  async (payload: PartnerAcquisitionReqType["body"], { rejectWithValue }) => {
    try {
      const auth = localStorage.getItem("authToken");
      if (!auth) throw new Error("No auth token found");
      const authToken = JSON.parse(auth);

      const response = await partnerAcquisitionOpportunity({
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: payload,
      });

      return response.data;
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response || "An error occurred");
    }
  },
);

export default partnerAcquisitionSlice.reducer;
