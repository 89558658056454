import ReactSelect from "react-select";
import { useFormContext } from "react-hook-form";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { CreatePartnerLeadType, partner_subtype_options } from "./schema";
import { getCity, getState } from "service/apis/PartnerRegistrationapi";
import { selectOptionType } from "components/SelectBox";
import { useEffect, useState } from "react";

export function isStep1Valid(
  partner_type: string,
  partner_subtype: string,
  name: string,
) {
  // Check if partner_type is selected
  if (!partner_type) return false;

  // If partner_type is selected, check if partner_subtype is selected
  if (!partner_subtype) return false;

  // Check if name is filled
  if (!name) return false;

  // All conditions met
  return true;
}

export default function Step1({ handleNext }: { handleNext: () => void }) {
  const methods = useFormContext<CreatePartnerLeadType>();
  const [stateoptions, setStateOptions] = useState<selectOptionType[]>([]);
  const [cityoptions, setCityOptions] = useState<selectOptionType[]>([]);
  const [selectedState, setSelectedState] = useState<string | null>(null);
  const { partner_type, partner_subtype, name, partner_city, partner_state } =
    methods.watch();

  function fetchState() {
    getState({})
      .then((res) => {
        const states = res.data as Array<{ id: number; name: string }>;
        setStateOptions(
          states.map((state) => ({ label: state.name, value: state.id })),
        );
      })
      .catch(() => {});
  }

  function fetchCity(state_id: string | null) {
    if (!state_id) return;
    const req = { params: { state_id } }; // Corrected line to use state_id
    getCity(req)
      .then((res) => {
        const cities = res.data as Array<{ id: number; name: string }>;
        setCityOptions(
          cities.map((city) => ({ label: city.name, value: city.id })),
        ); // Use city.id for value
      })
      .catch(() => {});
  }

  useEffect(() => {
    fetchState();
  }, []);

  useEffect(() => {
    fetchCity(selectedState);
  }, [selectedState]);

  return (
    <form className="max-w-sm">
      <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${
              partner_type ? "after:h-28 after:border-blue-700" : "after:h-0"
            }  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label
            htmlFor="customer_contact_phone"
            className={`text-sm ${
              !partner_type ? "text-[#C4C4C4]" : "text-[#6B7280]"
            } `}
          >
            Select partner type
          </label>
          <RadioGroup
            className="flex w-full items-center gap-4"
            onValueChange={(e) => {
              methods.setValue("partner_type", e, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              });
            }}
            value={partner_type}
          >
            <label
              htmlFor="entity"
              className={`${
                partner_type === "entity"
                  ? " focus-within:border-blue-700 border-black-label"
                  : ""
              } flex w-full items-center rounded-lg border p-3 space-x-2`}
            >
              <RadioGroupItem
                value="entity"
                id="entity"
                className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
              />
              <p className="w-full">Entity</p>
            </label>
            <label
              htmlFor="freelancer"
              className={`${
                partner_type === "freelancer"
                  ? " focus-within:border-blue-700 border-black-label"
                  : ""
              } flex w-full items-center rounded-lg border p-3 space-x-2`}
            >
              <RadioGroupItem
                value="freelancer"
                id="freelancer"
                className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
              />
              <p className="w-full">Freelancer</p>
            </label>
          </RadioGroup>
        </div>
        {partner_type && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${
                partner_subtype
                  ? "after:h-16 after:border-blue-700"
                  : "after:h-0"
              }  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <Tabs
              className="flex rounded-lg gap-2"
              value={partner_subtype} // Controlled by partner_subtype state
              onValueChange={(value) => {
                methods.setValue("partner_subtype", value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
            >
              <TabsList className="sm:max-h-28 sm:h-full flex justify-start items-center gap-3 sm:flex-wrap">
                {partner_subtype_options[partner_type]?.map(
                  (tab: { label: string; value: string }) => (
                    <TabsTrigger
                      key={tab.value}
                      value={tab.value}
                      className={`py-1.5 px-[18px] text-sm h-[32px] font-normal rounded-lg ${
                        tab.value === partner_subtype
                          ? "bg-navbar-menu-bg text-blue-700"
                          : "text-black-label bg-[#F1F1F1]"
                      }`}
                    >
                      {tab.label}
                    </TabsTrigger>
                  ),
                )}
              </TabsList>
            </Tabs>
          </div>
        )}
        {partner_subtype && partner_type && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden  items-center top-0 flex-col after:right-10 absolute ${
                name ? "after:h-28 after:border-blue-700" : "after:h-0"
              } after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label htmlFor="entity_name" className="text-sm text-[#6B7280]">
              What is the name of entity?
            </label>
            <input
              type="text"
              onWheel={(e) => e.currentTarget.blur()}
              placeholder="What is the name of the entity?"
              id="entity_name"
              onChange={(e) => {
                methods.setValue("name", e.target.value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              value={name}
              className="border border-black-label h-12 rounded-lg p-4 w-full"
            />
          </div>
        )}
        {partner_subtype && partner_type && name && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden  items-center top-0 flex-col after:right-10 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label htmlFor="partner_state" className="text-sm text-[#6B7280]">
              Select partner state
            </label>
            <ReactSelect
              className="w-full"
              placeholder="Select the state"
              menuPlacement="auto"
              options={stateoptions}
              onChange={(e) => {
                setSelectedState(e?.value || null);
                methods.setValue("partner_state", e?.label || null, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              isClearable
              value={stateoptions.find(
                (option) => option.label === partner_state,
              )}
              name="state"
              id="state"
            />
            <label htmlFor="partner_city" className="text-sm text-[#6B7280]">
              Select partner city
            </label>
            <ReactSelect
              className="w-full"
              menuPlacement="auto"
              placeholder="Select the city"
              options={cityoptions}
              onChange={(e) => {
                methods.setValue("partner_city", e?.label || null, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              isClearable
              value={cityoptions.find(
                (option) => option.label === partner_city,
              )}
              name="city"
              id="city"
            />
          </div>
        )}
      </div>
    </form>
  );
}
