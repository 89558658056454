import Stepper from "components/Stepper/newstepper";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useEffect, useState } from "react";
import { createContactSteps } from "utils";
import ContactDetails from "./step1";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import OpportunityDetails from "./step2";
import InteractionDetails from "./step3";
import { step1Schema } from "./schema";
import { z } from "zod";
import { AxiosRequestConfig } from "axios";
import {
  CreateContactReqType,
  postCreateContact,
  postExistingOpportunity,
} from "service/apis/Opportunities";
import { toast, Toaster } from "sonner";
import { Spinner } from "components";
import { resetState } from "redux/features/CreateOpportunity";
import { useLocation, useNavigate } from "react-router-dom";
import DocPopup from "./popup/docpopup";
import { Separator } from "@/components/ui/separator";
import {
  channel_options_inbound,
  channel_options_outbound,
} from "pages/Opportunities/utils/featureRule";

export default function CreateContact() {
  const { state }: any = useLocation();
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);
  const [selectedMode, setSelectedMode] = useState<string[]>([]);
  const [expires, setExpires] = useState("1");
  const [channel, setChannel] = useState("whatsapp");
  const [popupnotes, setPopupNotes] = useState<string>("");
  const {
    product_list,
    product_purpose,
    assigned_to,
    remit_note,
    outlet_id,
    meeting_type,
    moneeflo_branch,
    moneeflo_branch_employee,
    team,
    team_member,
    product,
    who_contacted,
    medium_of_contact,
    pipelineType,
    refferal_campaign,
    refferal_source,
    partner_location,
    partner_id,
    partner_reffered,
    employee_reffered,
    contact_reffered,
    entityEmail,
    entityName,
    entityNumber,
    entityType,
    customer_contact_address,
    customer_contact_email,
    customer_contact_name,
    customer_contact_phone,
    partner_name,
    company_address,
    company_email,
    company_name,
    contact_person_name,
    contact_person_phone,
    contact_person_type,
  } = useAppSelector((state) => state.CreateOpportunity);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handlePrevious = () => {
    if (currentStep === 1) return;
    setCurrentStep((prev) => prev - 1);
    setErrors({});
  };

  const validateStep = (step: number) => {
    let isValid = false;
    const newErrors: Record<string, string> = {};

    switch (step) {
      case 1:
        try {
          if (pipelineType === "corporate") {
            step1Schema.parse({
              company_name,
              company_email,
              contact_person_name,
              contact_person_phone,
              contact_person_type,
            });
            isValid = true;
          } else if (pipelineType === "retail") {
            // step1Schema.pick({ company_email: true }).parse({
            //     company_email: customer_contact_email,
            // })
            isValid = true;
          } else if (pipelineType === "partner") {
            step1Schema
              .pick({ company_email: true, contact_person_phone: true })
              .parse({
                company_email: entityEmail,
                contact_person_phone: entityNumber,
              });
            isValid = true;
          }
        } catch (error) {
          if (error instanceof z.ZodError) {
            error.errors.forEach((err) => {
              newErrors[err.path[0]] = err.message;
            });
          }
        }
        break;
      // Add cases for other steps if needed
      // case 2:
      //     // Validate step 2
      //     break;
      // case 3:
      //     // Validate step 3
      //     break;
      default:
        isValid = true; // Default to true for steps without validation
    }

    setErrors(newErrors);
    return isValid;
  };

  const buttonState = () => {
    switch (currentStep) {
      case 1:
        if (
          pipelineType === "corporate" &&
          (!company_name ||
            !company_email ||
            !contact_person_name ||
            !contact_person_phone ||
            !contact_person_type)
        ) {
          return false;
        }
        if (pipelineType === "retail") {
          if (!product || !product_purpose) return false;
          return true;
        }
        if (
          pipelineType === "partner" &&
          (!entityName || !entityEmail || !entityNumber || !entityType)
        ) {
          return false;
        }
        return true;
      case 2:
        // if (!assigned_to) return false;
        return true;
      case 3:
        if (
          medium_of_contact === "refferal" &&
          refferal_source === "contacts" &&
          !contact_reffered
        )
          return false;
        if (!medium_of_contact || !who_contacted) return false;
        return true;
      default:
        return false;
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <ContactDetails errors={errors} />;
      case 2:
        return <OpportunityDetails errors={errors} />;
      case 3:
        return <InteractionDetails errors={errors} />;
      default:
        return null;
    }
  };

  const channel_of_interaction_id =
    who_contacted === "customer" && medium_of_contact === "refferal"
      ? refferal_source === "contacts"
        ? "20"
        : refferal_source === "partner"
          ? "12"
          : refferal_source === "staff"
            ? "18"
            : ""
      : who_contacted === "myself"
        ? meeting_type === "whatsapp"
          ? "4"
          : meeting_type === "other"
            ? "14"
            : channel_options_inbound
                .find((item) => medium_of_contact === item.label.toLowerCase())
                ?.value.toString() ?? ""
        : meeting_type === "whatsapp"
          ? "8"
          : meeting_type === "other"
            ? "15"
            : meeting_type === "instagram"
              ? "10"
              : meeting_type === "facebook"
                ? "17"
                : meeting_type === "linkedin"
                  ? "16"
                  : channel_options_outbound
                      .find(
                        (item) =>
                          medium_of_contact === item.label.toLowerCase(),
                      )
                      ?.value.toString() ?? "";

  const handleSubmit = async (type = "") => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const formData = new FormData();

    switch (pipelineType) {
      case "retail":
        // formData.append("contactEmail", customer_contact_email);
        // formData.append("contactName", customer_contact_name);
        // formData.append("contactNumber", customer_contact_phone);
        // formData.append("contactAddress", customer_contact_address);
        formData.append("contact_id", state?.id as string);
        formData.append("typeOfRefferal", refferal_source);
        // formData.append("campaign", refferal_campaign);
        formData.append("refferedBy_staff", employee_reffered);
        formData.append("refferedBy_contacts", contact_reffered);
        formData.append(
          "refferedBy_partner",
          medium_of_contact === "refferal" ? partner_reffered : "",
        );
        formData.append("pipelineType", "retail");
        formData.append("forex_branch", moneeflo_branch);
        formData.append("outlet_branch", outlet_id);
        formData.append("exp_time", expires);
        formData.append("note", popupnotes);
        selectedMode.forEach((mode) => {
          formData.append("doc_list[]", JSON.stringify({ filename: mode }));
        });
        formData.append("channel_of_interaction_id", channel_of_interaction_id);
        break;
      case "corporate":
        formData.append("company_name", company_name);
        formData.append("company_email", company_email);
        formData.append("contactName", contact_person_name);
        formData.append("contactNumber", contact_person_phone);
        formData.append("typeOfContact", contact_person_type);
        formData.append("contactEmail", "bdbbh@gmail.com");
        formData.append("contactAddress", company_address);
        formData.append("pipelineType", "coorporate");
        break;
      case "partner":
        formData.append("contactName", entityName);
        formData.append("contactEmail", entityEmail);
        formData.append("contactNumber", entityNumber);
        formData.append("typeOfPartner", entityType);
        formData.append("typeOfContact", "partner");
        formData.append("pipelineType", "partner");
        break;
      default:
        break;
    }
    // product_list.forEach((product) => {
    formData.append("channel", channel);
    formData.append(
      "items[]",
      JSON.stringify({
        subject: "",
        remarks: "",
        notes: remit_note,
        assignedTo: assigned_to,
        product_id: product_purpose,
      }),
    );
    // });

    const payload: AxiosRequestConfig<CreateContactReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: formData as any,
    };

    try {
      const res = (await postExistingOpportunity(payload)) as any;
      if (res.status === 200) {
        if (
          (res.data?.throwError as any)?.toLowerCase() ===
          "contact already exists"
        ) {
          toast.error("Contact already exists");
          return;
        }
        toast.success("Lead created successfully");
        setLoading(false);
        if (type === "prepareQuote") {
          dispatch(resetState());
          handlePrepareQuote(
            res?.data?.response?.id as string,
            res?.data?.response.interactions_id?.[0] as string,
          );
          return;
        }
      }
      dispatch(resetState());
      navigate(`/leads/contact/${state?.id}`);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Failed to create opportunity");
    }
  };

  const handleNext = () => {
    if (currentStep === createContactSteps.length) {
      handleSubmit();
      return;
    }
    if (validateStep(currentStep)) {
      setCurrentStep((prev) => prev + 1);
      setErrors({}); // Clear errors when moving to next step
    }
  };

  const handlePrepareQuote = (opp_id: string, interaction_id: string) => {
    if (product === "remittance") {
      navigate("/createremittancequotation", {
        state: {
          contact_name: state?.contact_name,
          contact_number: state?.contact_number,
          contact_email: state?.contact_email,
          product_id: product_purpose,
          product_name: "",
          opportunity_id: opp_id,
          interaction_id,
          remarks: "",
          meeting_time: "",
          meetingStaff_id: [],
          note: remit_note || "Quotation shared",
          channel_of_interaction_id: channel_of_interaction_id,
          partner_id:
            ((medium_of_contact === "refferal") && (refferal_source === "partner"))
              ? partner_reffered
              : "",
          partner_name: partner_name,
        },
      });
    }
    if (product === "forex") {
      navigate("/createforexquotation", {
        state: {
          contact_name: state?.contact_name,
          contact_number: state?.contact_number,
          contact_email: state?.contact_email,
          product_id: product_purpose,
          opportunity_id: opp_id,
          interaction_id,
          remarks: "",
          meeting_time: "",
          meetingStaff_id: [],
          note: remit_note || "Quotation shared",
          channel_of_interaction_id: channel_of_interaction_id,
        },
      });
    }
    return;
  };

  // useEffect(() => {
  //     return () => {
  //         // This function will run when the component unmounts
  //         dispatch(resetState());
  //     };
  // }, [dispatch]);

  return (
    <>
      {loading && <Spinner />}
      <div className="sm:p-1 flex flex-col font-inter items-start justify-start overflow-hidden mx-auto w-full">
        <div className="gap-5 w-full flex h-full flex-col">
          <div className=" flex items-center w-full justify-between border-b gap-2 px-5 pt-2.5 pb-5">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              <span className="capitalize">
                {createContactSteps.map((val) => val.name)[currentStep - 1]}
              </span>
            </h3>
            <AlertDialog>
              <AlertDialogTrigger>
                <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                  Cancel
                </button>
              </AlertDialogTrigger>
              <AlertDialogPortal>
                <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                  <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                    <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                      Do you want to{" "}
                      <span className="font-semibold text-blue-700">
                        discard
                      </span>{" "}
                      the lead?
                    </AlertDialogTitle>
                    <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                      This action cannot be undone. This will permanently cancel
                      the ongoing progress.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                    <AlertDialogCancel
                      className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                      asChild
                    >
                      <button>Close</button>
                    </AlertDialogCancel>
                    <AlertDialogAction
                      className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                      asChild
                    >
                      <button
                        className=""
                        onClick={() => {
                          dispatch(resetState());
                          navigate("/lead/contacts");
                        }}
                      >
                        Confirm
                      </button>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogPortal>
            </AlertDialog>
          </div>
          <div className="rounded-xl flex flex-col justify-between h-full gap-5 w-full sm:px-0 px-5 py-2.5 overflow-auto">
            <Stepper
              steps={createContactSteps.map((val) => val.name)}
              currentStep={currentStep - 1}
            />
            <div className="flex relative flex-col gap-5 w-full overflow-y-auto flex-grow">
              {renderStep()}
            </div>
            <div className="flex gap-3 sm:flex-col sm:items-start">
              {currentStep !== 1 && (
                <button
                  type="button"
                  onClick={handlePrevious}
                  className={`px-4 py-[11px] w-fit text-sm font-semibold rounded-lg text-blue-700 ${true ? "" : "opacity-40"}`}
                >
                  Previous
                </button>
              )}
              {["forex", "remittance"].includes(product) &&
                currentStep === 3 && (
                  <button
                    onClick={() => handleSubmit("prepareQuote")}
                    disabled={!buttonState()}
                    className="px-4 py-[11px] text-sm font-semibold rounded-lg text-blue-700 border-blue-700 border disabled:opacity-40"
                  >
                    Prepare Quote
                  </button>
                )}
              <button
                type="button"
                disabled={!buttonState()}
                onClick={handleNext}
                className={`px-4 py-[11px] w-fit text-sm font-semibold rounded-lg border ${pipelineType === "retail" && currentStep === 3 && product !== "remittance" ? "border-blue-700 text-blue-700" : "bg-blue-700 text-white-A700"} disabled:opacity-40`}
              >
                {currentStep === createContactSteps.length ? "Submit" : "Next"}
              </button>
              {pipelineType === "retail" &&
                currentStep === 3 &&
                product !== "remittance" && (
                  <Separator
                    className="bg-gray-border-gray-lite sm:hidden"
                    orientation="vertical"
                  />
                )}
              {currentStep === 3 &&
                pipelineType === "retail" &&
                product !== "remittance" && (
                  <DocPopup
                    buttonState={buttonState}
                    onSubmit={handleSubmit}
                    selectedMode={selectedMode}
                    setSelectedMode={setSelectedMode}
                    expires={expires}
                    setExpires={setExpires}
                    popupnotes={popupnotes}
                    setPopupNotes={setPopupNotes}
                    channel={channel}
                    setChannel={setChannel}
                  />
                )}
            </div>
          </div>
        </div>
        <Toaster theme="dark" position="top-right" />
      </div>
    </>
  );
}
