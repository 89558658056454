import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CorporateContactType } from "@/lib/validations/schema";
import { ColumnDef } from "@tanstack/react-table";
import { AxiosRequestConfig } from "axios";
import { company_contact_status, partner_contact_status } from "common/filters";
import { Spinner } from "components";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import NewCustomersPage from "pages/Customers/newcustomer";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCompanyLeads, GetCompanyRequestType } from "service/api";
import { Toaster } from "sonner";
import { IPage } from "./partner_contact";

const allcolumns: ColumnDef<CorporateContactType>[] = [
  {
    id: "id",
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => <div className="">{row.getValue("id")}</div>,
    enableHiding: false,
    enableSorting: false,
    filterFn: "includesString",
  },
  {
    id: "name",
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Company Name" />
    ),
    filterFn: "includesString",
    enableSorting: false,
  },
  {
    id: "type",
    accessorKey: "type",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-center"
        column={column}
        title="Type"
      />
    ),
    cell: ({ row }) => {
      return <p className=" text-center w-full">{row.getValue("type")}</p>;
    },
    enableSorting: false,
  },
  {
    id: "location",
    accessorKey: "location",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-center"
        column={column}
        title="Location"
      />
    ),
    cell: ({ row }) => {
      return <p className=" text-center w-full">{row.getValue("location") || 'N/A'},{row.getValue("location") || 'N/A'}</p>;
    },
    enableSorting: false,
  },
  {
    id: "assignedTo",
    accessorKey: "assignedTo",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-center"
        column={column}
        title="Assigned To"
      />
    ),
    cell: ({ row }) => {
      return (
        <p className=" text-center w-full">{row.getValue("assignedTo")}</p>
      );
    },
    enableSorting: false,
  },
  {
    id: "lead_status",
    accessorKey: "lead_status",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-center"
        column={column}
        title="Status"
      />
    ),
    cell: ({ row }) => {
      const partnerStatus = row.original.status;
      console.log("partnerStatus", partnerStatus);
      const leadStatus = row.getValue("lead_status");

      const statusToUse =
        partnerStatus === "Active"
          ? partnerStatus
          : leadStatus || partnerStatus;

      const status = company_contact_status.find(
        (status) => status.value === statusToUse,
      );

      if (!status) {
        return null;
      }

      return (
        <p
          style={{ backgroundColor: status.bgColor, color: status.textColor }}
          className="py-[3px] text-sm leading-4 text-center font-medium max-w-[160px]  px-2 mx-auto rounded-md"
        >
          {status.label}
        </p>
      );
    },
    enableSorting: false,
  },
];

const tabData = [
  { label: "All", value: "all", enabled: true },
  { label: "Clients", value: "clients", enabled: true },
];

export default function CorporateContact() {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<CorporateContactType[]>([]);
  const [activeTab, setActiveTab] = useState("all");
  const [statusFilter, setStatusFilter] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageDetails, setPageDetails] = useState<IPage>();
  const navigate = useNavigate();

  const onRowClick = (row: any) => {
    if (row?.partnerContactStatus === "lead") {
      navigate(`/leads/corporate/${row?.id}`);
    } else {
      navigate(`#`);
    }
  };

  const columns = useMemo(() => {
    return allcolumns.filter((column) => {
      if (activeTab === "all") {
        return !["contactStatus"].includes(column?.id as string);
      }
      return allcolumns;
    });
  }, [activeTab]);

  const fetchResults = (page: number, per_page: number) =>
    // searchTerm: string,
    // page: number | string,
    // perPage: string | number,
    // type: string,
    // status: string,
    {
      setLoading(true);

      // get auth token from localstorage
      const auth: string | null = localStorage.getItem("authToken");
      if (auth === null) return null;
      const authToken = JSON.parse(auth);

      const payload: AxiosRequestConfig<GetCompanyRequestType> = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          paging: {
            page: page,
            per_page: per_page,
            offset: 0,
          },
        },
      };
      if (activeTab === "clients") {
        payload.params = { isActive: true };
      }
      getCompanyLeads(payload)
        .then((response) => {
          console.log(response);
          setLoading(false);
          setCompany(() => {
            return (
              response?.data?.items?.map(
                (
                  result: Partial<{
                    id: number;
                    company_name: string;
                    address: string | null;
                    email: string;
                    phone: string | null;
                    contacts_id: number[];
                    rm_id: number;
                    status: string;
                    type: string;
                    lead_status: string;
                    state: string;
                    extra_detail: {
                      channel_of_interaction_id: number;
                      service: string[];
                      meeting_staff: string[];
                      _channel_of_interaction: string[];
                    };
                    rm_staff: {
                      id: number;
                      fname: string;
                    };
                  }>,
                ) => {
                  return {
                    id: result.id,
                    name: result.company_name,
                    location: result?.city,
                    state: result?.state,
                    assignedTo: result?.rm_staff?.fname,
                    type: result.type,
                    partnerContactStatus: result.status,
                    status: result.status,
                    lead_status: result.lead_status,
                    staffName: "",
                    subType: "",
                  };
                },
              ) ?? []
            );
          });
          setPageDetails({
            pageTotal: response?.data?.pageTotal,
            curPage: response?.data?.curPage,
            itemsReceived: response?.data?.itemsReceived,
            itemsTotal: response?.data?.itemsTotal,
            nextPage: response?.data?.nextPage,
            offset: response?.data?.offset,
            perPage: response?.data?.perPage,
            prevPage: response?.data?.prevPage,
          });
        })
        .catch(() => {
          setLoading(false);
        });
    };
  useEffect(() => {
    fetchResults(page, perPage);
  }, [statusFilter, activeTab, page, perPage]);
  return (
    <>
      {loading && <Spinner />}
      <div className="flex h-full gap-10 py-5 px-6 sm:px-0 w-full flex-col overflow-hidden">
        <div className="flex gap-5 justify-between w-full items-center">
          <Tabs
            className="bg-navbar-menu-bg w-fit flex rounded-lg gap-2"
            value={activeTab}
            onValueChange={(val) => {
              if (val === "partners") {
                setStatusFilter("active");
              } else {
                setStatusFilter("");
              }
              setActiveTab(val);
            }}
          >
            <TabsList className="h-[44px]">
              {tabData
                .filter((tab) => true)
                .map((tab, index: number) => (
                  <TabsTrigger
                    key={index}
                    value={tab.value}
                    disabled={!tab.enabled}
                    className={`py-3 px-10 disabled:opacity-40 text-sm h-[44px] min-w-[150px] font-semibold ${
                      tab.value === activeTab
                        ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg"
                        : " text-black-label"
                    }`}
                  >
                    {tab.label}
                  </TabsTrigger>
                ))}
            </TabsList>
          </Tabs>
          {activeTab === "all" ? (
            <Link to={"/lead/createcontact/corporate"} className="sm:hidden">
              <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                New Corporate Lead
              </button>
            </Link>
          ) : (
            <Link to='/corporateregistration' className="sm:hidden">
              <button className="rounded-lg py-[11px]  px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                Register Corporate
              </button>
            </Link>
          )}
        </div>
        <div className="flex-1 relative overflow-auto">
          {loading && <Spinner />}
          {!activeTab ? (
            <NewCustomersPage />
          ) : (
            <DataTable
              columns={columns}
              data={company}
              onRowClick={(row) => onRowClick(row)}
              isToolbarVisible={true}
              isPaginationVisible={true}
              searchData={{
                placeholder: "Search by corporate name",
                columns: ["name", "mobile"],
              }}
              isPageCountVisible={false}
              defaultSorting={[{ id: "id", desc: true }]}
              onClickNextPage={() => setPage((page) => page + 1)}
              onClickPreviousPage={() => setPage((page) => page - 1)}
              totalPages={pageDetails?.pageTotal}
              currentPage={pageDetails?.curPage}
              canNextPage={!!pageDetails?.nextPage}
              canPreviousPage={!!pageDetails?.prevPage}
            />
          )}
        </div>
        {activeTab === "all" ? (
          <Link
            className="p-2.5 rounded-[20px] hidden sm:flex text-center border border-blue-700"
            to={"/lead/createcontact"}
          >
            <button className="rounded-lg py-[11px] px-4 border w-full bg-blue-700 text-white-A700 font-semibold text-sm">
              New Corporate Lead
            </button>
          </Link>
        ) : (
          <Link
            className="p-2.5 rounded-[20px] hidden sm:flex text-center border border-blue-700"
            to={"/corporateregistration"}
          >
            <button className="rounded-lg py-[11px] px-4 border w-full bg-blue-700 text-white-A700 font-semibold text-sm">
              Register Corporate
            </button>
          </Link>
        )}
        <Toaster position="top-right" />
      </div>
    </>
  );
}
