import { hr } from "date-fns/locale";

interface TabData {
  label: string;
  value: string;
  enabled: boolean;
}

export const tabData: TabData[] = [
  {
    label: "Retail",
    value: "retail",
    enabled: true,
  },
  {
    label: "Corporate",
    value: "corporate",
    enabled: false,
  },
  {
    label: "Partner",
    value: "partner",
    enabled: false,
  },
];

export const buttonData = {
  retail: {
    label: "+ Add Contact",
    enabled: true,
    href: "/lead/createcontact",
  },
  corporate: {
    label: "+ Add Corporate",
    enabled: false,
    href: "/lead/createcontact",
  },
  partner: {
    label: "+ Add Partner",
    enabled: true,
    href: "/lead/createcontact",
  },
};

export const placeholderData = {
  retail: "Search contact by name or mobile number",
  corporate: "Search contact by name or mobile number",
  partner: "Search contact by name",
};

export interface ContactData {
  name: string;
  contact: string;
  email: string;
  type: string;
  location: string;
  role: string;
  status: string;
  label?: string;
  value?: string;
  isPartner?: boolean;
  isCorporate?: boolean;
  companyName?: string;
  lead_status: string;
  partnerType: string;
  contactType: string;
  contactName: string;
  contactPhone: string;
}

// export const fakeData: ContactData[] = [
//   {
//     name: "John Doe",
//     contact: "1234567890",
//     email: "exaple@example.com",
//     type: "retail",
//     location: "Delhi",
//     role: "Sales",
//     status: "Active",
//   },
//   {
//     name: "Jane Doe",
//     contact: "1234567891",
//     email: "exaple@example.com",
//     type: "corporate",
//     location: "Mumbai",
//     role: "Marketing",
//     status: "Inactive",
//   },
//   {
//     name: "John Doyy",
//     contact: "1234567892",
//     email: "exaple@example.com",
//     type: "retail",
//     location: "Bangalore",
//     role: "HR",
//     status: "Active",
//   },
//   {
//     name: "Jane Deiyy",
//     contact: "1234567893",
//     email: "exaple@example.com",
//     type: "partner",
//     location: "Chennai",
//     role: "Admin",
//     status: "Inactive",
//   },
// ];
