import { AxiosPromise, AxiosRequestConfig } from "axios";
import { apis } from "service";

const create_contact_otp =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:vQ4fLKDn/createContactOTP";
const contactPrimary =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:vQ4fLKDn/makePrimary";
const verify_contact_otp =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:vQ4fLKDn/verifyOTP_contact";
const get_forex_doc_map =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:rBHQT4XV/getReq_forex_doc?uuid=7d445ca0-7de0-4d09-9af0-1a249bfd359f";
const post_submit_doc =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:rBHQT4XV/submit_doc_forex";

export type PostCreateContactOtpReqType = Partial<{
  phone_no: string;
  email: string;
  Name: string;
}>;

export type PostCreateContactOtpResponseType = Partial<{
  contactExist: boolean;
  otpsent: boolean;
}>;

export const postCreateContactOtp = (
  payload: AxiosRequestConfig<PostCreateContactOtpReqType>,
): AxiosPromise<PostCreateContactOtpResponseType> => {
  return apis.post(create_contact_otp, payload);
};

export type MakeContactPrimaryReqType = {
  contacts_id: number;
  primary: boolean;
};

export type MakeContactPrimaryResponseType = {
  message: string;
};

export const makeContactPrimary = (
  payload: MakeContactPrimaryReqType,
  authToken: string,
): AxiosPromise<MakeContactPrimaryResponseType> => {
  return apis.patch(contactPrimary, {
    data: payload,
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export type PostVerifyContactOtpReqType = Partial<{
  emailOTP: number;
  phoneOTP: number;
  name: string;
  email: string;
  phone: string;
}>;

export type PostVerifyContactOtpResponseType = Partial<{
  result1: string;
  authToken: string;
}>;

export const postVerifyContactOtp = (
  payload: AxiosRequestConfig<PostVerifyContactOtpReqType>,
): AxiosPromise<PostVerifyContactOtpResponseType> => {
  return apis.post(verify_contact_otp, payload);
};

export type GetForexDocMapReqType = Partial<{
  headers: {
    Authorization: string;
  };
  uuid: string;
}>;

export type GetForexDocMapResponseType = Partial<{}>;

export const getForexDocMap = (
  payload: AxiosRequestConfig<GetForexDocMapReqType>,
): AxiosPromise<GetForexDocMapResponseType> => {
  return apis.get(get_forex_doc_map, payload);
};

export type PostSubmitDocReqType = Partial<{
  headers: {
    Authorization: string;
  };
  uuid: string;
  consent: boolean;
  doc_list: {
    file: string;
    doc_id: number;
  }[];
}>;

export type PostSubmitDocResponseType = Partial<{}>;

export const postSubmitDoc = (
  payload: AxiosRequestConfig<PostSubmitDocReqType>,
): AxiosPromise<PostSubmitDocResponseType> => {
  return apis.post(post_submit_doc, payload);
};
