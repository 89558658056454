// pages/QuotationPage.tsx
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Toaster, toast } from "sonner";
import { AxiosRequestConfig } from "axios";
import { NewStateLocation } from "pages/CreateRemittanceQuotation/step1";
import { useAppSelector } from "redux/hooks";
import {
  getInfo,
  GetInfoRequestType,
  getPartnerInfo,
  GetPartnerInfoRequestType,
  getRemittancePDFURL,
  GetRemittancePDFURLRequestType,
} from "service/apis/CreateForexQuotation";
import {
  postCorporateInteractions,
  PostCorporateInteractionsReqType,
  putUpdateInteractionStatus,
  PutUpdateInteractionStatusReqType,
} from "service/apis/Opportunities";
import { formatIndianNumber } from "utils";
import { MessageCircle, Mail, Download } from "lucide-react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { FormProvider, useForm } from "react-hook-form";
import EditForm from "pages/Opportunities/editform";
import { Spinner } from "components";
import FormQuotation from "./FormQuotation";
import {
  shareQuoteAPI,
  ShareQuoteRequestType,
} from "service/apis/CreateDealapi";

// types/quotation.ts
export interface QuotationData {
  amount: string;
  currency: string;
  customerRate: string;
  country: string;
  purpose: string;
  partnerMargin: string;
  calculations: {
    customerRate: string;
    totalCharges: string;
    gst: string;
    foreignBankCharges: string;
    ttCharges: string;
    totalPayable: string;
  };
}

const Step2 = ({ state }: { state: any }) => {
  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState([]);
  const [managingBranch, setManagingBranch] = useState<number>(0);
  const [activeButton, setActiveButton] = useState<"partner" | "customer" | "">(
    "customer",
  );
  const [customerName, setCustomerName] = useState<string>("");
  const [customerNumber, setCustomerNumber] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [partnerName, setPartnerName] = useState<string>("");
  const [showPartnerDropdown, setShowPartnerDropdown] =
    useState<boolean>(false);
  const [whatsappChecked, setWhatsappChecked] = useState(true);
  const [emailChecked, setEmailChecked] = useState(true);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const mystate = { ...(state as NewStateLocation) };
  const [partnerDeatils, setPartnerDetails] = useState<{
    name: string;
    email: string;
    phone: string;
  }>();

  const [contactDetails, setContactDetails] = useState<{
    contactPerson: string;
    contactEmail: string;
    contactNumber: string;
    location: string;
    notes: string;
    oppId: string;
    contactId: string;
  }>();

  useEffect(() => {
    getDetails();
    setCustomerName(mystate?.contact_name);
    setCustomerNumber(mystate?.contact_number);
  }, []);

  const {
    amount,
    country,
    created_by,
    currency,
    customer_rate,
    add_on_rates,
    purpose_id,
    showerror,
    type,
    cal_profit,
    cal_customer_rate,
    cal_amount_inr,
    cal_total_charges,
    cal_gst,
    cal_foreign_bank_charges,
    cal_tt_charges,
    cal_total_payable,
    service_partner_id,
    partner_status,
    partner_walkin_margin,
    partner_margin,
    tcs,
    fx_charges,
    partnerDeal,
  } = useAppSelector((state) => state.CreateDeal);

  const navigate = useNavigate();

  //sendfucntion code for share on whatsapp and email

  const getDetails = () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetInfoRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
    };

    getInfo(payload)
      .then((res: any) => {
        setPartnerName(res?.data?.name as string);
      })
      .catch((err) => {});
  };

  const API_URL =
    "https://xxl6-bbr3-kxzs.m2.xano.io/api:MhhkaM6y#/getPartnerDetail/get_getPartnerDetail";

  async function getPartnerDetails() {
    const response = await fetch(API_URL);
    const data = await response.json();
    console.log(data);
  }

  const methods = useForm({});

  const fetchPartnerDetails = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetPartnerInfoRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        channels_id: mystate?.partner_id,
      },
    };
    try {
      const res = (await getPartnerInfo(payload)) as any;
      if (res.status === 200) {
        const data = res.data;
        if (data) {
          setPartnerDetails({
            name: data.name,
            email: data.email,
            phone: data.phone,
          });
        }
      }
    } catch (error) {
      toast.error("Failed to fetch partner details");
    }
  };

  useEffect(() => {
    if (mystate?.partner_id) {
      fetchPartnerDetails();
    }
  }, []);

  const setDefaultValues = (type: string) => {
    if (type === "partner") {
      if (mystate?.partner_id) {
        methods.reset({
          partnerName: partnerDeatils?.name || "",
          contact_number: partnerDeatils?.phone || "", // Add default email if available in mystate
          company_email: partnerDeatils?.email || "",
        });
      }
    }
    if (type === "customer")
      methods.reset({
        contactNumber: mystate?.contact_number || "",
        contactEmail: mystate?.contact_email || "", // Add default email if available in mystate
        contactName: mystate?.contact_name || "",
      });
  };

  const handleUpdateInteractionStatus = async (pdf_url: string) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<PutUpdateInteractionStatusReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        id: mystate?.interaction_id as string,
        isOpen: "false",
        quotation_pdf: pdf_url,
      },
    };
    try {
      const res = (await putUpdateInteractionStatus(payload)) as any;
      if (res.status === 200) {
        `Interaction added successfully for Lead-ID ${mystate?.opportunity_id}`;
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed to update interaction status");
      setLoading(false);
    }
  };

  const handleAddInteraction = async (pdfUrl: string) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const mystate = { ...(state as NewStateLocation) };

    if (!mystate?.opportunity_id) {
      setLoading(false);
      return;
    }

    const payload: AxiosRequestConfig<PostCorporateInteractionsReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        opportunity_id: mystate?.opportunity_id as string,
        remarks: "",
        meeting_time: mystate?.meeting_time,
        meetingStaff_id: mystate?.meetingStaff_id,
        note: mystate?.note,
        channel_of_interaction_id: mystate?.channel_of_interaction_id as string,
        quotation_pdf: pdfUrl,
      },
    };
    try {
      const res = (await postCorporateInteractions(payload)) as any;
      if (res.status === 200) {
        toast.success(
          `Interaction added successfully for id ${mystate?.opportunity_id}`,
        );
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed to add interaction");
      setLoading(false);
    }
  };

  const handleDialogOpen = (open: boolean) => {
    setDialogOpen(open);
  };

  const handleSubmit = (type: string) => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const todayDate = new Date();
    // Calculate the date after 7 days
    const today = new Date(todayDate);
    today.setDate(today.getDate());

    // Format the date in "dd/mm/yy" format
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = String(today.getFullYear()).slice(-2);

    const formattedDate = `${day}-${month}-${year}`;
    const mystate = { ...(state as NewStateLocation) };

    const payload: AxiosRequestConfig<GetRemittancePDFURLRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        data: {
          date: formattedDate,
          customer_name: mystate?.contact_name,
          customer_number: mystate?.contact_number,
          purpose: purpose_id,
          currency: currency,
          remitter_send:
            "₹ " +
            formatIndianNumber(parseFloat(cal_total_payable)?.toFixed(2)),
          beneficiary_received:
            (currency === "" || currency === undefined ? "USD" : currency) +
            " " +
            formatIndianNumber(parseFloat(amount)?.toFixed(4)),
          customer_rate:
            cal_customer_rate == "" ? "0.00" : "₹ " + cal_customer_rate,
          total_charges:
            "₹ " + parseFloat(Number(cal_total_charges)?.toFixed(2)),
          gst: "₹ " + Number(cal_gst)?.toFixed(2),
          fb_charges: "₹ " + Number(cal_foreign_bank_charges)?.toFixed(2),
          tt_charges: "₹ " + Number(cal_tt_charges)?.toFixed(2),
          payable_amount:
            "₹" + formatIndianNumber(parseFloat(cal_total_payable)?.toFixed(2)),
          partner_name: partnerName,
        },
      },
    };
    setLoading(true);
    getRemittancePDFURL(payload)
      .then((res) => {
        setLoading(false);
        if (!res?.data) {
          toast.error("Failed to generate PDF");
          return;
        } else {
          if (type === "onload") setPdfUrl(res?.data as unknown as string);
          if (type === "download") {
            if (mystate?.interaction_id) {
              handleUpdateInteractionStatus(res?.data as string).then(() => {});
            } else {
              handleAddInteraction(res?.data as string).then(() => {});
            }
            downloadPDFfromUrl(res?.data);
            navigate("/remit/leads");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  const downloadPDFfromUrl = async (pdf_url: string) => {
    try {
      const response = await fetch(pdf_url);
      const blob = await response.blob();

      // const blob = base64toBlob(pdf_url);
      // Create a download link
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Remittance Quotation.pdf";

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // // Clean up: remove the link
      document.body.removeChild(link);
    } catch (error) {
      // console.error('Error downloading PDF:', error);
    }
  };

  const handleSend = async (channel: string) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    let data;
    if (activeButton === "partner") {
      data = {
        link: pdfUrl,
        channel: channel,
        toSend: "partner",
        channels_id: mystate?.partner_id,
      };
    }
    if (activeButton === "customer") {
      data = {
        link: pdfUrl,
        channel: channel,
        toSend: "customer",
        customers_id: mystate?.contact_id,
      };
    }
    const payload: AxiosRequestConfig<ShareQuoteRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        ...data,
      },
    };
    try {
      await shareQuoteAPI(payload);
      setLoading(false);
      toast.success("Quote shared successfully");
    } catch (error) {
      setLoading(false);
      toast.error("Failed to share quote");
    }
  };

  useEffect(() => {
    handleSubmit("onload");
  }, []);

  return (
    <div className="w-full sm:h-full ">
      {loading && <Spinner />}
      <div className="w-full flex sm:flex-col sm:gap-5 justify-between items-center ">
        {" "}
        {/* Changed to flex-col */}
        <div
          className="w-full px-5 py-2 pb-2 rounded-lg bg-gray-800 text-white flex items-center gap-3 opacity-100 max-w-full"
          style={{
            color: "white",
          }}
        >
          <p className="text-sm">
            The amount is an estimate and may change based on real time fx rates
            & remitter's current FY transactions. Decimal are rounded off.
          </p>
        </div>
        <div className="w-full max-w-full flex justify-end sm:justify-start">
          {" "}
          {/* Changed mt-4 to mt-6 */}
          <div className="flex sm:flex-col justify-between gap-10">
            {" "}
            {/* Added gap-10 (40px) */}
            <div className="flex">
              {/* Added relative positioning to the container */}
              <div className="relative">
                <div
                  className="flex rounded-lg bg-white pt-[6px] pb-[6px] pl-[10px] pr-[10px] gap-[10px]"
                  style={{
                    border: "1px solid #165fe3",
                  }}
                >
                  <button
                    className={`px-4 py-1.5 rounded text-sm ${
                      activeButton === "partner" && !showPartnerDropdown
                        ? ""
                        : "text-gray-600"
                    }`}
                    style={{
                      backgroundColor: showPartnerDropdown
                        ? "#E1EBFC"
                        : activeButton === "partner" && !showPartnerDropdown
                          ? "#f9f9f9"
                          : "#F9F9F9",
                      borderRadius: "10px",
                      color: activeButton === "partner" ? "#165fe3" : "#333333",
                      fontWeight: activeButton === "partner" ? "bold" : "bold",
                    }}
                    onClick={() => {
                      if (activeButton === "partner" && showPartnerDropdown) {
                        setActiveButton(""); // Reset active button when closing dropdown
                      } else {
                        setActiveButton("partner");
                      }
                      setShowPartnerDropdown(!showPartnerDropdown);
                      setShowDropdown(false);
                    }}
                  >
                    Partner
                  </button>
                  <button
                    className={`px-4 py-1.5 rounded text-sm ${
                      activeButton === "customer" && !showDropdown
                        ? ""
                        : "text-gray-600"
                    }`}
                    style={{
                      backgroundColor: showDropdown
                        ? "#E1EBFC"
                        : activeButton === "customer" && !showDropdown
                          ? "#f9f9f9"
                          : "#F9F9F9",
                      borderRadius: "10px",
                      color:
                        activeButton === "customer" ? "#165fe3" : "#333333",
                      fontWeight: activeButton === "customer" ? "bold" : "bold",
                    }}
                    onClick={() => {
                      if (activeButton === "customer" && showDropdown) {
                        setActiveButton(""); // Reset active button when closing dropdown
                      } else {
                        setActiveButton("customer");
                      }
                      setShowDropdown(!showDropdown);
                      setShowPartnerDropdown(false);
                    }}
                  >
                    Customer
                  </button>
                </div>

                {showPartnerDropdown && (
                  <div
                    className="absolute left-0 top-[calc(100%+4px)] bg-white border border-gray-200 rounded-lg shadow-lg p-3 z-[9999]"
                    style={{
                      width: "180px", // Increased width to fit content
                      minHeight: "120px", // Changed to minHeight
                      borderRadius: "8px",
                      backgroundColor: "white",
                    }}
                  >
                    <div className="space-y-3">
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={whatsappChecked}
                          onChange={(e) => setWhatsappChecked(e.target.checked)}
                          className="w-4 h-4 accent-blue-600"
                        />
                        <span className="text-sm text-gray-700">Whatsapp</span>
                        <MessageCircle className="w-4 h-4 text-green-500" />
                      </div>
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={emailChecked}
                          onChange={(e) => setEmailChecked(e.target.checked)}
                          className="w-4 h-4 accent-blue-600"
                        />
                        <span className="text-sm text-gray-700">Email</span>
                        <Mail className="w-4 h-4 text-gray-600" />
                      </div>
                      <button
                        className="w-full bg-blue-600 text-white rounded text-sm font-medium flex items-center justify-center gap-2"
                        style={{
                          height: "32px",
                          padding: "0px 8px",
                          borderRadius: "6px",
                          backgroundColor: "#2563eb",
                          color: "white",
                        }}
                        onClick={() => {
                          setDefaultValues(activeButton);
                          if (whatsappChecked) {
                            handleSend("whatsapp");
                          }
                          if (emailChecked) {
                            handleSend("email");
                          }
                        }}
                      >
                        Share now
                        <svg
                          className="w-4 h-4"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 12H19M19 12L12 5M19 12L12 19"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex float-right absolute -top-3 -right-4 gap-2">
                          <AlertDialog open={dialogOpen}>
                            <AlertDialogPortal>
                              <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                              <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl w-full animate-contentShow sm:max-w-[90%]">
                                <AlertDialogHeader className="flex text-left border-b  rounded-t py-5 px-10 ">
                                  <AlertDialogTitle className="font-bold capitalize p-0 text-lg text-black-label">
                                    Share on Whatsapp and Email.
                                  </AlertDialogTitle>
                                </AlertDialogHeader>
                                <FormProvider {...methods}>
                                  <div className="h-[500px] overflow-auto w-full">
                                    <FormQuotation
                                      type="partner"
                                      whatsappChecked={whatsappChecked}
                                      emailChecked={emailChecked}
                                    />
                                  </div>
                                </FormProvider>
                                <AlertDialogFooter className="flex justify-start items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                                  <AlertDialogCancel
                                    className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                                    asChild
                                  >
                                    <button
                                      onClick={() => {
                                        methods.reset();
                                        methods.clearErrors();
                                        setDialogOpen(false);
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </AlertDialogCancel>
                                  <AlertDialogAction
                                    className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                                    asChild
                                  >
                                    Share
                                  </AlertDialogAction>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialogPortal>
                          </AlertDialog>
                        </div>
                      </button>
                    </div>
                  </div>
                )}

                {/* Customer dropdown with same styling */}
                {showDropdown && (
                  <div
                    className="absolute right-0 top-[calc(100%+4px)] bg-white border border-gray-200 rounded-lg shadow-lg p-3 z-10"
                    style={{
                      width: "180px",
                      minHeight: "120px",
                      borderRadius: "10px",
                      color: "white",
                      backgroundColor: "white",
                    }}
                  >
                    <div className="space-y-3">
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={whatsappChecked}
                          onChange={(e) => setWhatsappChecked(e.target.checked)}
                          className="w-4 h-4 accent-blue-600"
                        />
                        <span className="text-sm text-gray-700">Whatsapp</span>
                        <MessageCircle className="w-4 h-4 text-green-500" />
                      </div>
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={emailChecked}
                          onChange={(e) => setEmailChecked(e.target.checked)}
                          className="w-4 h-4 accent-blue-600"
                        />
                        <span className="text-sm text-gray-700">Email</span>
                        <Mail className="w-4 h-4 text-gray-600" />
                      </div>
                      <button
                        className="w-full bg-blue-600 text-white rounded text-sm font-medium flex items-center justify-center gap-2"
                        style={{
                          height: "32px",
                          padding: "0px 8px",
                          borderRadius: "6px",
                          backgroundColor: "#2563eb",
                        }}
                        onClick={() => {
                          setDefaultValues(activeButton);
                          if (whatsappChecked) {
                            handleSend("whatsapp");
                          }
                          if (emailChecked) {
                            handleSend("email");
                          }
                        }}
                      >
                        Share now
                        <svg
                          className="w-4 h-4"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 12H19M19 12L12 5M19 12L12 19"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex float-right absolute -top-3 -right-4 gap-2">
                          <AlertDialog open={dialogOpen}>
                            <AlertDialogPortal>
                              <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                              <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl w-full animate-contentShow sm:max-w-[90%]">
                                <AlertDialogHeader className="flex text-left border-b  rounded-t py-5 px-10 ">
                                  <AlertDialogTitle className="font-bold capitalize p-0 text-lg text-black-label">
                                    Share on Whatsapp and Email.
                                  </AlertDialogTitle>
                                </AlertDialogHeader>
                                <FormProvider {...methods}>
                                  <div className="h-[500px] overflow-auto w-full">
                                    <FormQuotation
                                      type="customer"
                                      whatsappChecked={whatsappChecked}
                                      emailChecked={emailChecked}
                                    />
                                  </div>
                                </FormProvider>
                                <AlertDialogFooter className="flex justify-start items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                                  <AlertDialogCancel
                                    className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                                    asChild
                                  >
                                    <button
                                      onClick={() => {
                                        methods.reset();
                                        methods.clearErrors();
                                        setDialogOpen(false);
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </AlertDialogCancel>
                                  <AlertDialogAction
                                    className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                                    asChild
                                  >
                                    <button>Share</button>
                                  </AlertDialogAction>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialogPortal>
                          </AlertDialog>
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <button
              onClick={() => handleSubmit("download")}
              className="px-4 py-1.5 text-blue-600 border border-blue-600 rounded-lg text-sm font-medium flex items-center gap-2"
            >
              <Download className="w-4 h-4" />
              Download
            </button>
          </div>
        </div>
      </div>
      <div className="overflow-auto bg-gray-100 p-4 w mt-2 ">
        <iframe
          className="w-full h-[350px] sm:h-[300px]"
          title="Embedded Content"
          src={pdfUrl}
          allowFullScreen
        />
        <Toaster position="top-right" />
      </div>
    </div>
  );
};

export default Step2;
