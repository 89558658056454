import { AxiosRequestConfig } from "axios";
import { Button, Img, List, Spinner, Text } from "components";
import Cancel from "models/Cancel";
import CancellationCharges from "models/DealDetails/CancellationCharges";
import SetServicePartner from "models/DealDetails/SetServicePartner";
import SettlementRate from "models/DealDetails/SettlementRate";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { setDealID } from "redux/features/CreateOrder";
import { useAppDispatch } from "redux/hooks";
import {
  CancelDealRequestType,
  GetDealDetailsRequestType,
  cancelDeal,
  getDealDetails,
} from "service/apis/DealDetails";
import { formatIndianNumber } from "utils";

type DealDetailsType = {
  id: string;
  created_at: string;
  currency: string;
  country: string;
  amount: string | number;
  customer_name: string;
  customer_email: string;
  customer_pan: string;
  customer_phone: string;
  utilization_status: string;
  status: string;
  service_partner_rates: string | number;
  l1_commission_rates: string | number;
  l2_commission_rates: string | number;
  l3_commission_rates: string | number;
  service_partner_cancellation_charge: string | number;
  channel_partner_cancellaton_charge: string | number;
  _channels: {
    id: string;
    name: string;
    phone: string;
    email: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    state: string;
    pincode: string;
  };
  _purpose: {
    id: string;
    name: string;
  };
  _service_partner: {
    id: string;
    display_name: string;
    email: string;
    legal_addr_city: string;
    legal_addr_state: string;
  };
};

const DealDetailsPage: React.FC = () => {
  const [dealDetails, setDealDetails] = React.useState<DealDetailsType>();
  const [status, setStatus] = React.useState<String>("");
  const [confirmCancel, setConfirmCancel] = React.useState<boolean>(false);
  const [showSettlementRate, setShowSettlementRate] = React.useState(false);
  const [showServicePartner, setShowServicePartner] = React.useState(false);
  const [showCancellationCharges, setShowCancellationCharges] =
    React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [createdate, setCreateDate] = React.useState<any>();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  React.useEffect(() => {
    DealDetails();
  }, [id]);

  React.useEffect(() => {
    if (dealDetails) {
      const date = new Date(dealDetails?.created_at);
      setCreateDate(date.toUTCString().substring(0, 16));
    }
  }, [dealDetails]);

  const level = localStorage.getItem("level");
  const isEmployee = localStorage.getItem("isEmployee") == "false";
  const channel_id = localStorage.getItem("channel_id");

  const DealDetails = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetDealDetailsRequestType> = {
      data: {
        deal_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    getDealDetails(payload)
      .then((res) => {
        setDealDetails(res.data);
        setStatus(res.data.status);
      })
      .catch((err) => {});
  };

  const cancelDealFunction = (): void | null => {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<CancelDealRequestType> = {
      data: {
        deal_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    setLoading(true);

    cancelDeal(payload)
      .then(() => {
        toast.success("Deal Status Updated Successfully");
        setLoading(false);
        DealDetails();
        // window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const createOrder = (): void | null => {
    dispatch(setDealID(dealDetails?.id));
    navigate(`/createOrder`);
  };

  let borderColor;
  switch (status) {
    case "unconfirmed":
      borderColor = "bg-[#FFA500]"; // Gray
      break;
    case "confirmed":
      borderColor = "bg-[#0000FF]"; // Light Green
      break;
    case "fulfilled":
      borderColor = "border-colors1"; // Green
      break;
    case "hold":
      borderColor = "bg-[#888888]";
      break;
    case "cancelled":
      borderColor = "bg-[#FF0000]"; // Red
  }

  return (
    <>
      {loading && <Spinner />}
      {
        <div>
          {showSettlementRate && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <SettlementRate
                setShowSettlementRate={setShowSettlementRate}
                id={id}
                dealDetails={dealDetails}
                DealDetails={DealDetails}
              />
            </div>
          )}
        </div>
      }
      {
        <div>
          {showServicePartner && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <SetServicePartner
                setShowPopup={setShowServicePartner}
                id={id}
                dealDetails={dealDetails}
                DealDetails={DealDetails}
              />
            </div>
          )}
        </div>
      }
      {
        <div>
          {showCancellationCharges && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <CancellationCharges
                setShowCancellationCharges={setShowCancellationCharges}
                id={id}
                DealDetails={DealDetails}
              />
            </div>
          )}
        </div>
      }
      {confirmCancel && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Cancel
            title={`Do you really want to cancel this deal? Please note that cancellation charges may apply`}
            setConfirmCancel={setConfirmCancel}
            handleCancel={cancelDealFunction}
          />{" "}
        </div>
      )}

      <div className="bg-gray-100 flex sm:flex-col md:flex-col flex-row font-inter sm:gap-5 md:gap-5 items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className="bg-gray-100 flex flex-1 flex-col font-rubik h-full items-start justify-start md:px-5 px-9 py-[58px] w-full">
          <div className="flex flex-col gap-5 items-start justify-center w-full">
            <div className="flex flex-row items-start justify-between w-full">
              <div className="flex flex-row justify-start w-full">
                <Text className="text-black-900 text-xl w-[120px]">
                  Deal Details
                </Text>
              </div>
              <div className="flex flex-row gap-[8px] justify-end w-full">
                {dealDetails?.status === "unconfirmed" && level === "1" && (
                  <>
                    <div
                      className="common-pointer bg-blue-700 cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-5"
                      onClick={() => setShowSettlementRate(true)}
                    >
                      Set Settlement Rate
                    </div>
                  </>
                )}
                {dealDetails?.status === "hold" &&
                  level === "1" &&
                  !isEmployee && (
                    <>
                      <div
                        className="common-pointer bg-blue-700 cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-5"
                        onClick={() => setShowCancellationCharges(true)}
                      >
                        Set Cancellaton Charges
                      </div>
                    </>
                  )}
                {(dealDetails?.status === "confirmed" ||
                  dealDetails?.status === "unconfirmed") && (
                  <Button
                    className="bg-white-A700 border border-gray-200_01 border-solid gap-2 items-end justify-start px-3 py-1.5 rounded-lg w-auto"
                    onClick={() => setConfirmCancel(true)}
                  >
                    <Text className="font-medium text-[13px] text-blue_gray-700 w-auto">
                      Cancel Deal
                    </Text>
                  </Button>
                )}
                {dealDetails?.status === "confirmed" &&
                  dealDetails?.utilization_status === "unutilized" && (
                    <>
                      <div
                        className="common-pointer bg-blue-700 cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-5"
                        onClick={createOrder}
                      >
                        Create Order
                      </div>
                    </>
                  )}
              </div>
            </div>
            <div className="bg-white-A700 flex flex-col font-inter gap-2.5 items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-lg w-full">
              <div className="flex sm:flex-col sm:items-start flex-row sm:gap-10 items-center justify-between w-full">
                <Text className="font-semibold text-gray-900_01 text-xl w-auto">
                  Deal ID: {dealDetails?.id}
                </Text>
                <div className="flex sm:flex-col sm:items-start flex-row gap-[15px] items-center justify-start w-auto">
                  <div className="bg-white-A700 border border-gray-200_01 border-solid flex flex-row gap-2 items-center justify-start px-3 py-1.5 rounded-lg w-auto">
                    <div
                      className={`${borderColor} h-1.5 rounded-[50%] w-1.5`}
                    ></div>

                    <Text className="font-medium text-[13px] text-blue_gray-700 w-auto">
                      Deal -{" "}
                      {dealDetails?.status &&
                        dealDetails?.status.slice(0, 1).toUpperCase() +
                          dealDetails?.status.slice(1).toLowerCase()}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="flex sm:flex-col flex-row gap-1.5 items-start justify-start w-auto sm:w-full">
                <Text className="font-bold text-gray-600_03 text-sm w-auto">
                  Created at :
                </Text>
                <Text className="text-gray-600_03 text-sm w-auto">
                  {createdate}
                </Text>
                <Text className="text-gray-600_03 text-sm w-auto"> from </Text>
                <Text className="font-medium text-gray-600_03 text-sm w-auto">
                  Created By :{" "}
                  <span
                    className="cursor-pointer text-[#000000]"
                    onClick={() =>
                      navigate(`/partnerdetails${dealDetails?._channels?.id}`)
                    }
                  >
                    {dealDetails?._channels?.name &&
                      dealDetails?._channels?.name.charAt(0).toUpperCase() +
                        dealDetails?._channels?.name.slice(1).toLowerCase()}
                  </span>
                </Text>
              </div>
            </div>
            <div className="grid grid-cols-2 flex flex-1 md:flex-col flex-wrap flex-row font-inter gap-5 items-center justify-start w-full">
              <div className="bg-white-A700 flex flex-1 flex-grow h-full flex-col gap-2.5 min-h-[260px] items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
                <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px] text-gray-600_02 text-sm uppercase w-full">
                  Deal Details
                </Text>

                <div className="flex flex-row gap-2.5 items-center justify-between pt-[5px] w-full">
                  <Text className="text-gray-600_02 text-sm w-auto">
                    Purpose
                  </Text>
                  <Text className="text-gray-900_01 text-sm w-auto">
                    {dealDetails?._purpose?.name}
                  </Text>
                </div>

                <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                  <Text className="text-gray-600_02 text-sm w-auto">
                    Amount (FCY)
                  </Text>
                  <Text className="text-gray-900_01 text-sm w-auto">
                    {dealDetails?.currency}{" "}
                    {formatIndianNumber(dealDetails?.amount ?? 0)}
                  </Text>
                </div>
                <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                  <Text className="text-gray-600_02 text-sm w-auto">
                    Country
                  </Text>
                  <Text className="text-gray-900_01 text-sm w-auto">
                    {dealDetails?.country}
                  </Text>
                </div>
                <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                  <Text className="text-gray-600_02 text-sm w-auto">
                    Customer Rate
                  </Text>
                  <Text className="text-gray-900_01 text-sm w-auto">
                    ₹
                    {`${(
                      Number(dealDetails?.service_partner_rates?.toFixed(4)) +
                      Number(dealDetails?.l2_commission_rates?.toFixed(4)) +
                      Number(dealDetails?.l1_commission_rates?.toFixed(4)) +
                      Number(dealDetails?.l3_commission_rates?.toFixed(4))
                    )?.toFixed(4)}`}
                  </Text>
                </div>

                <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                  <Text className="text-gray-600_02 text-sm w-auto">
                    Settlement Rate
                  </Text>
                  <Text className="text-gray-900_01 text-sm w-auto">
                    ₹
                    {(
                      Number(dealDetails?.service_partner_rates) +
                      (level == "2"
                        ? Number(dealDetails?.l1_commission_rates)
                        : 0) +
                      (level == "3"
                        ? Number(dealDetails?.l1_commission_rates) +
                          Number(dealDetails?.l2_commission_rates)
                        : 0)
                    )?.toFixed(4)}
                  </Text>
                </div>

                {level == "1" && (
                  <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                    <Text className="text-gray-600_02 text-sm w-auto">
                      IBR Rate
                    </Text>
                    <Text className="text-gray-900_01 text-sm w-auto">
                      ₹{`${dealDetails?.eforex_rates?.toFixed(4)}`}
                    </Text>
                  </div>
                )}

                {status === "cancelled" && (
                  <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                    <Text className="text-gray-600_02 text-sm w-auto">
                      Cancellation Charges
                    </Text>
                    <Text className="text-gray-900_01 text-sm w-auto">
                      ₹
                      {Number(
                        `${dealDetails?.service_partner_cancellation_charge?.toFixed(
                          4,
                        )}`,
                      ) +
                        Number(
                          `${dealDetails?.channel_partner_cancellaton_charge?.toFixed(
                            4,
                          )}`,
                        )}
                    </Text>
                  </div>
                )}
              </div>
              <div className="hidden bg-white-A700 flex flex-1 flex-grow h-full flex-col gap-2.5 min-h-[260px] items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
                <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px] text-gray-600_02 text-sm uppercase w-full">
                  Customer Details
                </Text>
                <div className="flex flex-row gap-2.5 items-center justify-between pt-[5px] w-full">
                  <Text className="text-gray-600_02 text-sm w-auto">Name</Text>
                  <Text className="text-gray-900_01 text-sm w-auto">
                    {dealDetails?.customer_name}
                  </Text>
                </div>
                <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                  <Text className="text-gray-600_02 text-sm w-auto">Email</Text>
                  <Text className="text-gray-900_01 text-sm w-auto">
                    {dealDetails?.customer_email === ""
                      ? "N.A"
                      : dealDetails?.customer_email}
                  </Text>
                </div>
                <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                  <Text className="text-gray-600_02 text-sm w-auto">Phone</Text>
                  <Text className="text-gray-900_01 text-sm w-auto">
                    {dealDetails?.customer_phone === ""
                      ? "N.A"
                      : dealDetails?.customer_phone}
                  </Text>
                </div>
                <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                  <Text className="text-gray-600_02 text-sm w-auto">PAN</Text>
                  <Text className="text-gray-900_01 text-sm w-auto">
                    {dealDetails?.customer_pan === ""
                      ? "N.A"
                      : dealDetails?.customer_pan}
                  </Text>
                </div>
              </div>
              {dealDetails?._channels?.id != channel_id && (
                <div className="bg-white-A700 flex flex-1 flex-grow h-full flex-col gap-2.5 min-h-[260px] items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-lg w-full">
                  <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px] text-gray-600_02 text-sm uppercase w-full">
                    Partner Details
                  </Text>
                  <div className="flex flex-col items-start justify-start  w-full">
                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                      <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between rounded-lg w-full">
                        <Text className="text-gray-600_02 text-sm w-auto">
                          Name
                        </Text>
                        <Text className="text-gray-900_01 text-sm w-auto">
                          {dealDetails?._channels?.name
                            ? dealDetails?._channels?.name
                                ?.charAt(0)
                                ?.toUpperCase() +
                              dealDetails?._channels?.name
                                ?.slice(1)
                                ?.toLowerCase()
                            : "N/A"}
                        </Text>
                      </div>
                      <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                        <Text className="text-gray-600_02 text-sm w-auto">
                          ID
                        </Text>
                        <Text className="text-gray-900_01 text-sm w-auto">
                          {dealDetails?._channels?.id ?? "N/A"}
                        </Text>
                      </div>
                      <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                        <Text className="text-gray-600_02 text-sm w-auto">
                          Email
                        </Text>
                        <Text className="text-gray-900_01 text-sm w-auto">
                          {dealDetails?._channels?.email ?? "N/A"}
                        </Text>
                      </div>
                      <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                        <Text className="text-gray-600_02 text-sm w-auto">
                          Mobile
                        </Text>
                        <Text className="text-gray-900_01 text-sm w-auto">
                          {dealDetails?._channels?.phone
                            ? dealDetails?._channels?.phone
                            : "N/A"}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {level == "1" && (
                <div className="bg-white-A700 flex flex-1 flex-grow h-full flex-col gap-2.5 min-h-[260px] items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-lg w-full">
                  <div className="flex flex-row w-full justify-between">
                    <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px] text-gray-600_02 text-sm uppercase w-full">
                      Service Provider Details
                    </Text>
                    {dealDetails?.status === "unconfirmed" && (
                      <Img
                        className="h-[15px] w-[15px] hover:cursor-pointer"
                        src="images/img_edit.svg"
                        alt="edit"
                        onClick={() => setShowServicePartner(true)}
                      />
                    )}
                  </div>
                  <div className="flex flex-col gap-2 items-start justify-start w-full">
                    <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                      <Text className="text-gray-600_02 text-sm w-auto">
                        Name
                      </Text>
                      <Text className="text-gray-900_01 text-sm w-auto">
                        {dealDetails?._service_partner?.display_name
                          ? dealDetails?._service_partner?.display_name
                              ?.slice(0, 1)
                              ?.toUpperCase() +
                            dealDetails?._service_partner?.display_name
                              ?.slice(1)
                              ?.toLowerCase()
                          : "N/A"}
                      </Text>
                    </div>
                    <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                      <Text className="text-gray-600_02 text-sm w-auto">
                        ID
                      </Text>
                      <Text className="text-gray-900_01 text-sm w-auto">
                        {dealDetails?._service_partner?.id ?? "N/A"}
                      </Text>
                    </div>
                    <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                      <Text className="text-gray-600_02 text-sm w-auto">
                        Email
                      </Text>
                      <Text className="text-gray-900_01 text-sm w-auto">
                        {dealDetails?._service_partner?.email ?? "N/A"}
                      </Text>
                    </div>
                    <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                      <Text className="text-gray-600_02 text-sm w-auto">
                        City
                      </Text>
                      <Text className="text-gray-900_01 text-sm w-auto">
                        {dealDetails?._service_partner?.legal_addr_city
                          ? dealDetails?._service_partner?.legal_addr_city
                              ?.charAt(0)
                              ?.toUpperCase() +
                            dealDetails?._service_partner?.legal_addr_city
                              ?.slice(1)
                              ?.toLowerCase()
                          : "N/A"}
                      </Text>
                    </div>
                    <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                      <Text className="text-gray-600_02 text-sm w-auto">
                        State
                      </Text>
                      <Text className="text-gray-900_01 text-sm w-auto">
                        {dealDetails?._service_partner?.legal_addr_state
                          ? dealDetails?._service_partner?.legal_addr_state
                              ?.charAt(0)
                              ?.toUpperCase() +
                            dealDetails?._service_partner?.legal_addr_state
                              ?.slice(1)
                              ?.toLowerCase()
                          : "N/A"}
                      </Text>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <List
              className="sm:flex-col flex-row font-inter gap-5 grid md:grid-cols-1 grid-cols-2 justify-start w-full"
              orientation="horizontal"
            ></List>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default DealDetailsPage;
