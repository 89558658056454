import React, {
  DetailedHTMLProps,
  Dispatch,
  FC,
  HTMLAttributes,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  getRelationShipManager,
  isDefaultMarginSet,
} from "service/apis/PartnerRegistrationapi";
import { Button, Img, Input, SelectBox, Text } from "components";
import { selectOptionType } from "components/SelectBox";
import VerifyOTP from "models/VerifyOTP";
import { Spinner } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import {
  setEmail,
  setPhone,
  setPartnertype,
  setPartnertypeother,
  setStaffId,
} from "../../redux/features/PartnerRegistration";

import "react-toastify/dist/ReactToastify.css";

const inputboxPartnerOptionsList = [
  { label: "SMB", value: "SMB" },
  { label: "Enterprise", value: "Enterprise" }
];

type FooterProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{
    setCurrentStep: Dispatch<SetStateAction<number>> | undefined;
    showOtp: boolean;
    setShowOtp: Dispatch<SetStateAction<boolean>> | undefined;
    EmailOTPVerify: (otp: string) => void | null;
    sendEmailOTP: () => void | null;
    disableButton: boolean;
  }>;

const Step1: FC<FooterProps> = ({
  setCurrentStep,
  showOtp,
  setShowOtp,
  EmailOTPVerify,
  sendEmailOTP,
  disableButton,
}) => {
  // const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [relationshipManagers, setRelationshipManager] = useState<
    selectOptionType[]
  >([]);
  const email = useAppSelector((state) => state.PartnerRegistration.email);
  const phone = useAppSelector((state) => state.PartnerRegistration.phone);
  const Partnertype = useAppSelector(
    (state) => state.PartnerRegistration.Partnertype,
  );
  const staff_id = useAppSelector(
    (state) => state.PartnerRegistration.staff_id,
  );

  const Partnertypeother = useAppSelector(
    (state) => state.PartnerRegistration.Partnertypeother,
  );

  const dispatch = useAppDispatch();

  const myself = localStorage.getItem("staff_id");

  // Fetching ReationShip Manager
  useEffect(() => {
    const level = localStorage.getItem("level");
    const loggedInUserId = localStorage.getItem("staff_id");
    if (loggedInUserId) {
      dispatch(setStaffId(loggedInUserId));
    }
    if (level === "3") {
      navigate("/");
    }
    fetchRelationShipManager();
    checkIsDefaultMarginset();
  }, [dispatch]);

  function checkIsDefaultMarginset(): void | null {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    // API call
    const req = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        product_id: 1,
      },
    };

    isDefaultMarginSet(req)
      .then(() => {})
      .catch((err) => {
        toast.error(err.response.data.message);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      });
  }

  function fetchRelationShipManager(): void | null {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    // API call
    const req = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    const owner_name = localStorage.getItem("name");
    const is_owner = localStorage.getItem("is_owner");

    getRelationShipManager(req)
      .then((res) => {
        setRelationshipManager(
          res.data.map((obj) => {
            const option: selectOptionType = {
              label:
                obj?.id !== undefined
                  ? obj.id === myself
                    ? "Myself"
                    : obj.fname
                  : "",
              value: obj?.id !== undefined ? obj.id : "",
            };
            return option;
          }),
        );
        setRelationshipManager((prev) => [...prev]);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  const validInstructions = () => {
    if (!email || !Partnertype || !(staff_id === 0)) {
      return <Img src="/images/overview/exclamation.svg" />;
    }
    return <Img src="/images/login/green-tick.svg" />;
  };

  return (
    <div className="flex">
      {loading && <Spinner />}
      <div className=" relative border-solid flex md:flex-1 flex-col gap-5 md:h-auto items-start justify-start overflow-y-scroll md:px-5 w-[53%] md:w-full">
        {showOtp && (
          <div
            style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
            className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
          >
            <VerifyOTP
              setCurrentStep={setCurrentStep}
              setShowOtp={setShowOtp}
              verifyOTP={EmailOTPVerify}
              resendOTP={sendEmailOTP}
              title="Please enter OTP sent to your registered Email Address"
            />
          </div>
        )}

        <div className="flex pl-10 flex-col gap-5 h-[545px] md:h-auto items-start justify-start w-auto sm:w-full">
          <div className="flex flex-col relative font-inter gap-3 items-start justify-start w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text className="text-sm font-medium leading-[17px]">
              What is the corporate email ID ?
            </Text>
            <Input
              name="email"
              value={email}
              onChange={(e: string) => dispatch(setEmail(e))}
              placeholder="Enter email address"
              className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
              wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
              type="email"
              disabled={disableButton}
            ></Input>
          </div>

          <div className="flex flex-col gap-2.5 items-start relative justify-center w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text className="text-sm font-medium leading-[17px]">
              What is the corporate number ? (optional)
            </Text>
            <Input
              name="mobileNo"
              disabled={disableButton}
              value={phone}
              onChange={(e: string) => dispatch(setPhone(e))}
              placeholder="Enter phone number"
              className="font-inter py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
              wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
            ></Input>
          </div>

          <div className="flex flex-col relative gap-[17px] items-start justify-start w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10  ${Partnertype === "other" ? "after:h-44 after:border-blue-700" : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text className="text-sm font-medium leading-[17px]">
              What type of corporate do you want to register?
            </Text>
            <div className="flex flex-row font-inter gap-2 items-center justify-start w-full">
              <SelectBox
                className="bg-white-A700 border focus-within:border-blue-700 py-4 px-[15px] rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                placeholderClassName="text-black-900_60"
                options={inputboxPartnerOptionsList}
                value={
                  inputboxPartnerOptionsList.find(
                    (item) => item.value === Partnertype,
                  )?.value
                }
                onChange={(e) => dispatch(setPartnertype(e))}
                indicator={
                  <Img
                    className="h-4 w-4"
                    src="images/img_arrowdown.svg"
                    alt="arrow_down"
                  />
                }
                isMulti={false}
                name="inputbox"
                isSearchable={false}
                placeholder="Select type of partner"
              />
            </div>
          </div>
          <div className="flex flex-col relative font-inter gap-2.5 h-20 md:h-auto items-start justify-start w-[377px] sm:w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-0"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text className="text-sm font-medium leading-[17px]">
              Who will be the relationship manager ?
            </Text>
            <SelectBox
              className="bg-white-A700 border border-[#d2d2d2] py-4 px-[15px] rounded-md text-[15px] focus-within:border-blue-700  text-left tracking-[0.50px] w-full"
              placeholderClassName="text-black-900_60"
              value={staff_id}
              onChange={(e) => dispatch(setStaffId(e))}
              indicator={
                <Img
                  className="h-4 w-4"
                  src="images/img_arrowdown.svg"
                  alt="arrow_down"
                />
              }
              isMulti={false}
              name="inputbox"
              options={relationshipManagers}
              isSearchable={false}
              placeholder="Select relationship manager"
            />
          </div>
        </div>
      </div>
      <div className="border-solid flex sm:flex-1 flex-col md:h-auto md:hidden items-start justify-start md:px-5 sm:w-full">
        <div className="w-full flex flex-col gap-2">
          <h3 className="bg-[#F5F9FF] text-blue-700 py-3 px-4 rounded-md">
            Instruction
          </h3>
          <div className="flex py-2 px-4 items-center gap-5">
            {validInstructions()}
            <p className=" text-sm text-black-label">
              Please enter email or mobile number to continue
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
