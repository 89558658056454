import React, { FC, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Img, Spinner } from "../../components";

import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import Cancel from "models/Cancel";
import { customerRegistrationSteps } from "../../utils";
import { Toaster, toast } from "sonner";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { AxiosRequestConfig } from "axios";
import {
  setCurrVerifyStep,
  setEmail,
  setPhone,
  setPanfront,
  setPanfrontBinary,
  setPanNumber,
  setCustomersId,
  setadhaarFront,
  setadhaarFrontBinary,
  setadhaarBack,
  setadhaarBackBinary,
  setAadharNumber,
  setCanceledCheque,
  setCanceledChequeBinary,
  setAccountNo,
  setAccountNoRe,
  setBankName,
  setIrfcCode,
} from "../../redux/features/CustomerRegistration/index";
import "react-toastify/dist/ReactToastify.css";
import {
  PostCustomerRegistrationStep1RequestType,
  postCustomerRegistrationStep1,
  PostCustomerRegistrationStep1VerifyOTPRequestType,
  postCustomerRegistrationStep1VerifyOTP,
  PostCustomerRegistrationStep2RequestType,
  postCustomerRegistrationStep2,
  PostCustomerRegistrationStep2FallbackReqType,
  POSTPanVerifyFallback,
  POSTAadhaarVerifyFallback,
  PostCustomerRegistrationStep3FallbackReqType,
  PostCustomerRegistrationStep3RequestType,
  postCustomerRegistrationStep3,
  PostCustomerRegistrationStep3VerifyOTPRequestType,
  postCustomerRegistrationStep3VerifyOTP,
  PostCustomerRegistrationStep4RequestType,
  postCustomerRegistrationStep4,
  POSTPanVerify,
} from "service/apis/CustomerRegistrationapi";
import { useNavigate } from "react-router-dom";
import VerifyOTP from "models/VerifyOTP";
import Aadhaarpopup from "models/aadhaarpopup";
import Panpopup from "models/Panpopup";
import Navbar from "components/Navbar/Index";
import Stepper from "components/Stepper/newstepper";
import FormPopup from "./PANandAadhaarPopup/formpopup";
export const CustomFromContext = React.createContext<any>({ form: {} });

const CustomerRegistration: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [showaadhaarpopup, setShowaadhaarpopup] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showpanpopup, setShowpanpopup] = useState<boolean>(false);
  const [verifypandetails, setVerifypandetails] = useState<any>({
    masked_pan_no: "",
    name: "",
  });
  const [aadhaarverifydetails, setAadhaarverifydetails] = useState<any>({
    masked_aadhaar_no: "",
    dob: "",
    address: "",
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [verifyOTP, setVerifyOTP] = useState<string>("");
  const [showOtp, setShowOtp] = useState(false);
  const dispatch = useAppDispatch();
  const email = useAppSelector((state) => state.CustomerRegistration.email);
  const phone = useAppSelector((state) => state.CustomerRegistration.phone);
  const passport_number = useAppSelector(
    (state) => state.CustomerRegistration.passport_number,
  );
  const currverifystep = useAppSelector(
    (state) => state.CustomerRegistration.currverifystep,
  );
  const PanfrontBinary = useAppSelector(
    (state) => state.CustomerRegistration.PanfrontBinary,
  );
  const PanNumber = useAppSelector(
    (state) => state.CustomerRegistration.PanNumber,
  );
  const customers_id = useAppSelector(
    (state) => state.CustomerRegistration.customers_id,
  );
  const AadharFrontBinary = useAppSelector(
    (state) => state.CustomerRegistration.adhaarFrontBinary,
  );
  const AadharBackBinary = useAppSelector(
    (state) => state.CustomerRegistration.adhaarBackBinary,
  );
  const AadharNumber = useAppSelector(
    (state) => state.CustomerRegistration.aadhar_number,
  );
  const CanceledChequeBinary = useAppSelector(
    (state) => state.CustomerRegistration.Canceled_ChequeBinary,
  );
  const AccountNo = useAppSelector(
    (state) => state.CustomerRegistration.account_no,
  );
  const BankName = useAppSelector(
    (state) => state.CustomerRegistration.bank_name,
  );
  const IrfcCode = useAppSelector(
    (state) => state.CustomerRegistration.irfc_code,
  );
  const AccountNoRe = useAppSelector(
    (state) => state.CustomerRegistration.account_no_re,
  );

  const submitfrom = () => {
    //   form.handleSubmit(guestSubmit);
  };
  // Step-1 API
  const step1API = (): void | null => {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<PostCustomerRegistrationStep1RequestType> = {
      data: {
        email: email,
        phone_number: phone,
        passport_no: passport_number,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    setLoading(true);
    postCustomerRegistrationStep1(req)
      .then((res) => {
        setLoading(false);
        dispatch(setCustomersId(res.data.id));
        setShowOtp(false);
        setCurrentStep(currentStep + 1);

        dispatch(setCurrVerifyStep(currentStep));

        // setCurrentStep(currentStep + 1);
        // setVerifyOTP("email");
        // setShowOtp(true);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const step1verifyOTP = (otp: string): void | null => {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<PostCustomerRegistrationStep1VerifyOTPRequestType> =
      {
        data: {
          customers_id: customers_id,
          otp: otp,
          type: "email",
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
    setLoading(true);
    postCustomerRegistrationStep1VerifyOTP(req)
      .then((res) => {
        setLoading(false);
        setShowOtp(false);

        setCurrentStep(currentStep + 1);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const step2API = (): void | null => {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<PostCustomerRegistrationStep2RequestType> = {
      data: {
        pan_card: PanfrontBinary,
        pan_no: PanNumber,
        customers_id: customers_id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    setLoading(true);

    POSTPanVerify(req)
      .then((res) => {
        setLoading(false);
        // setCurrentStep(currentStep + 1);
        dispatch(setCurrVerifyStep(currentStep));
        setVerifypandetails({
          masked_pan_no: res.data.pan_no,
          name: res.data.full_name,
        });
        setShowpanpopup(true);
      })
      .catch((err) => {
        setLoading(false);
        if (
          err.response.data.message ===
          "Other customer is registered with this pan"
        ) {
          toast.error("Other customer is registered with this pan");
          return;
        }
        setType("PAN");
        setDialogOpen(true);
      });
  };

  const step2fallback = async (data: { PAN: string; name: string }) => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<PostCustomerRegistrationStep2FallbackReqType> =
      {
        data: {
          customers_id,
          pan_no: data?.PAN,
          name: data?.name,
          pan_card: PanfrontBinary,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
    setLoading(true);
    try {
      const response = await POSTPanVerifyFallback(req);
      if (response.status === 200) {
        setLoading(false);
        dispatch(setCurrVerifyStep(currentStep));
        setDialogOpen(false);
        setLoading(false);
        setCurrentStep((currentStep) => currentStep + 1);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response.data.message);
    }
  };

  const step3API = (): void | null => {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<PostCustomerRegistrationStep3RequestType> = {
      data: {
        aadhar_front: AadharFrontBinary,
        aadhar_back: AadharBackBinary,
        aadhar_number: AadharNumber,
        customers_id: customers_id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    setLoading(true);
    postCustomerRegistrationStep3(req)
      .then((res) => {
        setShowOtp(false);
        setLoading(false);
        setAadhaarverifydetails({
          masked_aadhaar_no: "**** ****",
          dob: res.data.aadhaar.dob,
          address:
            res.data.aadhaar.address_line_1 +
            " " +
            res.data.aadhaar.address_line_2,
        });
        step4API();
      })
      .catch((err) => {
        setLoading(false);
        setType("aadhaar");
        setDialogOpen(true);
      });
  };

  const step3fallback = async (data: {
    aadhaar_number: string;
    name: string;
    date_of_birth: string;
    address: string;
  }) => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<PostCustomerRegistrationStep3FallbackReqType> =
      {
        data: {
          customers_id,
          aadhar_number: data?.aadhaar_number,
          name: data?.name,
          date: data?.date_of_birth,
          address: data?.address,
          aadhar_front_img: AadharFrontBinary,
          aadhar_back_img: AadharBackBinary,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
    setLoading(true);
    try {
      const response = await POSTAadhaarVerifyFallback(req);
      if (response.status === 200) {
        setLoading(false);
        dispatch(setCurrVerifyStep(currentStep));
        setDialogOpen(false);
        setLoading(false);
        step4API();
        handleonCancel();
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response.data.message);
    }
  };

  const step3verifyOTP = (otp: string): void | null => {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const request_id: string | null = localStorage.getItem("request_id");
    if (request_id === null) return null;

    const req: AxiosRequestConfig<PostCustomerRegistrationStep3VerifyOTPRequestType> =
      {
        data: {
          request_id: request_id,
          customers_id: customers_id,
          otp: otp,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
    setLoading(true);
    postCustomerRegistrationStep3VerifyOTP(req)
      .then((res) => {
        setShowOtp(false);
        setLoading(false);
        setAadhaarverifydetails({
          masked_aadhaar_no: "**** ****",
          dob: res.data.aadhaar.dob,
          address:
            res.data.aadhaar.address_line_1 +
            " " +
            res.data.aadhaar.address_line_2,
        });

        dispatch(setCurrVerifyStep(currentStep));
        step4API();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const step4API = (): void | null => {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<PostCustomerRegistrationStep4RequestType> = {
      data: {
        customers_id,
        cancel_cheque: CanceledChequeBinary,
        bank_account_no: AccountNo,
        bank_name: BankName,
        ifsc_code: IrfcCode,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    setLoading(true);
    postCustomerRegistrationStep4(req)
      .then((res) => {
        localStorage.setItem("ApplicationId", res.data.id.toString());
        localStorage.setItem("ApplicationStatus", res.data.status.toString());
        setLoading(false);
        if (type !== "aadhaar") setShowaadhaarpopup(true);
        else setCurrentStep(currentStep + 1);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const handleNext = () => {
    if (currentStep === 1) {
      if (email === "" || phone === "" || passport_number === "") {
        toast.error("Please Enter All Details");
        return;
      } else if (phone.length != 10) {
        toast.error("Please Enter valid phone number");
        return;
      }
      if (currverifystep >= currentStep) {
        setCurrentStep(currentStep + 1);
      } else {
        step1API();
      }
    } else if (currentStep === 2) {
      if (PanfrontBinary === "" || PanNumber === "") {
        toast.error("Please enter PAN details");
        return;
      }
      if (currverifystep >= currentStep) {
        setCurrentStep(currentStep + 1);
      } else {
        step2API();
      }
    } else if (currentStep === 3) {
      if (
        AadharFrontBinary === "" ||
        AadharBackBinary === "" ||
        AadharNumber === ""
      ) {
        toast.error("Please enter Aadhaar details");
        return;
      }
      if (currverifystep >= currentStep) {
        setCurrentStep(currentStep + 1);
      } else {
        step3API();
      }
    }
  };

  const handleonCancel = () => {
    dispatch(setCurrVerifyStep(0));
    dispatch(setEmail(""));
    dispatch(setPhone(""));
    dispatch(setPanfront(null));
    dispatch(setPanfrontBinary(""));
    dispatch(setPanNumber(""));
    dispatch(setCustomersId(""));
    dispatch(setadhaarFront(null));
    dispatch(setadhaarFrontBinary(""));
    dispatch(setadhaarBack(null));
    dispatch(setadhaarBackBinary(""));
    dispatch(setAadharNumber(""));
    dispatch(setCanceledCheque(null));
    dispatch(setCanceledChequeBinary(""));
    dispatch(setAccountNo(""));
    dispatch(setAccountNoRe(""));
    dispatch(setBankName(""));
    dispatch(setIrfcCode(""));
    navigate("/customers");
  };
  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 />;
      // case 4:
      //   return <Step4 />;
      case 4:
        return <Step5 />;
      // Add more cases for additional steps
      default:
        return null;
    }
  };

  return (
    <>
      {confirmCancel && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6" }}
          className="fixed top-0 left-0 z-50 h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Cancel
            title="Are You Sure You Want To Cancel This Customer Registration?"
            setConfirmCancel={setConfirmCancel}
            handleCancel={handleonCancel}
          />{" "}
        </div>
      )}
      {showOtp && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-50 h-screen w-[100vw] flex justify-center items-center"
        >
          <VerifyOTP
            setCurrentStep={setCurrentStep}
            setShowOtp={setShowOtp}
            resendOTP={step3API}
            verifyOTP={verifyOTP === "email" ? step1verifyOTP : step3verifyOTP}
            title={
              verifyOTP === "email"
                ? email != ""
                  ? "Please enter OTP sent to your registered Email"
                  : "Please enter OTP sent to your registered Phone Number"
                : "Sent to your aadhaar linked mobile number"
            }
          />
        </div>
      )}
      <FormPopup
        defaultValue={type === "PAN" ? PanNumber : AadharNumber}
        entityType="customer"
        onSubmit={type === "PAN" ? step2fallback : step3fallback}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        type={type}
      />
      {showaadhaarpopup && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-50 h-screen w-[100vw] flex justify-center items-center"
        >
          <Aadhaarpopup
            setCurrentStep={setCurrentStep}
            setShowaadhaarpopup={setShowaadhaarpopup}
            aadhaarverifydetails={aadhaarverifydetails}
          />
        </div>
      )}
      {showpanpopup && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-50 h-screen w-[100vw] flex justify-center items-center"
        >
          <Panpopup
            setCurrentStep={setCurrentStep}
            setShowpanpopup={setShowpanpopup}
            verifypandetails={verifypandetails}
          />
        </div>
      )}
      {loading && <Spinner />}
      <div className="p-4 bg-gray-100 flex flex-col h-screen font-inter items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className="flex md:flex-col flex-col gap-4 md:gap-5 items-start justify-start w-full">
          <Navbar>
            <div className=" w-full flex justify-between">
              <div className=" flex gap-3 items-center">
                <Img
                  src="images/sidebar_img/remit.svg"
                  className="h-5"
                  alt=""
                />
                <div className="gap-2 flex flex-col">
                  <span className="text-sm font-semibold">
                    Customer Registration
                  </span>
                  <span className="text-xs font-normal text-[#999999]">
                    Register new customer with correct details
                  </span>
                </div>
              </div>
              <div className="flex gap-3">
                {/* <div className="gap-2.5 flex items-center">
                  <Img src="images/overview/wrench.svg" alt="" />
                  <span className="text-sm font-semibold text-blue-700">
                    Help
                  </span>
                </div> */}
                {currentStep !== 4 && (
                  <AlertDialog>
                    <AlertDialogTrigger>
                      <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                        Cancel
                      </button>
                    </AlertDialogTrigger>
                    <AlertDialogPortal>
                      <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                      <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                        <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                          <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                            Are you absolutely sure?
                          </AlertDialogTitle>
                          <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                            This action cannot be undone. This will permanently
                            delete your progress and remove the actions
                            preformed.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                          <AlertDialogCancel
                            className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                            asChild
                          >
                            <button>Close</button>
                          </AlertDialogCancel>
                          <AlertDialogAction
                            className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                            asChild
                          >
                            <button className="" onClick={handleonCancel}>
                              Confirm
                            </button>
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogPortal>
                  </AlertDialog>
                )}
              </div>
            </div>
          </Navbar>
          <div className="gap-5 w-full flex h-full flex-1 flex-grow">
            <div className=" bg-white-A700 p-5 rounded-xl flex flex-col gap-5 w-full">
              <Stepper
                steps={customerRegistrationSteps.map((val) => val.name)}
                currentStep={currentStep - 1}
              />
              {renderStep()}
              {currentStep !== 4 && (
                <div className="flex gap-3">
                  {currentStep !== 1 && (
                    <button
                      type="button"
                      onClick={handlePrevious}
                      className={`px-4 py-[11px] w-fit rounded-lg border border-blue-700 text-blue-700 ${true ? "" : "opacity-40"}`}
                    >
                      Previous
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={handleNext}
                    className={`px-4 py-[11px] w-fit rounded-lg bg-blue-700 text-white-A700 ${true ? "" : "opacity-40"}`}
                  >
                    {currentStep === customerRegistrationSteps.length - 1
                      ? "Submit"
                      : "Next"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Toaster theme="dark" position="top-right" />
    </>
  );
};

export default CustomerRegistration;
