import { Controller, useFormContext } from "react-hook-form";
import ReactSelect from "react-select";
import { useAppSelector } from "redux/hooks";
import { Option } from "../Opportunities/types";
import { entityTypes } from "pages/Opportunities/utils/featureRule";

interface FormQuotationProps {
  type: "customer" | "partner";
  whatsappChecked: boolean;
  emailChecked: boolean;
}

export default function FormQuotation({
  type,
  whatsappChecked,
  emailChecked,
}: FormQuotationProps) {
  //const { pipelineType } = useAppSelector((state) => state.CreateOpportunity);
  const pipelineType = type;
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();

  const defaultValues = getValues();
  const dottedBorderWidth = Object.keys(errors).length
    ? "after:h-40 after:border-blue-700"
    : "after:h-32 after:border-blue-700";

  const renderField = (
    name: string,
    label: string,
    type = "text",
    options: Option[] | null = null,
    isLast: boolean = false,
    defaultValue?: any,
    rules?: any,
    isOptional: boolean = false,
    isFielddDisabled: boolean = false,
  ) => (
    <div
      className="flex relative flex-col gap-3 items-start justify-start w-full"
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${isLast ? "after:h-0" : dottedBorderWidth} absolute after:relative after:content-[''] after:border after:border-dashed`}
      >
        <img
          className="relative -left-10"
          src="/images/overview/ellipse.svg"
          alt=""
        />
      </div>
      <label htmlFor={name} className="text-sm font-medium text-black-label">
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={
          rules || {
            ...(isOptional ? {} : { required: "This field is required" }),
            validate: (value) => {
              if (type === "email" && value) {
                return (
                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ||
                  "Invalid email address"
                );
              }
              if (type === "number" && value) {
                return (
                  /^\d{10}$/.test(value) || "Phone number must be 10 digits"
                );
              }
              return true;
            },
          }
        }
        // skipcq: JS-0417
        render={({ field }) =>
          options ? (
            <ReactSelect
              {...field}
              options={options}
              defaultValue={options.find(
                (option) => option?.value === defaultValue,
              )}
              className="w-full"
              menuPlacement="top"
              placeholder={`Select ${label}`}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  zIndex: 100,
                  borderRadius: ".5rem",
                  padding: ".6rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: state.isFocused
                    ? "#165FE3"
                    : errors[name]
                      ? "red"
                      : "#333333",
                  boxShadow: "none",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 1000,
                }),
              }}
              isClearable
              isSearchable
              isDisabled={isFielddDisabled}
            />
          ) : (
            <input
              {...field}
              type={type}
              placeholder={`Enter ${label}`}
              defaultValue={defaultValue}
              className={`border ${errors[name] ? "border-red-500" : "border-black-label"} rounded-lg p-4 w-full`}
              disabled={isFielddDisabled}
            />
          )
        }
      />
      {errors[name] && (
        <span className="text-red-500 text-sm">
          {errors[name].message as string}
        </span>
      )}
    </div>
  );

  switch (type) {
    case "customer":
      return (
        <form
          className="max-w-md py-8 sm:p-2 pl-9"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-col sm:pl-0 pl-10 gap-10 w-full">
            {renderField(
              "contactName",
              "What is Customer name?",
              "text",
              null,
              false,
              defaultValues?.contactName,
              {
                required: "This field is required",
              },
            )}
            {whatsappChecked &&
              renderField(
                "contact_number",
                "Whatsapp Number",
                "tel",
                null,
                false,
                defaultValues?.contactNumber,
              )}
            {emailChecked &&
              renderField(
                "contact_email",
                "Email ID",
                "email",
                null,
                false,
                defaultValues?.contactEmail,
              )}
          </div>
        </form>
      );

    case "partner":
      return (
        <form
          className="max-w-md py-8 sm:p-2 pl-9"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-col sm:pl-0 pl-10 gap-10 w-full">
            {renderField(
              "partner_name",
              "What is Partner name?",
              "text", // Add type
              null,
              false,
              defaultValues?.partnerName,
            )}

            {whatsappChecked &&
              renderField(
                "contact_number",
                "What is Partner's phone number?",
                "tel",
                null,
                false,
                defaultValues?.contact_number,
              )}
            {emailChecked &&
              renderField(
                "contact_email", // Changed from company_email to match customer pattern
                "What is Partner's email ID?",
                "email",
                null,
                true,
                defaultValues?.company_email, // Updated to match field name
              )}
          </div>
        </form>
      );
    //   return (
    //     <form className="max-w-md py-8 sm:p-2 pl-9">
    //       <div className="flex flex-col sm:pl-0 pl-10 gap-10 w-full">
    //         {renderField(
    //           "partner_name",
    //           "What is Partner name?",
    //           "",
    //           null,
    //           false,
    //           defaultValues?.partner_name
    //         )}
    //         {renderField(
    //           "entity_type",
    //           "What is the type of the entity?",
    //           "",
    //           entityTypes,
    //           false,
    //           defaultValues?.entity_type
    //         )}
    //         {renderField(
    //           "contact_number",
    //           "What is Partner's phone number?",
    //           "tel",
    //           null,
    //           false,
    //           defaultValues?.contact_number
    //         )}
    //         {renderField(
    //           "company_email",
    //           "What is Partner's email ID?",
    //           "email",
    //           null,
    //           true,
    //           defaultValues?.company_email
    //         )}
    //       </div>
    //     </form>
    default:
      return null;
  }
}
