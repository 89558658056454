import React, { useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useLocation, useNavigate } from "react-router-dom";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import Step6 from "./step6";
import Cancel from "models/Cancel";
import Footer1 from "components/Footer1";
import { partnerRegistrationSteps } from "../../utils";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import {
  incrementVerifystep,
  resetState,
  setVerifystep,
} from "redux/features/PartnerRegistration";
import axios from "axios";
import { toast, Toaster } from "sonner";
import {
  PostOfficeAddressVerificationStep4RequestType,
  postOfficeAddressStep4Verification,
} from "service/apis/PartnerRegistrationapi";
import { AxiosRequestConfig } from "axios";
import { Img, Spinner } from "components";
import {
  getRelationShipManager,
  postPartnerRegistrationStep1,
  postSendEmailOTP,
  PostSendEmailOTPRequestType,
  PostEmailOTPVerifyRequestType,
  postEmailOTPVerify,
} from "service/apis/PartnerRegistrationapi";

import {
  GetPartnerIncompleteStepsRequestType,
  getPartnerIncompleteSteps,
} from "service/apis/PartnerDetails";

import {
  setEmail,
  setPhone,
  resetVerifystep,
  setPartnertype,
  setPartnertypeother,
  setStaffId,
  setPanfront,
  setPanfrontBinary,
  setPanNumber,
  setPanType,
  setadhaarFront,
  setadhaarFrontBinary,
  setadhaarBack,
  setadhaarBackBinary,
  setUidNumber,
  setAddressLine1,
  setAddressLine2,
  setCity,
  setState,
  setPincode,
  setCitylabel,
  setStateLabel,
  setPhotos,
  setGst,
  setGeolocation,
  setPropertyType,
  setaddress_proof,
  setOffice_Interior1,
  setPhoto1,
  setphotosBinary,
  setaddressBinary,
  setResumeForm,
} from "../../redux/features/PartnerRegistration";
import {
  getCommisionRatesStep5,
  postUpdateCommisionRatesStep5,
} from "service/apis/PartnerRegistrationapi";
import "react-toastify/dist/ReactToastify.css";
import Stepper from "components/Stepper/newstepper";
import Navbar from "components/Navbar/Index";
import FormPopup from "pages/CustomerRegistration/PANandAadhaarPopup/formpopup";

const PartnerRegistration: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [commisionrate, setCommisionrate] = React.useState<any>([]);
  const [loading, setloading] = useState(false);
  const [showOtp, setShowOtp] = React.useState(false);
  const [confirmCancel, setConfirmCancel] = React.useState(false);
  const [confirmSubmit, setConfirmSubmit] = React.useState(false);
  const {
    email,
    phone,
    Partnertype,
    staff_id,
    PanNumber,
    PanfrontBinary,
    adhaarFrontBinary,
    adhaarBackBinary,
    uid_number,
    currverifystep,
    address_line_1,
    address_line_2,
    state,
    pincode,
    addressBinary,
    gst,
    geolocation,
    photosBinary,
    citylabel,
    statelabel,
    PropertyType,
    Partnertypeother,
    resume_form,
    enabledProducts,
  } = useAppSelector((state) => state.PartnerRegistration);

  React.useEffect(() => {
    dispatch(setVerifystep(0));
    if (resume_form) {
      resumeRegistration();
    } else {
    }
  }, []);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const resumeRegistration = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<GetPartnerIncompleteStepsRequestType> = {
      data: {
        channels_id: localStorage.getItem("child_channel_id"),
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    setloading(true);
    getPartnerIncompleteSteps(req)
      .then((res) => {
        setloading(false);

        setCurrentStep(res?.data?.step);

        dispatch(setEmail(res?.data?.result?.email));
        dispatch(setPhone(res?.data?.result?.phone));
        dispatch(setPartnertype(res?.data?.result?.type));
        dispatch(setStaffId(res?.data?.result?.relationshil_manager));

        // dispatch(setPanfront(res?.data?.result?.pan?.path))
        dispatch(
          setPanType(
            res?.data?.result?.pan_type === ""
              ? ""
              : res?.data?.result?.pan_type === "Individual or Person"
                ? "individualPan"
                : "businessPan",
          ),
        );
        dispatch(setAddressLine1(res?.data?.result?.address_line_1));
        dispatch(setAddressLine2(res?.data?.result?.address_line_2));
        dispatch(
          setState(
            isNaN(res?.data?.result?.state)
              ? ""
              : parseInt(res?.data?.result?.state),
          ),
        );
        dispatch(
          setCity(
            isNaN(res?.data?.result?.city)
              ? ""
              : parseInt(res?.data?.result?.city),
          ),
        );
        if (res?.data?.step == 5) {
          dispatch(setPincode(res?.data?.result?.pincode));
        }
        dispatch(setGst(res?.data?.result?.gst_number));
        dispatch(
          setPropertyType(
            res?.data?.result?.property_type === ""
              ? "Owned"
              : res?.data?.result?.property_type,
          ),
        );

        dispatch(setVerifystep(res?.data?.step - 1));

        if (res?.data?.step == 1) {
          sendEmailOTP();
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  function sendEmailOTP(): void | null {
    setloading(true);
    // get channel id from localstorage
    const channel_id = localStorage.getItem("child_channel_id");
    if (channel_id === null) return null;

    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<PostSendEmailOTPRequestType> = {
      data: {
        channels_id: channel_id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    postSendEmailOTP(req)
      .then(() => {
        setloading(false);
        setShowOtp(true);
      })
      .catch(() => {
        setloading(false);
        toast.error("OTP Not Send");
      });
  }

  function EmailOTPVerify(otp: string): void | null {
    setloading(true);
    // get channel id from localstorage
    const channel_id = localStorage.getItem("child_channel_id");
    if (channel_id === null) return null;

    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const OTP = parseInt(otp);
    const req: AxiosRequestConfig<PostEmailOTPVerifyRequestType> = {
      data: {
        channels_id: channel_id,
        otp: OTP,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    postEmailOTPVerify(req)
      .then(() => {
        setloading(false);
        dispatch(incrementVerifystep());

        dispatch(incrementVerifystep());
        setShowOtp(false);
        if (setCurrentStep) {
          setCurrentStep((prev) => prev + 1);
        }
      })
      .catch((err) => {
        setloading(false);
        toast.error(err?.response?.data?.message);
      });
  }

  const step1 = (): void | null => {
    // call step1 api
    setloading(true);
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;

    const authToken = JSON.parse(auth);

    const req = {
      data: {
        email: email,
        phone: phone,
        type: Partnertype,
        staff_id: staff_id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    postPartnerRegistrationStep1(req)
      .then((res) => {
        if (res.data?.id) {
          localStorage.setItem("child_channel_id", res.data?.id);
        }
        dispatch(incrementVerifystep());
        setShowOtp(false);
        if (setCurrentStep) {
          setCurrentStep((prev) => prev + 1);
        }
        setloading(false);
        // sendEmailOTP();
      })
      .catch((err) => {
        setloading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const step1emailverification = () => {
    step1();
  };
  const step4verification = (): null | void => {
    setloading(true);
    const channel_id = localStorage.getItem("child_channel_id");
    if (channel_id === null) return null;

    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const req: AxiosRequestConfig<PostOfficeAddressVerificationStep4RequestType> =
      {
        data: {
          address_line_1: address_line_1,
          address_line_2: address_line_2,
          city: citylabel,
          state: statelabel,
          pincode: pincode,
          address_proof: addressBinary,
          property_type: PropertyType,
          gst_number: gst,
          channels_id: channel_id,
          geolocation: geolocation,
          photo_1: photosBinary.photo_1,
          photo_2: photosBinary.photo_2,
          photo_3: photosBinary.photo_3,
          photo_4: photosBinary.photo_4,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

    postOfficeAddressStep4Verification(req)
      .then(() => {
        setloading(false);
        dispatch(incrementVerifystep());
        // if (setCurrentStep) {
        setCurrentStep((prev) => prev + 1);
        // }
      })
      .catch((e) => {
        setloading(false);
        toast.error(e.response.data.message);
      });
  };
  const fetchCommisionRatesStep5 = (): void | null => {
    setloading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    getCommisionRatesStep5(req)
      .then((res) => {
        setCommisionrate(res.data);
        setloading(false);
      })
      .catch((e) => {
        setloading(false);
        toast.error(e.response.data.message);
      });
  };
  const updateCommision = (): void | null => {
    setloading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const channel_id = localStorage.getItem("child_channel_id");
    if (channel_id === null) return null;

    const req = {
      data: {
        child_channel: channel_id,
        accept_default: true,
        products: enabledProducts,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    postUpdateCommisionRatesStep5(req)
      .then((res) => {
        if (res.data.channel) {
          localStorage.setItem("ApplicationId", res.data.channel.id);
          localStorage.setItem(
            "ApplicationStatus",
            res.data.channel.approval_status,
          );
          dispatch(setResumeForm(false));
        }
        if (setCurrentStep) {
          setCurrentStep((prev) => prev + 1);
        }
        setloading(false);
      })
      .catch((e) => {
        setloading(false);
        toast.error(e.response.data.message);
      });
  };

  const handleStep5Submit = () => {
    fetchCommisionRatesStep5();
    updateCommision();
  };
  const handleSubmit = () => {
    if (enabledProducts.length <= 0) {
      toast.error("Please select atleast one product");
      return;
    } else {
      setConfirmSubmit(true);
    }
  };
  const handleNext = () => {
    if (currentStep == 1) {
      if (email === "" || Partnertype === "" || staff_id === "") {
        toast.error("Please fill all the fields");
        return;
      } else {
        if (currverifystep >= currentStep) {
          setCurrentStep(currentStep + 1);
        } else {
          step1emailverification();
        }
      }
    } else if (currentStep === 2) {
      if (PanNumber !== "" || PanfrontBinary !== "") {
        if (currverifystep >= currentStep) {
          setCurrentStep(currentStep + 1);
        } else {
          toast.error("Please verify your PAN");
          return;
        }
      } else {
        toast.error("Please fill all the fields");
        return;
      }
    } else if (currentStep === 3) {
      if (
        (adhaarFrontBinary !== "" && adhaarBackBinary !== "") ||
        uid_number !== ""
      ) {
        if (currverifystep >= currentStep) {
          setCurrentStep(currentStep + 1);
        } else {
          toast.error("Please verify your Aadhar");
          return;
        }
      } else {
        toast.error("Please fill all the fields");
        return;
      }
    } else if (currentStep === 4) {
      if (
        address_line_1 === "" ||
        state === "" ||
        citylabel === "" ||
        pincode === "" ||
        addressBinary === "" ||
        statelabel === "" ||
        PropertyType === ""
      ) {
        toast.error("Please fill all the fields");
        return;
      } else if (String(pincode)?.length !== 6) {
        toast.error("Please enter valid pincode");
      } else {
        if (currverifystep >= currentStep) {
          setCurrentStep(currentStep + 1);
        } else {
          step4verification();
        }
      }
    } else if (currentStep === 5) {
      if (currverifystep >= currentStep) {
        setCurrentStep((currentStep) => currentStep + 1);
      } else {
        toast.error("Please Save Commision Rates");
        return;
      }
    }
  };
  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };
  const handleCancel = () => {
    dispatch(resetState());
    navigate("/lead/contacts/partner");
  };

  const handleReset = () => {
    dispatch(resetState());
    setCurrentStep(1);
  }
  const location = useLocation()
  useEffect(() => {
    handleReset();
  }, [location, dispatch])

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            showOtp={showOtp}
            setShowOtp={setShowOtp}
            EmailOTPVerify={EmailOTPVerify}
            sendEmailOTP={sendEmailOTP}
            setCurrentStep={setCurrentStep}
            disableButton={currverifystep >= currentStep}
          />
        );
      case 2:
        return (
          <Step2
            setCurrentStep={setCurrentStep}
            disableButton={currverifystep >= currentStep}
          />
        );
      case 3:
        return (
          <Step3
            setCurrentStep={setCurrentStep}
            disableButton={currverifystep >= currentStep}
          />
        );
      case 4:
        return (
          <Step4
            setCurrentStep={setCurrentStep}
            disableButton={currverifystep >= currentStep}
          />
        );
      case 5:
        return (
          <Step5
            setCurrentStep={setCurrentStep}
            disableButton={currverifystep >= currentStep}
          />
        );
      case 6:
        return <Step6 />;
      // Add more cases for additional steps
      default:
        return null;
    }
  };

  return (
    <>
      {confirmCancel && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Cancel
            title="Are You Sure You Want To Cancel This Partner Registration?"
            setConfirmCancel={setConfirmCancel}
            handleCancel={handleCancel}
          />{" "}
        </div>
      )}
      {confirmSubmit && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Cancel
            title="Are You Sure You Want To Submit? Changes made in Default Commission will not be saved"
            setConfirmCancel={setConfirmSubmit}
            handleCancel={handleStep5Submit}
          />{" "}
        </div>
      )}
      {loading && <Spinner />}
      <div className="p-4 bg-gray-100 flex flex-col h-screen font-inter items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className="flex md:flex-col flex-col gap-4 md:gap-5 items-start justify-start w-full">
          <Navbar>
            <div className=" w-full flex justify-between">
              <div className=" flex gap-3 items-center">
                <Img
                  src="images/sidebar_img/remit.svg"
                  className="h-5"
                  alt=""
                />
                <div className="gap-2 flex flex-col">
                  <span className="text-sm font-semibold">
                    Partner Registration
                  </span>
                  <span className="text-xs font-normal text-[#999999]">
                    Register new Partner with correct details
                  </span>
                </div>
              </div>
              <div className="flex gap-3">
                <div className="gap-2.5 flex items-center">
                  <Img src="images/overview/wrench.svg" alt="" />
                  <span className="text-sm font-semibold text-blue-700">
                    Help
                  </span>
                </div>
                {currentStep !== 6 && (
                  <AlertDialog>
                    <AlertDialogTrigger>
                      <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                        Cancel
                      </button>
                    </AlertDialogTrigger>
                    <AlertDialogPortal>
                      <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                      <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                        <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                          <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                            Are you absolutely sure?
                          </AlertDialogTitle>
                          <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                            This action cannot be undone. This will permanently
                            delete your progress and remove the actions
                            preformed.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                          <AlertDialogCancel
                            className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                            asChild
                          >
                            <button>Close</button>
                          </AlertDialogCancel>
                          <AlertDialogAction
                            className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                            asChild
                          >
                            <button className="" onClick={handleCancel}>
                              Confirm
                            </button>
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogPortal>
                  </AlertDialog>
                )}
              </div>
            </div>
          </Navbar>
          <div className="gap-5 w-full flex h-full flex-1 flex-grow">
            <div className=" bg-white-A700 p-5 rounded-xl flex flex-col gap-5 w-full">
              <Stepper
                steps={partnerRegistrationSteps.map((val) => val.name)}
                currentStep={currentStep - 1}
              />
              {renderStep()}
              {currentStep !== 6 && (
                <div className="flex gap-3">
                  {currentStep !== 1 && (
                    <button
                      type="button"
                      onClick={handlePrevious}
                      className={`px-4 py-[11px] w-fit rounded-lg border border-blue-700 text-blue-700 ${true ? "" : "opacity-40"}`}
                    >
                      Previous
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={handleNext}
                    className={`px-4 py-[11px] w-fit rounded-lg bg-blue-700 text-white-A700 ${true ? "" : "opacity-40"}`}
                  >
                    {currentStep === partnerRegistrationSteps.length - 1
                      ? "Submit"
                      : "Next"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Toaster theme="dark" position="top-right" />
    </>
  );
};

export default PartnerRegistration;
