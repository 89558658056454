import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { AxiosRequestConfig } from "axios";
import React, { useState, useMemo } from "react";
import {
  getNotification,
  GetNotificationRequestType,
  PatchEditNotification,
  PatchEditNotificationRequest,
} from "service/api";
import { isToday, isYesterday, format } from "date-fns";
import { getRelativeDate } from "pages/Opportunities/utils/featureRule";
import { RowDetailsSheet } from "pages/Opportunities/opportunitydetails";

export default function NotificationPopover({
  children,
}: {
  readonly children: React.ReactNode;
}) {
  const [notifications, setNotifications] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [isRowDetailsOpen, setIsRowDetailsOpen] = useState(false);
  const [rowData, setRowData] = useState<any>({});
  const [readCount, setReadCount] = useState(0);

  const fetchNotifications = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const staff_id = localStorage.getItem("staff_id");
    const payload: AxiosRequestConfig<GetNotificationRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        staff_id: staff_id,
      },
    };
    try {
      const response = await getNotification(payload);
      if (response?.data?.length === 0) return;
      setNotifications(response?.data);
      setReadCount(response?.data?.filter((e) => e.isRead === false)?.length);
    } catch (error) {
      // Error handling
    }
  };

  const markNotification = async (id: string, isRead: boolean) => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<PatchEditNotificationRequest> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        notification_id: id,
        read: isRead,
      },
    };
    try {
      const response = await PatchEditNotification(payload);
    } catch (error) {
      // Error handling
    }
  };

  // Categorize notifications by date
  const categorizedNotifications = useMemo(() => {
    const today: any[] = [];
    const yesterday: any[] = [];
    const older: any[] = [];

    notifications.forEach((notification) => {
      const notificationDate = new Date(notification?.created_at as string);

      if (isToday(notificationDate)) {
        today.push(notification);
      } else if (isYesterday(notificationDate)) {
        yesterday.push(notification);
      } else {
        older.push(notification);
      }
    });

    return { today, yesterday, older };
  }, [notifications]);

  const renderNotificationSection = (title: string, notifications: any[]) =>
    notifications.length > 0 && (
      <>
        <div className="px-0 py-2 text-sm text-[#999999]">{title}</div>
        <div className="space-y-4 overflow-auto">
          {notifications.map((notification, index) => (
            <div
              onClick={() => {
                setIsRowDetailsOpen(true);
                setRowData({
                  id: notification?.extra_info?.lead_id,
                  product: notification?.extra_info?.product,
                  createDate: getRelativeDate(
                    notification?.extra_info?.createdAt_lead,
                  ),
                });
                markNotification(notification?.id as string, true).then(() => {
                  fetchNotifications();
                });
              }}
              key={index}
              // className="w-full"
              className="rounded-lg"
              style={{
                background: !notification?.isRead
                  ? "linear-gradient(89.99deg, #FFFFFF 0.01%, #E1EBFC 99.99%)"
                  : "linear-gradient(89.99deg, #FFFFFF 0.01%, #F9F9F9 99.99%)",
              }}
            >
              <NotificationItem
                id={notification?.id}
                title={notification?.body?.Subject}
                description={notification?.body?.Content}
                time={format(new Date(notification?.created_at), "h:mm a")}
                link={""}
                isRead={notification?.isRead}
                markNotification={markNotification}
                fetchNotification={fetchNotifications}
              />
            </div>
          ))}
        </div>
      </>
    );

  return (
    <Popover
      onOpenChange={(e) => {
        if (e === true) fetchNotifications();
        if (e === false) setIsRowDetailsOpen(false);
      }}
    >
      <PopoverTrigger className="relative">
        {children}
        {readCount !== 0 && (
          <div className="absolute -top-1 right-0">
            <span className="relative flex h-3 w-3">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
            </span>
          </div>
        )}
      </PopoverTrigger>
      <PopoverContent
        align="end"
        side="bottom"
        sideOffset={12}
        className="gap-0 w-auto p-0 will-change-[transform,opacity] animate-slideDownAndFade rounded-lg"
      >
        <div
          style={{
            boxShadow: "0px 4px 4px 0px rgba(112, 112, 112, 0.2)",
          }}
          className="rounded-xl sm:max-w-[400px] max-w-[500px] bg-white-A700 flex flex-col p-4"
        >
          <Card className="border-0">
            <CardHeader className="border-b px-0 py-3">
              <CardTitle className="text-xl flex items-center gap-2">
                Notification{" "}
                <span className="text-sm px-2 py-0.5 bg-[#fbde62] text-yellow-800 rounded-full">
                  {readCount}
                </span>
              </CardTitle>
            </CardHeader>
            <Tabs
              value={activeTab}
              onValueChange={(e) => setActiveTab(e)}
              className="w-full flex flex-col gap-5"
            >
              <TabsList className="w-full justify-start px-0 py-2 h-auto">
                <TabsTrigger
                  value="all"
                  className={`py-1 px-5 disabled:opacity-40 text-sm h-[30px] font-semibold ${"all" === activeTab ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg" : " text-black-label"}`}
                >
                  All
                </TabsTrigger>
                <TabsTrigger
                  disabled
                  value="retail"
                  className={`py-1 px-5 disabled:opacity-40 text-sm h-[30px] font-semibold ${"retail" === activeTab ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg" : " text-black-label"}`}
                >
                  Retail
                </TabsTrigger>
                <TabsTrigger
                  disabled
                  value="corporates"
                  className={`py-1 px-5 disabled:opacity-40 text-sm h-[30px] font-semibold ${"corporates" === activeTab ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg" : " text-black-label"}`}
                >
                  Corporates
                </TabsTrigger>
                {/* <TabsTrigger
                  disabled
                  value="channel"
                  className={`py-1 px-5 disabled:opacity-40 text-sm h-[30px] font-semibold ${"channel" === activeTab ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg" : " text-black-label"}`}
                >
                  Channel
                </TabsTrigger> */}
              </TabsList>
              <TabsContent value="all" className="mt-0 overflow-auto h-[500px]">
                <RowDetailsSheet
                  isOpen={isRowDetailsOpen}
                  onOpenChange={setIsRowDetailsOpen}
                  rowData={rowData}
                  getOpportunityList={() => {}}
                />
                <CardContent className="p-0 overflow-auto">
                  {renderNotificationSection(
                    "Today",
                    categorizedNotifications.today,
                  )}
                  {renderNotificationSection(
                    "Yesterday",
                    categorizedNotifications.yesterday,
                  )}
                  {renderNotificationSection(
                    "Older",
                    categorizedNotifications.older,
                  )}
                </CardContent>
              </TabsContent>
            </Tabs>
          </Card>
        </div>
      </PopoverContent>
    </Popover>
  );
}

function NotificationItem({
  id,
  title,
  description,
  time,
  link,
  isRead = false,
  markNotification,
  fetchNotification,
}: {
  id: string;
  title: string;
  description: string;
  time: string;
  link: string;
  isRead: boolean;
  markNotification: (id: string, isRead: boolean) => Promise<void>;
  fetchNotification: () => Promise<void>;
}) {
  return (
    <div
      className={`p-4 rounded-lg border ${isRead ? "border-[#E3E3E3]" : "border-blue-700"}  hover:bg-gray-50 cursor-pointer`}
    >
      <div className="space-y-1">
        <p className="text-base leading-6 text-blue-700 font-medium">{title}</p>
        <p className="text-sm text-[#666666] leading-[19.6px] font-normal">
          {description}
        </p>
        <div className="flex items-center justify-between">
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xs text-blue-500 hover:underline truncate max-w-[250px]"
          >
            {link}
          </a>
          <span className="text-xs text-gray-400">{time}</span>
        </div>
        {isRead ? (
          <button
            onClick={(e) => {
              markNotification(id, false).then(() => {
                fetchNotification();
              });
              fetchNotification();
              e.stopPropagation();
              e.preventDefault();
            }}
            className="text-right w-full text-xs text-[#666666] underline font-medium leading-[16.8px]"
          >
            Mark unread
          </button>
        ) : (
          <button
            onClick={(e) => {
              markNotification(id, true).then(() => {
                fetchNotification();
              });
              e.stopPropagation();
              e.preventDefault();
            }}
            className="text-right w-full text-xs text-blue-700 underline font-medium leading-[16.8px]"
          >
            Mark Read
          </button>
        )}
      </div>
    </div>
  );
}
