import { type Table } from "@tanstack/react-table";

import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "lucide-react";

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  pageSizeOptions?: number[];
  isPageCountVisible?: boolean;
  onClickNextPage?: () => void;
  onClickPreviousPage?: () => void;
  onClickFirstPage?: () => void;
  onClickLastPage?: () => void;
  totalPages?: number;
  currentPage?: number;
  onClickPageSize?: (pageSize: number) => void;
  pageSize?: number;
  pageIndex?: number;
  canNextPage?: boolean;
  canPreviousPage?: boolean;
  pageCount?: number;
  getPageCount?: () => number;
}

export function DataTablePagination<TData>({
  table,
  pageSizeOptions = [10, 20, 30, 40, 50],
  isPageCountVisible,
  onClickNextPage,
  onClickPreviousPage,
  onClickFirstPage,
  onClickLastPage,
  totalPages,
  currentPage,
  onClickPageSize,
  pageSize,
  canNextPage,
  canPreviousPage,
}: Readonly<DataTablePaginationProps<TData>>) {
  return (
    <div className="flex flex-row gap-4 sm:flex-row sm:items-center sm:justify-between px-2 py-1">
      {isPageCountVisible && (
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
      )}
      <div className="flex flex-row sm:flex-row items-center gap-4 sm:gap-6 lg:gap-8">
        {isPageCountVisible && (
          <div className="flex items-center gap-2">
            <p className="text-sm font-medium ">Rows per page</p>
            <Select
              value={`${table.getState().pagination.pageSize}`}
              onValueChange={(value) => {
                table.setPageSize(Number(value));
              }}
            >
              <SelectTrigger className="h-8 w-[70px]">
                <SelectValue
                  placeholder={table.getState().pagination.pageSize}
                />
              </SelectTrigger>
              <SelectContent side="top">
                {pageSizeOptions.map((pageSize) => (
                  <SelectItem key={pageSize} value={`${pageSize}`}>
                    {pageSize}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
        <div className="flex items-center gap-2">
          <div className="text-sm font-medium whitespace-nowrap">
            Page
            {totalPages ? (
              <span className="">
                {" "}
                {currentPage} of {totalPages}
              </span>
            ) : (
              <span className="">
                {" "}
                {table.getState().pagination.pageIndex + 1} of{" "}
                {table.getPageCount()}
              </span>
            )}
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Go to first page</span>
              <ChevronsLeft className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => {
                canPreviousPage
                  ? onClickPreviousPage?.()
                  : table.previousPage();
              }}
              disabled={
                canPreviousPage ? !canPreviousPage : !table.getCanPreviousPage()
              }
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => {
                canNextPage ? onClickNextPage?.() : table.nextPage();
              }}
              disabled={canNextPage ? !canNextPage : !table.getCanNextPage()}
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRight className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Go to last page</span>
              <ChevronsRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
