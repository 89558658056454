import { FormItem, FormLabel } from "@/components/ui/form";
import { Img, Input } from "components";
import { convertFileToBase64 } from "pages/CreateRemit/documentation";
import React from "react";

export interface IFieldProps {
  type: string;
  name: string;
}
export default function FieldRenderer({
  field,
  isLast,
  setFileBinaries,
  setExtraFieldValues,
  fileBinaries,
  extraFieldValues,
  setFiles,
  files,
}: {
  field: IFieldProps;
  isLast: boolean;
  setFileBinaries: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  setExtraFieldValues: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  fileBinaries: { [key: string]: string };
  extraFieldValues: { [key: string]: string };
  files: { [key: string]: File };
  setFiles: React.Dispatch<React.SetStateAction<{ [key: string]: File }>>;
}) {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const allowedFormats = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/gif",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
    ];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

    // Reset any previous errors
    setExtraFieldValues((prev) => ({ ...prev, [`${field.name}_error`]: "" }));

    if (!file) {
      return;
    }

    // Check file size
    if (file.size > maxFileSize) {
      setExtraFieldValues((prev) => ({
        ...prev,
        [`${field.name}_error`]: "File size must be 5 MB or less",
      }));
      return;
    }

    // Check file format
    if (!allowedFormats.includes(file.type)) {
      setExtraFieldValues((prev) => ({
        ...prev,
        [`${field.name}_error`]:
          "Invalid file format. Please upload PDF, images, DOC, or DOCX",
      }));
      return;
    }

    // If validation passes
    setFiles({ ...files, [event.target.name]: file });
    convertFileToBase64(file).then((res) => {
      setFileBinaries((prev) => ({ ...prev, [event.target.name]: res }));
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExtraFieldValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  switch (field.type) {
    case "text":
      return (
        <FormItem
          className={`space-y-0 relative w-full flex ${isLast ? "flex-row items-start" : "flex-col"} gap-3`}
        >
          <div
            className={`flex items-center top-0 flex-col after:right-10  ${isLast ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src={`images/overview/ellipse.svg`}
              alt=""
            />
          </div>
          <FormLabel
            htmlFor={field.name}
            className={`${isLast ? "text-diabled" : "text-black-label"} text-sm font-medium leading-[17px]`}
          >
            {field.name}
          </FormLabel>
          <Input
            type="text"
            value={extraFieldValues[field.name]}
            onChange={handleInputChange}
            name={field.name}
            placeholder="Enter Remitter Name"
            className="m-0 h-12 max-w-[340px] border border-gray-border-gray-lite p-4 rounded-lg py-2 px-3"
          ></Input>
        </FormItem>
      );
    case "file":
      return (
        <div className="flex relative flex-col gap-2.5 items-start justify-center w-full">
          <div
            className={`flex items-center top-0 flex-col after:right-10  ${isLast ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src={`images/overview/ellipse.svg`}
              alt=""
            />
          </div>
          <p className="text-sm font-medium leading-[17px]">
            Upload {field.name}
          </p>
          <div
            className={`py-1.5 px-3.5 justify-between rounded-md w-full border flex ${files?.[field.name]?.name && "bg-[#F2F7FF]"}`}
          >
            <div className="flex gap-3 items-center overflow-hidden">
              <Img src="/images/remit/file.svg" alt="" />
              <p className="truncate">
                {files?.[field.name]
                  ? files?.[field.name]?.name
                  : "No file choosen"}
              </p>
            </div>
            <label
              htmlFor={field.name}
              className="text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
            >
              {files?.[field.name]?.name ? "Re-upload" : "Upload"}
            </label>
            <input
              name={field.name}
              type="file"
              id={field.name}
              className="hidden"
              onChange={(e) => handleFileChange(e)}
              accept=".pdf,.jpg,.jpeg,.png,.gif,.doc,.docx,.txt,.rtf,.bmp,.tiff,.webp"
            />
          </div>
          {extraFieldValues[`${field.name}_error`] && (
            <p className="text-red-500 text-sm mt-1">
              {extraFieldValues[`${field.name}_error`]}
            </p>
          )}
        </div>
      );
    case "date":
      return (
        <FormItem
          className={`space-y-0 relative w-full flex ${isLast ? "flex-row items-start" : "flex-col"} gap-3`}
        >
          <div
            className={`flex items-center top-0 flex-col after:right-10  ${isLast ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src={`images/overview/ellipse.svg`}
              alt=""
            />
          </div>
          <FormLabel
            htmlFor={field.name}
            className={`${isLast ? "text-diabled" : "text-black-label"} text-sm font-medium leading-[17px]`}
          >
            What is the {field.name}?
          </FormLabel>
          <Input
            type="date"
            value={extraFieldValues[field.name]}
            onChange={handleInputChange}
            name={field.name}
            placeholder=""
            className="m-0 h-12 max-w-[340px] border border-gray-border-gray-lite p-4 rounded-lg py-2 px-3"
          ></Input>
        </FormItem>
      );
    case "number":
      return (
        <FormItem
          className={`space-y-0 relative w-full flex ${isLast ? "flex-row items-start" : "flex-col"} gap-3`}
        >
          <div
            className={`flex items-center top-0 flex-col after:right-10  ${isLast ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src={`images/overview/ellipse.svg`}
              alt=""
            />
          </div>
          <FormLabel
            htmlFor={field.name}
            className={`${isLast ? "text-diabled" : "text-black-label"} text-sm font-medium leading-[17px]`}
          >
            What is the name of remitter
          </FormLabel>
          <Input
            type="number"
            onChange={handleInputChange}
            name={field.name}
            value={extraFieldValues[field.name]}
            placeholder=""
            className="m-0 h-12 max-w-[340px] border border-gray-border-gray-lite p-4 rounded-lg py-2 px-3"
          ></Input>
        </FormItem>
      );
    default:
      return null;
  }
}
