import { useEffect, useState } from "react";
import { Button, Input, Text } from "components";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  postResetPassword,
  PostResetPasswordRequestType,
  postResetPasswordLink,
  PostResetPasswordLinkRequestType,
} from "../../service/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AxiosRequestConfig } from "axios";
import useForm from "../../hooks/useForm";
import AuthActionPage from "components/AuthAction/Index";

const SetPasswordPage: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    // Get the token from the URL query parameters
    const urlSearchParams = new URLSearchParams(window.location.search);
    const tokenParam = urlSearchParams.get("token");

    if (tokenParam) {
      setToken(tokenParam);
    }
  }, []);

  const form = useForm({ new_password: "", confirm_new_password: "" });

  const validatePassword = (password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isLongEnough = password.length >= 8;

    return hasUpperCase && hasNumber && hasSpecialChar && isLongEnough;
  };

  const validate = (newValues: {
    confirm_new_password: string;
    new_password: string;
  }) => {
    if (newValues.confirm_new_password !== newValues.new_password) {
      return "Please make sure passwords are same";
    } else {
      return "";
    }
  };

  const onChangeHandler = (password: string, type: string) => {
    console.log(password);

    const newValues = {
      ...form.values,
      [type]: password,
    };

    let newError = "";
    if (type === "confirm_new_password" || type === "new_password") {
      newError = validate(newValues);
    }

    form.handleChange(type, password);
    setIsPasswordValid(validatePassword(password));

    // Update the error state
    form.errors.confirm_new_password = newError;

    // Force a re-render
    form.handleChange("confirm_new_password", newValues.confirm_new_password);
  };

  const ResetpasswordLink = () => {
    setLoading(true);
    const req: AxiosRequestConfig<PostResetPasswordLinkRequestType> = {
      data: {
        token,
        new_password: form?.values?.new_password,
      },
    };
    setLoading(true);
    postResetPasswordLink(req)
      .then(() => {
        toast.success("Password Changed Successfully");
        setIsSuccess(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message || "Something went wrong");
      });
  };

  const handleSubmit = () => {
    if (form?.values?.confirm_new_password !== form?.values?.new_password) {
      form.errors.confirm_new_password = "Please make sure password are same";
      toast.error("Confirm Password Must be same");
    } else {
      if (token) {
        ResetpasswordLink();
      } else {
        form.handleSubmit(Resetpassword);
      }
    }
  };

  function Resetpassword(
    data: Partial<{ new_password: string; confirm_new_password: string }>,
  ) {
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");

    if (email === null || password === null) {
      return;
    }
    const req: AxiosRequestConfig<PostResetPasswordRequestType> = {
      data: {
        email,
        password,
        new_password: data?.new_password,
      },
    };
    setLoading(true);
    postResetPassword(req)
      .then(() => {
        setLoading(false);
        setIsSuccess(true);
        toast.success("Password Changed Successfully");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message || "Something went wrong");
      });
  }

  const passwordCriteria = [
    {
      test: (password: string) => /[A-Z]/.test(password),
      message: "Use Capital Letters",
      successIcon: "images/login/green-tick.svg",
      defaultIcon: "images/login/exclamation.svg",
      failureIcon: "images/login/red-cross.svg",
    },
    {
      test: (password: string) =>
        /[0-9]/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password),
      message: "Use Numbers and Symbols",
      successIcon: "images/login/green-tick.svg",
      defaultIcon: "images/login/exclamation.svg",
      failureIcon: "images/login/red-cross.svg",
    },
    {
      test: (password: string) => password.length >= 8,
      message: "Password must be at least 8 characters",
      successIcon: "images/login/green-tick.svg",
      defaultIcon: "images/login/exclamation.svg",
      failureIcon: "images/login/red-cross.svg",
    },
  ];
  return (
    <AuthActionPage loading={loading}>
      <div className="flex flex-col gap-[23px] items-start justify-start w-full">
        <Text
          className="leading-[25.00px] flex flex-col gap-2 text-xl text-black-900 text-left"
          size="txtInterRegular16"
        >
          <span className="text-black-900_bf font-inter text-left font-medium">
            {isSuccess
              ? "Password Updated Successfully"
              : "Reset your Password"}
          </span>
          <span className="text-[#707070] font-inter text-base text-left font-normal">
            {isSuccess
              ? "Your password has been reset. You can now Sign in with your new password."
              : "Create a new password to securely regain access to your account."}
          </span>
        </Text>
      </div>
      {!isSuccess && (
        <div className="flex flex-col gap-3 items-start justify-start pt-0.5 w-full">
          <Text className="text-base font-normal text-form-content-label tracking-[0.15px]">
            New Password
          </Text>
          <Input
            name="enterpassword"
            placeholder="Enter your Password"
            className="font-inter p-0 placeholder:text-inputBorder-base text-[15px] border-inputBorder-base text-left w-full"
            wrapClassName="bg-white-A700 border focus-within:border-[#333333] border-black-900_4c border-solid flex pb-[13px] pl-3 pr-4 pt-4 rounded-md w-full"
            type={showPassword ? "text" : "password"}
            onChange={(password: string) =>
              onChangeHandler(password, "new_password")
            }
            errors={form?.errors?.new_password}
            value={form?.values?.new_password}
            suffix={
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }}
                className="pr-2"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            }
          />
          {form.values.new_password && (
            <ul className="flex flex-wrap justify-between w-full gap-2">
              {passwordCriteria.map((criterion, index) => {
                const isValid = criterion.test(form.values.new_password);
                const icon = isValid
                  ? criterion.successIcon
                  : criterion.failureIcon;
                const color = isValid ? "green" : "red";
                return (
                  <li key={index} style={{ color }}>
                    <div className="flex gap-2">
                      <img src={icon} alt="" />
                      <p className=" text-xs">{criterion.message}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
      {!isSuccess && (
        <div className="flex flex-col gap-3 items-start justify-start pt-0.5 w-full">
          <Text className="text-base font-normal text-form-content-label tracking-[0.15px]">
            Verify Password
          </Text>
          <Input
            name="enterpassword_One"
            placeholder="Enter your Password"
            className="font-inter p-0 placeholder:text-inputBorder-base text-[15px]text-left w-full"
            wrapClassName="bg-white-A700 border focus-within:border-[#333333] border-black-900_4c border-solid flex pb-[13px] pl-3 pr-4 pt-4 rounded-md w-full"
            errorClassName="text-[#EF923D]"
            type={showConfirmPassword ? "text" : "password"}
            onChange={(password: string) =>
              onChangeHandler(password, "confirm_new_password")
            }
            errors={[form?.errors?.confirm_new_password]}
            value={form?.values?.confirm_new_password}
            suffix={
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowConfirmPassword(!showConfirmPassword);
                }}
                className="pr-2"
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            }
          />
        </div>
      )}
      {isSuccess ? (
        <div className="flex gap-10 items-center sm:justify-end sm:flex-col justify-start w-full">
          <Link
            to="/"
            className="bg-blue-700 cursor-pointer py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700 w-full"
          >
            <Button>Continue to Sign In</Button>
          </Link>
        </div>
      ) : (
        <div className="flex gap-10 sm:justify-end items-center sm:flex-col justify-start w-full">
          <Link
            to="/"
            className="cursor-pointer py-2.5 rounded-md border text-base text-center text-blue-700 border-blue-700 w-full"
          >
            <Button>Back to Sign in</Button>
          </Link>
          <Button
            className="bg-blue-700 disabled:cursor-not-allowed disabled:opacity-40  cursor-pointer py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700 w-full"
            onClick={handleSubmit}
            disabled={!isPasswordValid}
          >
            Verify
          </Button>
        </div>
      )}
    </AuthActionPage>
  );
};

export default SetPasswordPage;
