import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogPortal,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import PopupForm from "./form";

interface FormPopupProps {
    type: string;
    dialogOpen: boolean;
    setDialogOpen: (e: boolean) => void;
    onSubmit: (data:any) => Promise<void>;
    entityType?: string;
    defaultValue?: string;
}

export default function FormPopup ({ type,dialogOpen,setDialogOpen,onSubmit, entityType, defaultValue }: FormPopupProps) {
    const methods = useForm({
        mode: 'onChange',
    })
    const [isFormValid, setIsFormValid] = useState(false);
    const [key, setKey] = useState(0);
    
    useEffect(() => {
        const subscription = methods.watch((value) => {
            const isValid = Object.values(methods.getValues()).every(value => value !== '' && value !== undefined);
            setIsFormValid(isValid);
        });
        return () => subscription.unsubscribe();
    }, [methods]);

    useEffect(() => {
        // Reset form when type changes
        methods.reset({
            [type === 'PAN' ? 'PAN' : 'aadhaar_number']: defaultValue || ''
        });
        setKey(prevKey => prevKey + 1);
    }, [type, defaultValue]);

    useEffect(() => {
        // Reset form when type changes
        methods.reset();
        setKey(prevKey => prevKey + 1);
    }, [type]);

    const handleCancel = () => {
        methods.reset();
        methods.clearErrors();
        setKey(key=>key+1);
        setDialogOpen(false);
        setIsFormValid(false);
    };

    const renderHeader = () => {
        if(entityType === 'corporate') {
            return type === 'aadhaar' ? 'Enter Aadhar details of Corporate' : 'Enter partner PAN card details';
        }
        if(entityType === 'customer') {
            return type === 'aadhaar' ? 'Enter Aadhar details of the customer' : 'Enter customer PAN card details';
        }
        return type === 'aadhaar' ? 'Enter Aadhar details of Founder/Owner if the partner type is a Firm.' : 'Enter partner PAN card details';
    }
    return (
        <AlertDialog open={dialogOpen}>
            <AlertDialogPortal>
                <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                    <AlertDialogHeader className="flex text-left border-b  rounded-t py-5 px-10 ">
                        <AlertDialogTitle className="font-bold capitalize p-0 text-lg text-black-label">
                            {type} Card Details
                        </AlertDialogTitle>
                        <AlertDialogDescription className="font-medium p-0 text-sm  text-[#7E7E7E] ">
                            {
                                renderHeader()
                            }
                     </AlertDialogDescription>
                    </AlertDialogHeader>
                    <FormProvider {...methods}>
                        <div className="h-[500px] overflow-auto w-full">
                            <PopupForm entityType={entityType as string} key={key} type={type} />
                        </div>
                    </FormProvider>
                    <AlertDialogFooter className="flex justify-start items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                        <AlertDialogCancel
                            className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                            asChild
                        >
                            <button
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                        </AlertDialogCancel>
                        <AlertDialogAction
                            className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                            asChild
                        >
                            <button
                                className="disabled:opacity-40"
                                disabled={!isFormValid}
                                onClick={methods.handleSubmit(async (data) => {
                                    await onSubmit(data);
                                    handleCancel();
                                }
                                )}
                            >                                                                                          
                                Submit
                            </button>
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogPortal>
        </AlertDialog>
    )
}