import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { PartnerContactType, partnersSchema } from "@/lib/validations/schema";
import { ColumnDef } from "@tanstack/react-table";
import { AxiosRequestConfig } from "axios";
import { partner_contact_status } from "common/filters";
import { Spinner } from "components";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import NewCustomersPage from "pages/Customers/newcustomer";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getChannel, GetChannelRequestType } from "service/api";
import {
  getCustomerContacts,
  GetCustomerContactsRequestType,
} from "service/apis/CustomerDetailsapi";
import { Toaster } from "sonner";

const allcolumns: ColumnDef<PartnerContactType>[] = [
  {
    id: "id",
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => <div className="">{row.getValue("id")}</div>,
    enableHiding: false,
    enableSorting: false,
    filterFn: "includesString",
  },
  {
    id: "name",
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    filterFn: "includesString",
    enableSorting: false,
  },
  {
    id: "type",
    accessorKey: "type",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-center"
        column={column}
        title="Type"
      />
    ),
    cell: ({ row }) => {
      return <p className=" text-center w-full">{row.getValue("type")}</p>;
    },
    enableSorting: false,
  },
  {
    id: "location",
    accessorKey: "location",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-center"
        column={column}
        title="Location"
      />
    ),
    cell: ({ row }) => {
      return <p className=" text-center w-full">{row.getValue("location") || 'N/A'},{row.getValue("location") || 'N/A'}</p>;
    },
    enableSorting: false,
  },
  {
    id: "assignedTo",
    accessorKey: "assignedTo",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-center"
        column={column}
        title="Assigned To"
      />
    ),
    cell: ({ row }) => {
      return (
        <p className=" text-center w-full">{row.getValue("assignedTo")}</p>
      );
    },
    enableSorting: false,
  },
  {
    id: "lead_status",
    accessorKey: "lead_status",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-center"
        column={column}
        title="Status"
      />
    ),
    cell: ({ row }) => {
      const partnerStatus = row.original.partnerContactStatus;
      const leadStatus = row.getValue("lead_status");

      // Use partnerStatus if leadStatus is empty/null, otherwise use leadStatus
      const statusToUse = !leadStatus ? partnerStatus : leadStatus;

      const status = partner_contact_status.find(
        (status) => status.value === statusToUse,
      );

      if (!status) {
        return null;
      }

      return (
        <p
          style={{ backgroundColor: status.bgColor, color: status.textColor }}
          className="py-[3px] text-sm leading-4 text-center font-medium max-w-[160px]  px-2 mx-auto rounded-md"
        >
          {status.label}
        </p>
      );
    },
    enableSorting: false,
  },
];

const tabData = [
  { label: "All", value: "all", enabled: true },
  { label: "Partners", value: "partners", enabled: true },
];

export interface IPage {
  curPage: number;
  itemsReceived: number;
  itemsTotal: number;
  nextPage: number;
  offset: number;
  pageTotal: number;
  perPage: number;
  prevPage: number;
}

export default function PartnerContact() {
  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState<PartnerContactType[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageDetails, setPageDetails] = useState<IPage>();
  const [activeTab, setActiveTab] = useState("all");
  const [statusFilter, setStatusFilter] = useState("");
  const navigate = useNavigate();

  const onRowClick = (row: any) => {
    if (row?.partnerContactStatus === "lead") {
      navigate(`/leads/partner/${row?.id}`);
    } else {
      navigate(`/partnerDetails/${row?.id}`);
    }
  };

  const columns = useMemo(() => {
    return allcolumns.filter((column) => {
      if (activeTab === "all") {
        return !["contactStatus"].includes(column?.id as string);
      }
      return allcolumns;
    });
  }, [activeTab]);

  const fetchCustomerContacts = async () => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetCustomerContactsRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
    };

    try {
      const response = await getCustomerContacts(payload);
      if (response?.data?.length === 0) return;
      const newOptions = response?.data?.map((item) => {
        return {
          id: item?.id,
          name: item?.full_name,
          mobile: item?.phone_number,
          contactStatus: item?.status,
          city: item?.city,
          state: item?.state,
          email: item?.email,
        };
      });
      setContacts(
        newOptions?.map((item) => {
          return {
            id: item?.id,
            name: item?.name,
            mobile: item?.mobile,
            contactStatus: item?.contactStatus,
          };
        }),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchResults = (page: number, per_page: number) => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetChannelRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        page: page,
        per_page: per_page,
        status: statusFilter,
      },
    };
    getChannel(payload)
      .then((response) => {
        setLoading(false);
        setPartners(() => {
          return (
            response?.data?.items?.map(
              (
                result: Partial<{
                  id: string | number;
                  name: string;
                  address_line_1: string;
                  _realtionship_manager_channels: {
                    fname: string;
                  };
                  relationshil_manager: string;
                  type: string;
                  status: string;
                  lead_status: string;
                }>,
              ) => {
                return {
                  id: result.id,
                  name: result.name,
                  location: result?.city,
                  state: result?.state,
                  assignedTo: result?._realtionship_manager_channels?.fname,
                  type: result.type,
                  partnerContactStatus: result.status,
                  lead_status: result.lead_status,
                  staffName: "",
                  subType: "",
                };
              },
            ) ?? []
          );
        });
        setPageDetails({
          pageTotal: response?.data?.pageTotal,
          curPage: response?.data?.curPage,
          itemsReceived: response?.data?.itemsReceived,
          itemsTotal: response?.data?.itemsTotal,
          nextPage: response?.data?.nextPage,
          offset: response?.data?.offset,
          perPage: response?.data?.perPage,
          prevPage: response?.data?.prevPage,
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchResults(page, perPage);
  }, [statusFilter, activeTab, page, perPage]);
  return (
    <>
      {loading && <Spinner />}
      <div className="flex h-full gap-10 py-5 px-6 sm:px-0 w-full flex-col overflow-hidden">
        {/* <div className=" flex border-b w-full justify-between items-center">
          <div className=" flex pb-4 flex-col gap-2">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              <span className="capitalize">
                {activeTab === "all" ? "Contacts" : "Customers"}
              </span>
            </h3>
          </div>
        </div> */}
        <div className="flex gap-5 justify-between w-full items-center">
          <Tabs
            className="bg-navbar-menu-bg w-fit flex rounded-lg gap-2"
            value={activeTab}
            onValueChange={(val) => {
              if (val === "partners") {
                setStatusFilter("active");
              } else {
                setStatusFilter("");
              }
              setActiveTab(val);
            }}
          >
            <TabsList className="h-[44px]">
              {tabData
                .filter((tab) => true)
                .map((tab, index: number) => (
                  <TabsTrigger
                    key={index}
                    value={tab.value}
                    disabled={!tab.enabled}
                    className={`py-3 px-10 disabled:opacity-40 text-sm h-[44px] min-w-[150px] font-semibold ${
                      tab.value === activeTab
                        ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg"
                        : " text-black-label"
                    }`}
                  >
                    {tab.label}
                  </TabsTrigger>
                ))}
            </TabsList>
          </Tabs>
          {activeTab === "all" ? (
            <Link
              to={"/lead/createcontact/partner?source=new-partner-lead"}
              className="sm:hidden"
              // onClick={(e) => e.preventDefault()}
            >
              <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                New Partner Lead
              </button>
            </Link>
          ) : (
            // to={"/customerregistration"}
            <Link
              className="sm:hidden"
              to="/partnerregistration"
              // Remove the onClick handler that was preventing navigation
            >
              <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                Register Partner
              </button>
            </Link>
          )}
        </div>
        <div className="flex-1 relative overflow-auto">
          {loading && <Spinner />}
          {!activeTab ? (
            <NewCustomersPage />
          ) : (
            <DataTable
              columns={columns}
              data={partners}
              onRowClick={(row) => onRowClick(row)}
              isToolbarVisible={true}
              isPaginationVisible={true}
              searchData={{
                placeholder: "Search by entity/freelancer name",
                columns: ["name", "mobile"],
              }}
              isPageCountVisible={false}
              defaultSorting={[{ id: "id", desc: true }]}
              onClickNextPage={() => setPage((page) => page + 1)}
              onClickPreviousPage={() => setPage((page) => page - 1)}
              totalPages={pageDetails?.pageTotal}
              currentPage={pageDetails?.curPage}
              canNextPage={!!pageDetails?.nextPage}
              canPreviousPage={!!pageDetails?.prevPage}
            />
          )}
        </div>
        {activeTab === "all" ? (
          <Link
            className="p-2.5 rounded-[20px] hidden sm:flex text-center border border-blue-700"
            to={"/lead/createcontact"}
          >
            <button className="rounded-lg py-[11px] px-4 border w-full bg-blue-700 text-white-A700 font-semibold text-sm">
              New Partner Lead
            </button>
          </Link>
        ) : (
          <Link
            className="p-2.5 rounded-[20px] hidden sm:flex text-center border border-blue-700"
            to={"/partnerregistration"}
          >
            <button className="rounded-lg py-[11px] px-4 border w-full bg-blue-700 text-white-A700 font-semibold text-sm">
              Register Partner
            </button>
          </Link>
        )}
        <Toaster position="top-right" />
      </div>
    </>
  );
}
