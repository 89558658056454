import { Spinner } from "components";
import Stepper from "components/Stepper/newstepper";
import Alert from "models/Alert/alert";
import { useEffect, useState } from "react";
import { partnerLeadSteps } from "utils";
import { useAppDispatch } from "redux/hooks";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3, { isStep3Valid } from "./step3";
import { Form, FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  step1Schema,
  step2Schema,
  step3Schema,
  defaultValues,
  CreatePartnerLeadType,
} from "./schema";
import { submitPartnerAcquisition } from "redux/features/PartnerAcquisition";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { isStep1Valid } from "./step1";
import { isStep2Valid } from "./step2";

export default function CreatePartnerLead() {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const contactID = searchParams.get("c_id");
  const relation = searchParams.get("relation");
  const getSchema = () => {
    if (currentStep === 1) return step1Schema;
    if (currentStep === 2) return step2Schema;
    return step3Schema;
  };

  const methods = useForm<CreatePartnerLeadType>({
    mode: "onChange",
    resolver: zodResolver(getSchema()),
    defaultValues,
  });
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/lead/contacts/partner");
  };

  const handleNext = async () => {
    if (currentStep === partnerLeadSteps.length) {
      const formData = methods.getValues();

      const payload = {
        entityName: formData.name,
        type: formData.partner_subtype,
        city: formData.partner_city,
        state: formData.partner_state,
        rm_id: parseInt(formData.assigned_to),
        services: formData.services,
        meeting_time: new Date(formData.meeting_time).getTime(),
        meetingStaff_id: formData.meeting_members,
        channel_of_interaction_id: formData.channel_of_interaction_id,
        notes: formData.notes,
        contact_id: Number(contactID),
        relationship_with_partner: relation,
      };

      try {
        const response = await dispatch(submitPartnerAcquisition(payload));

        if (response) {
          console.log(response?.payload);
          navigate(`/leads/partner/${response?.payload?.id}`);
        }
      } catch (err) {
        console.error("Submission failed:", err);
      }
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <FormProvider {...methods}>
            <Step1 handleNext={handleNext} />
          </FormProvider>
        );
      case 2:
        return (
          <FormProvider {...methods}>
            <Step2 />
          </FormProvider>
        );
      case 3:
        return (
          <FormProvider {...methods}>
            <Step3 />
          </FormProvider>
        );
      default:
        return null;
    }
  };

  const buttonState = () => {
    switch (currentStep) {
      case 1:
        const { partner_type, partner_subtype, name } = methods.getValues();
        return isStep1Valid(partner_type, partner_subtype, name);
      case 2:
        const { services, branch, assigned_to } = methods.getValues();
        return isStep2Valid(services, branch, assigned_to);
      case 3:
        const {
          contacted_type,
          channelofcontact_type,
          meeting_time,
          channel_of_interaction_id,
          meeting_members,
        } = methods.getValues();

        return isStep3Valid({
          contacted_type,
          channelofcontact_type,
          meeting_time,
          channel_of_interaction_id,
          meeting_members,
        });
      default:
        return false;
    }
  };
  return (
    <>
      {loading && <Spinner />}
      <div className="sm:p-1 flex flex-col font-inter items-start justify-start overflow-hidden mx-auto w-full">
        <div className="gap-5 w-full flex h-full flex-col">
          <div className=" flex items-center w-full justify-between border-b gap-2 px-5 pt-2.5 pb-5">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              <span className="capitalize">
                {partnerLeadSteps.map((val) => val.name)[currentStep - 1]}
              </span>
            </h3>
            <Alert
              handleSubmit={handleCancel}
              actionText="lead"
              actionButtonText="confirm"
            >
              <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                Cancel
              </button>
            </Alert>
          </div>
          <div className="rounded-xl flex flex-col justify-between h-full gap-5 w-full sm:px-0 px-5 py-2.5 overflow-auto">
            <Stepper
              steps={partnerLeadSteps.map((val) => val.name)}
              currentStep={currentStep - 1}
            />
            <div className="flex relative flex-col gap-5 w-full overflow-y-auto flex-grow">
              {renderStep()}
            </div>
            <div className="flex gap-3 sm:flex-col sm:items-start">
              {currentStep !== 1 && (
                <button
                  type="button"
                  onClick={handlePrevious}
                  className={`px-4 py-[11px] w-fit text-sm font-semibold rounded-lg text-blue-700 ${
                    true ? "" : "opacity-40"
                  }`}
                >
                  Previous
                </button>
              )}
              <button
                type="button"
                disabled={!buttonState()}
                onClick={handleNext}
                className={`px-4 py-[11px] w-fit text-sm font-semibold rounded-lg border bg-blue-700 text-white-A700 disabled:opacity-40`}
              >
                {currentStep === partnerLeadSteps.length ? "Submit" : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
