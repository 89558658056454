import { add, format } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { TimePicker12 } from "@/components/ui/time-picker-12";
import { PopoverClose } from "@radix-ui/react-popover";

interface DateTimePickerProps {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
}

export function DateTimePicker({ date, setDate }: DateTimePickerProps) {
  /**
   * carry over the current time when a user clicks a new day
   * instead of resetting to 00:00
   */
  const handleSelect = (newDay: Date | undefined) => {
    if (!newDay) return;
    if (!date) {
      setDate(newDay);
      return;
    }
    const diff = newDay?.getTime() - date?.getTime();
    const diffInDays = diff / (1000 * 60 * 60 * 24);
    const newDateFull = add(date, { days: Math.ceil(diffInDays) });
    setDate(newDateFull);
  };
  return (
    <Popover>
      <PopoverTrigger className=" h-[48px]" asChild>
        <Button
          variant={"outline"}
          className={cn(
            " justify-start text-left flex items-center gap-3 font-normal",
            !date && "text-muted-foreground justify-between",
          )}
        >
          {date ? format(date, "PPP HH:mm") : <span>Pick a date</span>}
          <svg
            width="24"
            height="24"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.9358 4.45V2.5C20.9358 1.9875 20.5108 1.5625 19.9983 1.5625C19.4858 1.5625 19.0608 1.9875 19.0608 2.5V4.375H10.9358V2.5C10.9358 1.9875 10.5108 1.5625 9.99833 1.5625C9.48583 1.5625 9.06083 1.9875 9.06083 2.5V4.45C5.68583 4.7625 4.04833 6.775 3.79833 9.7625C3.77333 10.125 4.07333 10.425 4.42333 10.425H25.5733C25.9358 10.425 26.2358 10.1125 26.1983 9.7625C25.9483 6.775 24.3108 4.7625 20.9358 4.45Z"
              fill="#165FE3"
            />
            <path
              d="M25 12.3047C25.6875 12.3047 26.25 12.8672 26.25 13.5547V21.2547C26.25 25.0047 24.375 27.5047 20 27.5047H10C5.625 27.5047 3.75 25.0047 3.75 21.2547V13.5547C3.75 12.8672 4.3125 12.3047 5 12.3047H25Z"
              fill="#E1EBFC"
            />
            <path
              d="M10.625 18.7486C10.3 18.7486 9.975 18.6111 9.7375 18.3861C9.5125 18.1486 9.375 17.8236 9.375 17.4986C9.375 17.1736 9.5125 16.8486 9.7375 16.6111C10.0875 16.2611 10.6375 16.1486 11.1 16.3486C11.2625 16.4111 11.4 16.4986 11.5125 16.6111C11.7375 16.8486 11.875 17.1736 11.875 17.4986C11.875 17.8236 11.7375 18.1486 11.5125 18.3861C11.275 18.6111 10.95 18.7486 10.625 18.7486Z"
              fill="#165FE3"
            />
            <path
              d="M15 18.7486C14.675 18.7486 14.35 18.6111 14.1125 18.3861C13.8875 18.1486 13.75 17.8236 13.75 17.4986C13.75 17.1736 13.8875 16.8486 14.1125 16.6111C14.225 16.4986 14.3625 16.4111 14.525 16.3486C14.9875 16.1486 15.5375 16.2611 15.8875 16.6111C16.1125 16.8486 16.25 17.1736 16.25 17.4986C16.25 17.8236 16.1125 18.1486 15.8875 18.3861C15.825 18.4361 15.7625 18.4861 15.7 18.5361C15.625 18.5861 15.55 18.6236 15.475 18.6486C15.4 18.6861 15.325 18.7111 15.25 18.7236C15.1625 18.7361 15.0875 18.7486 15 18.7486Z"
              fill="#165FE3"
            />
            <path
              d="M19.375 18.75C19.05 18.75 18.725 18.6125 18.4875 18.3875C18.2625 18.15 18.125 17.825 18.125 17.5C18.125 17.175 18.2625 16.85 18.4875 16.6125C18.6125 16.5 18.7375 16.4125 18.9 16.35C19.125 16.25 19.375 16.225 19.625 16.275C19.7 16.2875 19.775 16.3125 19.85 16.35C19.925 16.375 20 16.4125 20.075 16.4625C20.1375 16.5125 20.2 16.5625 20.2625 16.6125C20.4875 16.85 20.625 17.175 20.625 17.5C20.625 17.825 20.4875 18.15 20.2625 18.3875C20.2 18.4375 20.1375 18.4875 20.075 18.5375C20 18.5875 19.925 18.625 19.85 18.65C19.775 18.6875 19.7 18.7125 19.625 18.725C19.5375 18.7375 19.45 18.75 19.375 18.75Z"
              fill="#165FE3"
            />
            <path
              d="M10.625 23.125C10.4625 23.125 10.3 23.0875 10.15 23.025C9.9875 22.9625 9.8625 22.875 9.7375 22.7625C9.5125 22.525 9.375 22.2 9.375 21.875C9.375 21.55 9.5125 21.225 9.7375 20.9875C9.8625 20.875 9.9875 20.7875 10.15 20.725C10.375 20.625 10.625 20.6 10.875 20.65C10.95 20.6625 11.025 20.6875 11.1 20.725C11.175 20.75 11.25 20.7875 11.325 20.8375C11.3875 20.8875 11.45 20.9375 11.5125 20.9875C11.7375 21.225 11.875 21.55 11.875 21.875C11.875 22.2 11.7375 22.525 11.5125 22.7625C11.45 22.8125 11.3875 22.875 11.325 22.9125C11.25 22.9625 11.175 23 11.1 23.025C11.025 23.0625 10.95 23.0875 10.875 23.1C10.7875 23.1125 10.7125 23.125 10.625 23.125Z"
              fill="#165FE3"
            />
            <path
              d="M15 23.125C14.675 23.125 14.35 22.9875 14.1125 22.7625C13.8875 22.525 13.75 22.2 13.75 21.875C13.75 21.55 13.8875 21.225 14.1125 20.9875C14.575 20.525 15.425 20.525 15.8875 20.9875C16.1125 21.225 16.25 21.55 16.25 21.875C16.25 22.2 16.1125 22.525 15.8875 22.7625C15.65 22.9875 15.325 23.125 15 23.125Z"
              fill="#165FE3"
            />
            <path
              d="M19.375 23.125C19.05 23.125 18.725 22.9875 18.4875 22.7625C18.2625 22.525 18.125 22.2 18.125 21.875C18.125 21.55 18.2625 21.225 18.4875 20.9875C18.95 20.525 19.8 20.525 20.2625 20.9875C20.4875 21.225 20.625 21.55 20.625 21.875C20.625 22.2 20.4875 22.525 20.2625 22.7625C20.025 22.9875 19.7 23.125 19.375 23.125Z"
              fill="#165FE3"
            />
          </svg>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 pointer-events-auto">
        <Calendar
          mode="single"
          fromDate={new Date()}
          selected={date}
          onSelect={(d) => handleSelect(d)}
          initialFocus
          classNames={{
            day_selected: "bg-blue-700 text-white-A700",
          }}
        />
        <div className="p-3 border-t border-border space-y-2">
          <TimePicker12 setDate={setDate} date={date} />
          <PopoverClose className="w-full">
            <button
              disabled={!date}
              className="bg-blue-700 disabled:opacity-40 text-white-A700 w-full py-1.5 rounded-md border border-blue-700"
            >
              Set
            </button>
          </PopoverClose>
        </div>
      </PopoverContent>
    </Popover>
  );
}
