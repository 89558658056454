import { Spinner } from "components";
import Stepper from "components/Stepper/newstepper";
import Alert from "models/Alert/alert";
import { useState } from "react";
import { corporateLeadSteps } from "utils";
import { Form, FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CreateCorporateLeadType,
  defaultValues,
  step1Schema,
  step2Schema,
  step3Schema,
} from "./schema";
import { Location, useLocation, useNavigate } from "react-router-dom";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import { AxiosRequestConfig } from "axios";
import {
  postCorporateLead,
  PostCorporateLeadReqType,
} from "service/apis/Opportunities";
import { toast } from "sonner";

interface CorporateLocationProps extends Location {
  state: {
    contact_id: string;
    relation: string;
  };
}

const CorporateLead = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation() as CorporateLocationProps;
  const navigate = useNavigate();
  const getSchema = () => {
    if (currentStep === 1) return step1Schema;
    if (currentStep === 2) return step2Schema;
    return step3Schema;
  };
  const methods = useForm<CreateCorporateLeadType>({
    mode: "onChange",
    resolver: zodResolver(getSchema()),
    defaultValues,
  });
  const handleCancel = () => {
    navigate("/lead/contacts/corporate");
  };

  const handleNext = async () => {
    if (currentStep === corporateLeadSteps.length) {
      handleSubmit();
    }
    if (currentStep < corporateLeadSteps.length) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PostCorporateLeadReqType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        name: methods.getValues("company_name"),
        type: methods.getValues("corporate_type") as "SMB" | "enterprise",
        website: methods.getValues("company_website"),
        state: methods.getValues("company_state"),
        city: methods.getValues("company_city"),
        services: methods.getValues("services"),
        rm_id: methods.getValues("assigned_to"),
        channel_of_interaction_id: methods.getValues(
          "channel_of_interaction_id",
        ),
        meeting_staff: methods.getValues("meeting_members"),
        contacted_with: methods.getValues("contacted_to"),
        meeting_time: methods.getValues("meeting_time"),
        branch: methods.getValues("branch"),
        note: methods.getValues("notes"),
        contacts_id: state?.contact_id,
        relation: state?.relation,
      },
    };
    try {
      const response = await postCorporateLead(payload);
      if (response.status === 200) {
        setLoading(false);
        toast.success("Corporate Lead created successfully");
        navigate(`/leads/corporate/${response.data.id}`);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const buttonState = () => {
    const {
      company_city,
      company_name,
      meeting_time,
      channelofcontact_type,
      meeting_members,
      company_state,
      channel_of_interaction_id,
      company_website,
      contacted_to,
      corporate_type,
      services,
      assigned_to,
      branch,
    } = methods.getValues();
    switch (currentStep) {
      case 1:
        if (
          !corporate_type ||
          !company_name ||
          !company_city ||
          !company_state
          // !company_website
        )
          return false;
        return true;
      case 2:
        if (!services.length || !assigned_to || !branch) return false;
        return true;
      case 3:
        if (channelofcontact_type === "meeting") {
          if (!meeting_time || !meeting_members.length) return false;
        }
        return true;
      default:
        return false;
    }
  };

  const renderStep = () => {
    if (currentStep === 1) {
      return (
        <FormProvider {...methods}>
          {" "}
          <Step1 />
        </FormProvider>
      );
    }
    if (currentStep === 2) {
      return (
        <FormProvider {...methods}>
          <Step2 />
        </FormProvider>
      );
    }
    return (
      <FormProvider {...methods}>
        <Step3 />
      </FormProvider>
    );
  };
  return (
    <div className="sm:p-1 flex flex-col font-inter items-start justify-start overflow-hidden mx-auto w-full">
      {loading && <Spinner />}
      <div className="gap-5 w-full flex h-full flex-col">
        <div className=" flex items-center w-full justify-between border-b gap-2 px-5 pt-2.5 pb-5">
          <h3 className=" font-semibold leading-4 text-lg text-black-label">
            <span className="capitalize">
              {corporateLeadSteps.map((val) => val.name)[currentStep - 1]}
            </span>
          </h3>
          <Alert
            handleSubmit={handleCancel}
            actionText="lead"
            actionButtonText="confirm"
          >
            <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
              Cancel
            </button>
          </Alert>
        </div>
        <div className="rounded-xl flex flex-col justify-between h-full gap-5 w-full sm:px-0 px-5 py-2.5 overflow-auto">
          <Stepper
            steps={corporateLeadSteps.map((val) => val.name)}
            currentStep={currentStep - 1}
          />
          <div className="flex relative flex-col gap-5 w-full overflow-y-auto flex-grow">
            {renderStep()}
          </div>
          <div className="flex gap-3 sm:flex-col sm:items-start">
            {currentStep !== 1 && (
              <button
                type="button"
                onClick={handlePrevious}
                className={`px-4 py-[11px] w-fit text-sm font-semibold rounded-lg text-blue-700 ${
                  true ? "" : "opacity-40"
                }`}
              >
                Previous
              </button>
            )}
            <button
              disabled={!buttonState()}
              type="button"
              onClick={handleNext}
              className={`px-4 py-[11px] w-fit text-sm font-semibold rounded-lg border bg-blue-700 text-white-A700 disabled:opacity-40`}
            >
              {currentStep === corporateLeadSteps.length ? "Submit" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateLead;
