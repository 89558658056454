import {
  ArrowDown,
  ArrowRight,
  ArrowUp,
  Bug,
  CheckCircle2,
  Circle,
  HelpCircle,
  MailWarning,
  PackagePlus,
  ScrollText,
  Timer,
  XCircle,
} from "lucide-react";
import { FaExclamation } from "react-icons/fa";

export const deal_utilization_options = [
  { value: "unutilized", label: "Unutilized" },
  { value: "utilized", label: "Utilized" },
];

export const opportunity_status_options = [
  {
    value: "open",
    label: "Open",
    icon: FaExclamation,
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
  {
    value: "close",
    label: "Close",
    icon: CheckCircle2,
    textColor: "#C8831B",
    bgColor: "#FFF0D9",
  },
];

export const opportunity_contact_status_options = [
  {
    value: "open",
    label: "Open",
    icon: FaExclamation,
    textColor: "#5850EC",
    bgColor: "#CDDBFE",
  },
  {
    value: "close",
    label: "Close",
    icon: CheckCircle2,
    textColor: "#C8831B",
    bgColor: "#FFF0D9",
  },
];

export const deal_status_options = [
  {
    value: "unconfirmed",
    label: "Unconfirmed",
    icon: FaExclamation,
    textColor: "#f43f5e",
    bgColor: "#fecdd3",
  },
  {
    value: "confirmed",
    label: "Confirmed",
    icon: CheckCircle2,
    textColor: "#9747FF",
    bgColor: "#DABDFF",
  },
  {
    value: "hold",
    label: "Hold",
    icon: MailWarning,
    bgColor: "#D2D2D2",
    textColor: "#232323",
  },
  {
    value: "cancelled",
    label: "Cancelled",
    icon: XCircle,
    textColor: "#EA2C2C",
    bgColor: "#FFCECE",
  },
  {
    value: "fulfilled",
    label: "Fulfilled",
    icon: CheckCircle2,
    textColor: "#22c55e",
    bgColor: "#86efac",
  },
];

export const customer_status_options = [
  {
    value: "active",
    label: "Active",
    icon: CheckCircle2,
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
  {
    value: "pending",
    label: "Pending",
    icon: XCircle,
    textColor: "#333333",
    bgColor: "#E8E8E8",
  },
  {
    value: "blocked",
    label: "Blocked",
    icon: MailWarning,
    bgColor: "#D2D2D2",
    textColor: "#232323",
  },
  {
    value: "conditional active",
    label: "Conditional Active",
    icon: XCircle,
    textColor: "#9747FF",
    bgColor: "#DABDFF78",
  },
  {
    value: "archieved",
    label: "Archived",
    icon: XCircle,
    textColor: "#EA2C2C",
    bgColor: "#FFCECE",
  },
];
export const partner_status_options = [
  {
    value: "active",
    label: "Active",
    icon: CheckCircle2,
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
  {
    value: "inactive",
    label: "In-active",
    icon: XCircle,
    textColor: "#EA2C2C",
    bgColor: "#FFCECE",
  },
  {
    value: "incomplete",
    label: "Incomplete",
    icon: MailWarning,
    bgColor: "#FFF0D9",
    textColor: "#F4960A",
  },
  {
    value: "pending",
    label: "Pending",
    icon: FaExclamation,
    textColor: "#5850EC",
    bgColor: "#CDDBFE",
  },
];
export const kyc_status_options = [
  {
    value: "active",
    label: "Full KYC",
    icon: CheckCircle2,
    textColor: "#22c55e",
    bgColor: "#86efac",
  },
  {
    value: "expired",
    label: "Expired",
    icon: XCircle,
    textColor: "#EA2C2C",
    bgColor: "#FFCECE",
  },
  {
    value: "not applicable",
    label: "Not Applicable",
    icon: FaExclamation,
    textColor: "#94A3B8",
    bgColor: "#CDDBFE",
  },
  {
    value: "unverified",
    label: "Unverified",
    icon: XCircle,
    textColor: "#60A5FA",
    bgColor: "#FFCECE",
  },
];

export const finance_status_options = [
  {
    value: "initiated",
    label: "Initiated",
    icon: FaExclamation,
    textColor: "#5850EC",
    bgColor: "#CDDBFE",
  },
  {
    value: "completed",
    label: "Completed",
    icon: CheckCircle2,
    textColor: "#22c55e",
    bgColor: "#86efac",
  },
];

export const finance_payout_status_options = [
  {
    value: "initiated",
    label: "Initiated",
    icon: FaExclamation,
    textColor: "#5850EC",
    bgColor: "#CDDBFE",
  },
  {
    value: "draft",
    label: "Draft",
    icon: CheckCircle2,
    textColor: "#22c55e",
    bgColor: "#86efac",
  },
];

export const order_status_options = [
  {
    value: "processing",
    label: "Processing",
    icon: FaExclamation,
    textColor: "#5850EC",
    bgColor: "#CDDBFE",
  },
  {
    value: "pre processing",
    label: "Pre Processing",
    icon: FaExclamation,
    textColor: "#333333",
    bgColor: "#ECECEC",
  },
  {
    value: "cancelled",
    label: "Cancelled",
    icon: XCircle,
    textColor: "#EA2C2C",
    bgColor: "#FFCECE",
  },
  {
    value: "fulfilled",
    label: "Fulfilled",
    icon: CheckCircle2,
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
];

export const payment_status_options = [
  {
    value: "received",
    label: "Received",
    icon: FaExclamation,
    textColor: "#5850EC",
    bgColor: "#CDDBFE",
  },
  {
    value: "pending",
    label: "Pending",
    icon: XCircle,
    textColor: "#d97706",
    bgColor: "#fde68a",
  },
  {
    value: "partially received",
    label: "Partially Received",
    icon: CheckCircle2,
    textColor: "#15803d",
    bgColor: "#86efac",
  },
];

export const status_options = [
  {
    value: "backlog",
    label: "Backlog",
    icon: HelpCircle,
  },
  {
    value: "todo",
    label: "Todo",
    icon: Circle,
  },
  {
    value: "in-progress",
    label: "In Progress",
    icon: Timer,
  },
  {
    value: "done",
    label: "Done",
    icon: CheckCircle2,
  },
  {
    value: "canceled",
    label: "Canceled",
    icon: XCircle,
  },
];

export const label_options = [
  {
    value: "bug",
    label: "Bug",
    icon: Bug,
  },
  {
    value: "feature",
    label: "Feature",
    icon: PackagePlus,
  },
  {
    value: "documentation",
    label: "Documentation",
    icon: ScrollText,
  },
];

export const priority_options = [
  {
    value: "low",
    label: "Low",
    icon: ArrowDown,
  },
  {
    value: "medium",
    label: "Medium",
    icon: ArrowRight,
  },
  {
    value: "high",
    label: "High",
    icon: ArrowUp,
  },
];

export const staff_status_options = [
  {
    value: "active",
    label: "Active",
    icon: CheckCircle2,
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
  {
    value: "inactive",
    label: "Inactive",
    icon: XCircle,
    textColor: "#F4C80A",
    bgColor: "#F7C90533",
  },
];

export const channel_options = {
  inbound: [
    {
      value: "call",
      label: "Call",
      icon: "/images/channels/inbound_call.svg",
      type: "inbound",
    },
    {
      value: "whatsapp",
      label: "WhatsApp",
      icon: "/images/channels/whatsapp_inbound.svg",
      type: "inbound",
    },
    {
      value: "email",
      label: "Email",
      icon: "/images/channels/inbound_email.svg",
      type: "inbound",
    },
    {
      value: "other",
      label: "Other",
      icon: "/images/channels/chat_inbound.svg",
      type: "inbound",
    },
    {
      value: "call",
      label: "Call",
      icon: "/images/channels/inbound_call.svg",
      type: "outbound",
    },
    {
      value: "whatsapp",
      label: "WhatsApp",
      icon: "/images/channels/whatsapp_outbound.svg",
      type: "outbound",
    },
    {
      value: "other",
      label: "Other",
      icon: "/images/channels/chat_outbound.svg",
      type: "outbound",
    },
    {
      value: "refferal",
      label: "Referral",
      icon: "/images/channels/referral.svg",
      type: "inbound",
    },
    {
      value: "socialMedia",
      label: "Social Media",
      icon: "/images/channels/chat_inbound.svg",
      type: "inbound",
    },
    {
      value: "chat",
      label: "Chat",
      icon: "/images/channels/chat_inbound.svg",
      type: "inbound",
    },
    {
      value: "walk-in",
      label: "Walk-in",
      icon: "/images/channels/walkin.svg",
      type: "inbound",
    },
  ],
  outbound: [
    {
      value: "call",
      label: "Call",
      icon: "/images/channels/outbound_call.svg",
      type: "outbound",
    },
    {
      value: "whatsapp",
      label: "WhatsApp",
      icon: "/images/channels/whatsapp_inbound.svg",
      type: "outbound",
    },
    {
      value: "other",
      label: "Other",
      icon: "/images/channels/chat_inbound.svg",
      type: "outbound",
    },
    {
      value: "call",
      label: "Call",
      icon: "/images/channels/call_outbound.svg",
      type: "outbound",
    },
    {
      value: "whatsapp",
      label: "WhatsApp",
      icon: "/images/channels/whatsapp_outbound.svg",
      type: "outbound",
    },
    {
      value: "email",
      label: "Email",
      icon: "/images/channels/outbound_email.svg",
      type: "outbound",
    },
    {
      value: "other",
      label: "Other",
      icon: "/images/channels/chat_outbound.svg",
      type: "outbound",
    },
    {
      value: "refferal",
      label: "Referral",
      icon: "/images/channels/referral.svg",
      type: "outbound",
    },
    {
      value: "socialMedia",
      label: "Social Media",
      icon: "/images/channels/chat_outbound.svg",
      type: "outbound",
    },
    {
      value: "chat",
      label: "Chat",
      icon: "/images/channels/chat_outbound.svg",
      type: "outbound",
    },
    {
      value: "Walk-in",
      label: "Walk-in",
      icon: "/images/channels/walkin.svg",
      type: "outbound",
    },
    {
      value: "meeting",
      label: "Meeting",
      icon: "/images/channels/walkin.svg",
      type: "outbound",
    },
  ],
};

export const contact_lead_status = [
  {
    value: "conditional active",
    label: "Conditional Active",
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
  {
    value: "close",
    label: "Close",
    textColor: "#C8831B",
    bgColor: "#FFF0D9",
  },
  {
    value: "active",
    label: "Active",
    icon: FaExclamation,
    textColor: "#5850EC",
    bgColor: "#CDDBFE",
  },
  {
    value: "inActive",
    label: "In-active",
    icon: CheckCircle2,
    textColor: "#C8831B",
    bgColor: "#FFF0D9",
  },
];

export const partner_contact_status = [
  {
    value: "active",
    label: "Active",
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
  {
    value: "negotiating",
    label: "Negotiating",
    bgColor: "#CDFBFF",
    textColor: "#1FBECD",
  },
  {
    value: "contacted",
    label: "Contacted",
    textColor: "#165FE3",
    bgColor: "#E1EBFC",
  },
  {
    value: "proposal",
    label: "Proposal",
    textColor: "#9165B8",
    bgColor: "#F6ECFF",
  },
  {
    value: "archived",
    label: "Archived",
    textColor: "#F6ECFF",
    bgColor: "#9165B8",
  },
  {
    value: "notResponded",
    label: "Not Responded",
    textColor: "#8A544A",
    bgColor: "#EBDCD9",
  },
  {
    value: "notinterested",
    label: "Not Interested",
    textColor: "#666666",
    bgColor: "#F1F1F1",
  },
  {
    value: "new",
    label: "New",
    textColor: "#5850EC",
    bgColor: "#CDDBFE",
  },
  {
    value: "lead",
    label: "Lead",
    textColor: "#1e293b",
    bgColor: "#ecfccb",
  },
  {
    value: "pending",
    label: "Pending",
    textColor: "#1e293b",
    bgColor: "#ecfccb",
  },
  {
    value: "incomplete",
    label: "Incomplete",
    textColor: "#1e293b",
    bgColor: "#ecfccb",
  },
];
export const company_contact_status = [
  {
    value: "Active",
    label: "Active",
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
  {
    value: "negotiating",
    label: "Negotiating",
    bgColor: "#CDFBFF",
    textColor: "#1FBECD",
  },
  {
    value: "contacted",
    label: "Contacted",
    textColor: "#165FE3",
    bgColor: "#E1EBFC",
  },
  {
    value: "proposal",
    label: "Proposal",
    textColor: "#9165B8",
    bgColor: "#F6ECFF",
  },
  {
    value: "archived",
    label: "Archived",
    textColor: "#F6ECFF",
    bgColor: "#9165B8",
  },
  {
    value: "notResponded",
    label: "Not Responded",
    textColor: "#8A544A",
    bgColor: "#EBDCD9",
  },
  {
    value: "notinterested",
    label: "Not Interested",
    textColor: "#666666",
    bgColor: "#F1F1F1",
  },
  {
    value: "new",
    label: "New",
    textColor: "#5850EC",
    bgColor: "#CDDBFE",
  },
  {
    value: "lead",
    label: "Lead",
    textColor: "#1e293b",
    bgColor: "#ecfccb",
  },
  {
    value: "pending",
    label: "Pending",
    textColor: "#1e293b",
    bgColor: "#ecfccb",
  },
  {
    value: "incomplete",
    label: "Incomplete",
    textColor: "#1e293b",
    bgColor: "#ecfccb",
  },
];

export const close_reason_options = [
  {
    value: "buyLater",
    label: "Buy Later",
    textColor: "#FF8B5C",
    bgColor: "#FF8B5C21",
  },
  {
    value: "notInterested",
    label: "Not Interested",
    icon: XCircle,
    bgColor: "#F1F1F1",
    textColor: "#666666",
  },
  {
    value: "notServicable",
    label: " Not Serviceable",
    icon: MailWarning,
    bgColor: "#F1F1F1",
    textColor: "#666666",
  },
  {
    value: "spam",
    label: "Spam",
    icon: FaExclamation,
    textColor: "#666666",
    bgColor: "#F1F1F1",
  },
  {
    value: "noResponse",
    label: "No Response",
    icon: FaExclamation,
    textColor: "#666666",
    bgColor: "#F1F1F1",
  },
  {
    value: "success",
    label: "Success",
    icon: CheckCircle2,
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
  {
    value: "won",
    label: "Won",
    icon: CheckCircle2,
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
];
