import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PartnerRegistrationStatetype = {
  currverifystep: number;
  email: string;
  phone: string;
  Partnertype: string;
  Partnertypeother: string;
  staff_id: string | number;
  Panfront: File | string;
  PanfrontBinary: string;
  PanNumber: string;
  PanType: string;
  adhaarFront: File | string;
  adhaarFrontBinary: string;
  adhaarBack: File | string;
  adhaarBackBinary: string;
  uid_number: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  citylabel: string;
  state: string;
  statelabel: string;
  pincode: string | number;
  photos: File | string;
  gst: string;
  PropertyType: string;
  geolocation: string;
  address_proof: File | string;
  photo_1: File | string;
  Office_Interior1: File[];
  addressBinary: string;
  photosBinary: {
    photo_1: string;
    photo_2: string;
    photo_3: string;
    photo_4: string;
  };
  request_id: string;
  channel_id: string;
  resume_step: string | number;
  resume_form: boolean;
  name: string;
  enabledProducts: number[];
};

const initialState: PartnerRegistrationStatetype = {
  currverifystep: 0,
  resume_step: "",
  email: "",
  phone: "",
  Partnertype: "",
  Partnertypeother: "",
  staff_id: 0,
  Panfront: "",
  PanfrontBinary: "",
  PanNumber: "",
  PanType: "",
  adhaarFront: "",
  adhaarFrontBinary: "",
  adhaarBack: "",
  adhaarBackBinary: "",
  uid_number: "",
  address_line_1: "",
  address_line_2: "",
  city: "",
  citylabel: "",
  state: "",
  statelabel: "",
  pincode: "",
  photos: "",
  gst: "",
  PropertyType: "Owned",
  geolocation: "",
  address_proof: "",
  photo_1: "",
  Office_Interior1: [],
  addressBinary: "",
  photosBinary: {
    photo_1: "",
    photo_2: "",
    photo_3: "",
    photo_4: "",
  },
  request_id: "",
  channel_id: "",
  resume_form: false,
  name: "",
  enabledProducts: [],
};
export const PartnerRegistrationSlice = createSlice({
  initialState,
  name: "PartnerRegistration",
  reducers: {
    incrementVerifystep: (state) => {
      state.currverifystep += 1;
    },
    resetVerifystep: (state) => {
      state.currverifystep = 0;
    },
    setVerifystep: (state, action: PayloadAction<number>) => {
      state.currverifystep=action.payload;
    },
    setResumeForm: (state, action: PayloadAction<boolean>) => {
      state.resume_form=action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    setPartnertype: (state, action: PayloadAction<string>) => {
      state.Partnertype = action.payload;
    },
    setPartnertypeother: (state, action: PayloadAction<string>) => {
      state.Partnertypeother = action.payload;
    },
    setStaffId: (state, action: PayloadAction<string|number>) => {
      state.staff_id = action.payload;
    },
    setPanfront: (state, action: PayloadAction<File | string>) => {
      state.Panfront = action.payload;
    },
    setPanfrontBinary: (state, action: PayloadAction<string>) => {
      state.PanfrontBinary = action.payload;
    },
    setPanNumber: (state, action: PayloadAction<string>) => {
      state.PanNumber = action.payload;
    },
    setPanType: (state, action: PayloadAction<string>) => {
      state.PanType = action.payload;
    },
    setadhaarFront: (state, action: PayloadAction<File | string>) => {
      state.adhaarFront = action.payload;
    },
    setadhaarFrontBinary: (state, action: PayloadAction<string>) => {
      state.adhaarFrontBinary = action.payload;
    },
    setadhaarBack: (state, action: PayloadAction<File | string>) => {
      state.adhaarBack = action.payload;
    },
    setadhaarBackBinary: (state, action: PayloadAction<string>) => {
      state.adhaarBackBinary = action.payload;
    },
    setUidNumber: (state, action: PayloadAction<string>) => {
      state.uid_number = action.payload;
    },
    setAddressLine1: (state, action: PayloadAction<string>) => {
      state.address_line_1 = action.payload;
    },
    setAddressLine2: (state, action: PayloadAction<string>) => {
      state.address_line_2 = action.payload;
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.city = action.payload;
    },
    setCitylabel: (state, action: PayloadAction<string>) => {
      state.citylabel = action.payload;
    },
    setState: (state, action: PayloadAction<string>) => {
      state.state = action.payload;
    },
    setStateLabel: (state, action: PayloadAction<string>) => {
      state.statelabel = action.payload;
    },
    setPincode: (state, action: PayloadAction<string>) => {
      state.pincode = action.payload;
    },
    setPhotos: (state, action: PayloadAction<File | string>) => {
      state.photos = action.payload;
    },
    setGst: (state, action: PayloadAction<string>) => {
      state.gst = action.payload;
    },
    setPropertyType: (state, action: PayloadAction<string>) => {
      state.PropertyType = action.payload;
    },
    setGeolocation: (state, action: PayloadAction<string>) => {
      state.geolocation = action.payload;
    },
    setaddress_proof: (state, action: PayloadAction<File | string>) => {
      state.address_proof = action.payload;
    },
    setPhoto1: (state, action: PayloadAction<File | string>) => {
      state.photo_1 = action.payload;
    },
    setOffice_Interior1: (state, action: PayloadAction<File | string>) => {
      if (action.payload instanceof File) {
        state.Office_Interior1 = [...state.Office_Interior1, action.payload];
      }else{
        state.Office_Interior1 = [];
      }
    },
    setaddressBinary: (state, action: PayloadAction<string>) => {
      state.addressBinary = action.payload;
    },
    setphotosBinary: (
      state,
      action: PayloadAction<{
        photo_1: string;
        photo_2: string;
        photo_3: string;
        photo_4: string;
      }>
    ) => {
      state.photosBinary = action.payload;
    },
    setchannel_id: (state, action: PayloadAction<string>) => {
      state.channel_id = action.payload;
    },
    setrequest_id: (state, action: PayloadAction<string>) => {
      state.request_id = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setEnabledProducts: (state,  action: PayloadAction<number[]>) => {
      state.enabledProducts=action.payload
    },
    resetState: (state) => initialState
  },
});

export const {
  resetState,
  setEmail,
  setPhone,
  setPartnertype,
  setPartnertypeother,
  resetVerifystep,
  setVerifystep,
  setResumeForm,
  setStaffId,
  setPanfront,
  setPanfrontBinary,
  setPanNumber,
  setPanType,
  setadhaarFront,
  setadhaarFrontBinary,
  setadhaarBack,
  setadhaarBackBinary,
  setUidNumber,
  setAddressLine1,
  setAddressLine2,
  setCity,
  setState,
  setPincode,
  setCitylabel,
  setStateLabel,
  setPhotos,
  setGst,
  setGeolocation,
  setPropertyType,
  incrementVerifystep,
  setaddress_proof,
  setOffice_Interior1,
  setPhoto1,
  setphotosBinary,
  setaddressBinary,
  setchannel_id,
  setrequest_id,
  setName,
  setEnabledProducts,
} = PartnerRegistrationSlice.actions;
export default PartnerRegistrationSlice.reducer;
