import { Controller, useFormContext } from "react-hook-form";

interface EditFormProps {
    type: string;
    entityType: string;
}

export default function PopupForm({ type, entityType }: EditFormProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const dottedBorderWidth = Object.keys(errors).length
    ? "after:h-40 after:border-blue-700"
    : "after:h-32 after:border-blue-700";

  const renderField = (
    placeholder: string,
    name: string,
    label: string,
    type = "text",
    isLast: boolean = false,
    rules?: any,
    isOptional: boolean = false,
    transform?: (value: string) => string
  ) => (
    <div className="flex relative flex-col gap-3 items-start justify-start w-full">
      <div
        className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${isLast ? "after:h-0" : dottedBorderWidth} absolute after:relative after:content-[''] after:border after:border-dashed`}
      >
        <img
          className="relative -left-10"
          src="/images/overview/ellipse.svg"
          alt=""
        />
      </div>
      <label htmlFor={name} className="text-sm font-medium text-black-label">
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={
          rules || {
            ...(isOptional ? {} : { required: "This field is required" }),
            validate: (value) => {
              if (type === "email" && value) {
                return (
                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ||
                  "Invalid email address"
                );
              }
              if (type === "number" && value) {
                return (
                  /^\d{10}$/.test(value) || "Phone number must be 10 digits"
                );
              }
              return true;
            },
          }
        }
        // skipcq: JS-0417
        render={({ field }) =>     
            <input
              {...field}
              type={type}
              placeholder={placeholder}
              className={`border ${errors[name] ? "border-red-500" : "border-black-label"} rounded-lg p-4 w-full`}
              onChange={(e) => {
                const value = transform ? transform(e.target.value) : e.target.value;
                field.onChange(value);
              }}
              max={type === "date" ? new Date().toISOString().split("T")[0] : undefined}              
            />
        }
      />
      {errors[name] && (
        <span className="text-red-500 text-sm">
          {errors[name].message as string}
        </span>
      )}
    </div>
  );

    switch (type) {
        case 'aadhaar':
            return (
                <form className="max-w-md py-8 sm:p-2 pl-9">
                    <div className="flex flex-col sm:pl-0 pl-10 gap-10 w-full">
                        {renderField("Enter Aadhaar number","aadhaar_number", `What is ${entityType !=='customer' ? entityType==='corporate' ? "corporate": '' : "Partner"} Aadhaar number ?`, "tel",false,{
                          required: "Aadhaar Number is required",
                          pattern: {
                            value: /^\d{12}$/,
                            message: "Aadhaar Number must be exactly 12 digits"
                          }
                        }, false, (value) => value.replace(/\D/g, '').slice(0, 12))}
                        {renderField("Enter name (as mentioned in the Aadhaar card)","name", `What is ${entityType !=='customer' ? entityType==='corporate' ? "corporate": '' : "Partner"} name on Aadhaar card ?`, "",false,{
                            required: "Name is required" 
                        })}
                        {renderField("Enter date of birth","date_of_birth", `What is the ${entityType !=='customer' ? entityType==='corporate' ? "corporate": '' : "Partner"} date of birth?`,"date",false,{
                          required: "Date of Birth is required"
                        })}
                        {renderField("Enter address (as mentioned in the Aadhaar card)","address", `What is ${entityType !=='customer' ? entityType==='corporate' ? "corporate": '' : "Partner"} address (as mentioned in the Aadhaar Card)?`, "textarea",true,{
                          required: "Address is required"
                        })}
                    </div>
                </form>
            );
        case 'PAN':
            return (
                <form className="max-w-md py-8 sm:p-2 pl-9 ">
                    <div className="flex flex-col sm:pl-0 pl-10 gap-10 w-full">
                        {renderField("Enter PAN number","PAN", "What is the PAN number?", "",false,{
                          required: "PAN is required",
                          pattern: {
                            value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                            message: "PAN must be 10 characters: 5 letters, 4 numbers, 1 letter"
                          }                          
                        },false, (value) => value.toUpperCase().slice(0, 10))}
                        {renderField("Enter name on PAN card","name", `What is ${entityType !=='customer' ? entityType==='corporate' ? "corporate": '' : "Partner"} name on PAN card?`, "email",false,{
                          required: "Name is required"
                        })}
                        {renderField("Enter date of birth","date_of_birth", `What is the ${entityType !=='customer' ? entityType==='corporate' ? "company establishment date" : " Customer date of birth" :" Partner date of birth or date of incorporation"}?`, "date", true,{
                          required: "Date of Birth is required"
                        })}
                    </div>
                </form>
            );
        default:
            return null;
    }
}
