import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  DetailedHTMLProps,
  Dispatch,
  FC,
  HTMLAttributes,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Img, Input, Radio, SelectBox, Text } from "components";
import { selectOptionType } from "components/SelectBox";
import { getCity, getState } from "service/apis/PartnerRegistrationapi";

type FooterProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{
    setCurrentStep: Dispatch<SetStateAction<number>>;
    disableButton: boolean;
  }>;

import { useAppSelector, useAppDispatch } from "redux/hooks";
import {
  setAddressLine1,
  setAddressLine2,
  setCity,
  setState,
  setPincode,
  setaddress_proof,
  setPropertyType,
  setGst,
  setGeolocation,
  setPhoto1,
  setOffice_Interior1,
  setphotosBinary,
  setCitylabel,
  setaddressBinary,
  setStateLabel,
} from "../../redux/features/PartnerRegistration";
import { RadioInput } from "components/Input/Inputs";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { setAddOnRates } from "redux/features/CreateDeal";
import { setAddressProof } from "redux/features/BranchRegistration";
import { result } from "lodash";

function convertFileToDataUrl(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

const Step4: FC<FooterProps> = ({ disableButton }) => {
  const [Locationswitch, setLocationSwitch] = useState<boolean>(false);
  const [files, setFiles] = useState<{ [key: string]: File }>({});
  const [havePremiseImages, setHavePremiseImages] = useState<boolean>(false);
  const [geoMessage, setgeoMessage] = useState<string>(
    "Capturing Geolocation.....",
  );
  const [stateoptions, setstateoptions] = useState<selectOptionType[]>([]);
  const [cityoptions, setcityoptions] = useState<selectOptionType[]>([]);

  const dispatch = useAppDispatch();

  const address_line_1 = useAppSelector(
    (state) => state.PartnerRegistration.address_line_1,
  );
  const address_line_2 = useAppSelector(
    (state) => state.PartnerRegistration.address_line_2,
  );
  const city = useAppSelector((state) => state.PartnerRegistration.city);
  const state = useAppSelector((state) => state.PartnerRegistration.state);
  const pincode = useAppSelector((state) => state.PartnerRegistration.pincode);
  const gst = useAppSelector((state) => state.PartnerRegistration.gst);
  const address_proof = useAppSelector(
    (state) => state.PartnerRegistration.address_proof,
  );
  const PropertyType = useAppSelector(
    (state) => state.PartnerRegistration.PropertyType,
  );
  const office_front = useAppSelector(
    (state) => state.PartnerRegistration.photo_1,
  );
  const Office_Interior1 = useAppSelector(
    (state) => state.PartnerRegistration.Office_Interior1,
  );
  const photosBinary = useAppSelector(
    (state) => state.PartnerRegistration.photosBinary,
  );
  const Citylabel = useAppSelector(
    (state) => state.PartnerRegistration.citylabel,
  );
  useEffect(() => {
    if (address_proof instanceof File) {
      convertFileToDataUrl(address_proof).then((res) => {
        const progressEvent = res as ProgressEvent<FileReader>;
        if (progressEvent.target?.result?.toString()) {
          dispatch(setaddressBinary(progressEvent.target?.result?.toString()));
        }
      });
    }
    if (office_front instanceof File) {
      convertFileToDataUrl(office_front).then((res) => {
        const progressEvent = res as ProgressEvent<FileReader>;
        if (progressEvent.target?.result?.toString()) {
          dispatch(
            setphotosBinary({
              ...photosBinary,
              photo_1: progressEvent.target?.result?.toString(),
            }),
          );
        }
        // updatePhotosBinary("photo_1", progressEvent.target?.result?.toString());
      });
    }
    if (Office_Interior1) {
      if (Office_Interior1?.length > 0) {
        convertFileToDataUrl(Office_Interior1[0]).then((res) => {
          const progressEvent = res as ProgressEvent<FileReader>;
          if (progressEvent.target?.result?.toString()) {
            dispatch(
              setphotosBinary({
                ...photosBinary,
                photo_2: progressEvent.target?.result?.toString(),
              }),
            );
          }
        });
      }
      if (Office_Interior1?.length > 1) {
        convertFileToDataUrl(Office_Interior1[1]).then((res) => {
          const progressEvent = res as ProgressEvent<FileReader>;
          if (progressEvent.target?.result?.toString()) {
            dispatch(
              setphotosBinary({
                ...photosBinary,
                photo_3: progressEvent.target?.result?.toString(),
              }),
            );
          }
        });
      }

      if (Office_Interior1?.length > 2) {
        convertFileToDataUrl(Office_Interior1[2]).then((res) => {
          const progressEvent = res as ProgressEvent<FileReader>;
          if (progressEvent.target?.result?.toString()) {
            dispatch(
              setphotosBinary({
                ...photosBinary,
                photo_4: progressEvent.target?.result?.toString(),
              }),
            );
          }
        });
      }
    }
  }, [office_front, Office_Interior1, address_proof]);
  useEffect(() => {
    if (Locationswitch) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            dispatch(
              setGeolocation(
                `Latitude : ${position.coords.latitude} Longitude: ${position.coords.longitude}`
              )
            );
            setgeoMessage("GeoLocation Captured");
          },
          (error) => {
            // Handle geolocation errors
            console.error("Geolocation error:", error);
            setgeoMessage(`Geolocation error: ${error.message}`);
          },
          {
            // Optional configuration
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          }
        );
      } else {
        setgeoMessage("Geolocation is not available in this browser.");
      }
    }
  }, [Locationswitch, dispatch]);
  useEffect(() => {
    const cityreq = cityoptions.find((obj) => obj.value === city);
    if (cityreq) {
      dispatch(setCitylabel(cityreq.label.toString()));
    }
  }, [city]);
  useEffect(() => {
    const statereq = stateoptions.find((obj) => obj.value === state);
    if (statereq) {
      dispatch(setStateLabel(statereq.label.toString()));
      console.log(statereq.label.toString());
    }
  }, [state]);
  useEffect(() => {
    fetchState();
  }, []);
  useEffect(() => {
    if (state) {
      fetchCity();
    }
  }, [state]);

  function fetchState() {
    getState({})
      .then((res) => {
        const state = res.data as Array<{ id: number; name: string }>;
        setstateoptions(
          state.map((obj: any) => {
            return { label: obj.name, value: obj.id };
          }),
        );
      })
      .catch(() => {});
  }

  function fetchCity() {
    const req = {
      params: {
        state_id: state,
      },
    };

    getCity(req)
      .then((res) => {
        const city = res.data as Array<{ id: number; name: string }>;
        setcityoptions(
          city.map((obj: any) => {
            return { label: obj.name, value: obj.id };
          }),
        );
      })
      .catch();
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => {
    const file = event.target.files?.[0];
    const filesArr = event?.target?.files ?? [];

    if (file && name) {
      setFiles((prev) => ({ ...prev, [name]: file })); // Update the file name state
      const reader = new FileReader();
      if (name === "office_Interior1") {
        for (const [i, file] of Object.entries(filesArr)) {
          reader.readAsDataURL(file);
          reader.onload = () => {
            dispatch(
              setphotosBinary({
                ...photosBinary,
                [`photo_${i + 2}`]: reader.result as string,
              }),
            );
          };
        }
      }
      reader.onload = () => {
        if (name === "address_proof") {
          dispatch(setaddressBinary(reader.result as string));
        } else if (name === "office_front") {
          dispatch(
            setphotosBinary({
              ...photosBinary,
              photo_1: reader.result as string,
            }),
          );
        }
      };
      reader.readAsDataURL(file);
      console.log(photosBinary);
    }
  };
  
  return (
    <div className="flex">
      <div className="flex md:flex-1 flex-col gap-5 items-start justify-start overflow-y-scroll md:px-5 w-[53%] md:w-full">
        <div className="flex flex-col gap-[15px] items-start justify-start w-auto sm:w-full">
          <div className="flex flex-col gap-[15px] md:h-auto items-start justify-start w-full sm:w-full">
            <div className="flex pl-10 relative items-center justify-start w-auto">
              <div
                className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-14 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src={`images/overview/ellipse.svg`}
                  alt=""
                />
              </div>
              {/* <Switch.Root
                className="w-[45px] h-[25px] bg-blue_gray-lite-bg rounded-full  border-2 border-blue-700 outline-none cursor-default"
                id="airplane-mode"
                style={{ WebkitTapHighlightColor: "rgba(0, 0, 0, 0)" }}
                checked={Locationswitch}
                onCheckedChange={() => setLocationSwitch(!Locationswitch)}
              >
                <Switch.Thumb
                  className={`flex p-1 w-[20px] h-[20px] bg-blue-700_01 rounded-full shadow-black-900 transition-transform duration-100 ${Locationswitch && "translate-x-5"} will-change-transform`}
                />
              </Switch.Root> */}
              <div className="flex relative flex-col gap-5">
                <Text className="text-sm font-medium leading-[17px] ">
                  Are you at your location ?
                </Text>
                <RadioGroup
                  className="flex"
                  onValueChange={() => setLocationSwitch(!Locationswitch)}
                  defaultValue="not_at_location"
                >
                  <div
                    style={{ borderColor: Locationswitch ? "#165FE3" : "none" }}
                    className="flex items-center rounded-lg border p-3 space-x-2"
                  >
                    <RadioGroupItem value="at_location" id="at_location" />
                    <label htmlFor="at_location">
                      Yes, I am at my location
                    </label>
                  </div>
                  <div className="flex items-center rounded-lg border p-3 space-x-2">
                    <RadioGroupItem value="not_at_location" id="r2" />
                    <label htmlFor="r2">No, I am not at my location</label>
                  </div>
                </RadioGroup>
                <div className="flex flex-col gap-2">
                  <div
                    className={`flex items-center flex-col after:right-10 after:h-0 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  <h5
                    className={`${!Locationswitch ? "text-blue_gray-300_01" : ""} text-sm font-medium leading-[17px]`}
                  >
                    Capture the Geological Location
                  </h5>
                  {Locationswitch && (
                    <>
                      <div className="bg-[#F3F8FF] py-2.5 px-3.5 gap-3 rounded-md flex items-center">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_1449_73400)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 2C14.3869 2 16.6761 2.94821 18.364 4.63604C20.0518 6.32387 21 8.61305 21 11C21 14.074 19.324 16.59 17.558 18.395C16.6757 19.2871 15.7129 20.0958 14.682 20.811L14.256 21.101L14.056 21.234L13.679 21.474L13.343 21.679L12.927 21.921C12.6446 22.0822 12.3251 22.1669 12 22.1669C11.6749 22.1669 11.3554 22.0822 11.073 21.921L10.657 21.679L10.137 21.359L9.945 21.234L9.535 20.961C8.42283 20.2085 7.3869 19.3491 6.442 18.395C4.676 16.589 3 14.074 3 11C3 8.61305 3.94821 6.32387 5.63604 4.63604C7.32387 2.94821 9.61305 2 12 2ZM12 4C10.1435 4 8.36301 4.7375 7.05025 6.05025C5.7375 7.36301 5 9.14348 5 11C5 13.322 6.272 15.36 7.871 16.996C8.55853 17.692 9.30166 18.3308 10.093 18.906L10.551 19.232C10.699 19.3353 10.8413 19.4313 10.978 19.52L11.368 19.77L11.711 19.979L12 20.148L12.455 19.879L12.822 19.649C13.0173 19.525 13.2263 19.386 13.449 19.232L13.907 18.906C14.6983 18.3308 15.4415 17.692 16.129 16.996C17.728 15.361 19 13.322 19 11C19 9.14348 18.2625 7.36301 16.9497 6.05025C15.637 4.7375 13.8565 4 12 4ZM12 7C13.0609 7 14.0783 7.42143 14.8284 8.17157C15.5786 8.92172 16 9.93913 16 11C16 12.0609 15.5786 13.0783 14.8284 13.8284C14.0783 14.5786 13.0609 15 12 15C10.9391 15 9.92172 14.5786 9.17157 13.8284C8.42143 13.0783 8 12.0609 8 11C8 9.93913 8.42143 8.92172 9.17157 8.17157C9.92172 7.42143 10.9391 7 12 7ZM12 9C11.4696 9 10.9609 9.21071 10.5858 9.58579C10.2107 9.96086 10 10.4696 10 11C10 11.5304 10.2107 12.0391 10.5858 12.4142C10.9609 12.7893 11.4696 13 12 13C12.5304 13 13.0391 12.7893 13.4142 12.4142C13.7893 12.0391 14 11.5304 14 11C14 10.4696 13.7893 9.96086 13.4142 9.58579C13.0391 9.21071 12.5304 9 12 9Z"
                              fill="#999999"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1449_73400">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <small className=" text-[#999999] text-sm">
                          {geoMessage !== "Capturing Geolocation....."
                            ? "Wait a second , Capturing..."
                            : geoMessage}
                        </small>
                      </div>
                      <Text className="text-xs font-normal">
                        Click &quot;Allow&quot; on your browser to record your
                        current location.
                      </Text>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="item-1">
              <AccordionTrigger className="">
                <div className="flex justify-between">
                  <div className="flex gap-2 items-center">
                    <div className="">
                      <Img src="/images/remit/beni.svg" alt="" />
                    </div>
                    <div className="">Corporate Office Address</div>
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent className="flex pl-10 flex-col gap-5">
                <div className="flex flex-col gap-2.5 relative items-start justify-start w-[377px] sm:w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-14 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  <Text className="text-sm font-medium leading-[17px]">
                    Address line 1
                  </Text>
                  <Input
                    name="price"
                    value={address_line_1}
                    onChange={(e: string) => dispatch(setAddressLine1(e))}
                    placeholder="Please enter Address Line 1"
                    className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                    wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                  ></Input>
                </div>
                <div className="flex flex-col gap-2.5 relative items-start justify-start w-[377px] sm:w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-14 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  <Text className="text-sm font-medium leading-[17px]">
                    Address line 2
                  </Text>
                  <Input
                    name="price_One"
                    value={address_line_2}
                    onChange={(e: string) => dispatch(setAddressLine2(e))}
                    placeholder="Please enter Address Line 2"
                    className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                    wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                  ></Input>
                </div>
                <div className="flex flex-col gap-2.5 relative items-start justify-start w-[377px] sm:w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-14 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  <Text className="text-sm font-medium leading-[17px]">
                    State
                  </Text>
                  <SelectBox
                    className="bg-white-A700 border border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                    placeholderClassName="text-black-900_60"
                    value={state}
                    onChange={(e) => dispatch(setState(e))}
                    indicator={
                      <Img
                        className="h-4 w-4"
                        src="images/img_arrowdown.svg"
                        alt="arrow_down"
                      />
                    }
                    isMulti={false}
                    name="inputboxone"
                    options={stateoptions}
                    isSearchable
                    placeholder="Select State"
                  />
                </div>
                <div className="flex flex-col gap-2.5 relative items-start justify-start w-[377px] sm:w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-14 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  <Text className="text-sm font-medium leading-[17px]">
                    City
                  </Text>
                  <SelectBox
                    className="bg-white-A700 border border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                    placeholderClassName="text-black-900_60"
                    value={city}
                    onChange={(e) => dispatch(setCity(e))}
                    indicator={
                      <Img
                        className="h-4 w-4"
                        src="images/img_arrowdown.svg"
                        alt="arrow_down"
                      />
                    }
                    isMulti={false}
                    name="inputbox"
                    options={cityoptions}
                    isSearchable
                    placeholder="Select City"
                  />
                </div>

                <div className="flex flex-col gap-2.5 relative items-start justify-start w-[377px] sm:w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-14 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  <Text className="text-sm font-medium leading-[17px]">
                    Pin code
                  </Text>

                  <Input
                    name="zipcode"
                    type="number"
                    value={pincode}
                    errors={
                      String(pincode).length > 0 && String(pincode).length < 6
                        ? ["Pincode should be of 6 digits"]
                        : []
                    }
                    onChange={(e: string) => {
                      if (e.length <= 6) {
                        dispatch(setPincode(e));
                      }
                    }}
                    placeholder="Enter Pin Code"
                    className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                    wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                  ></Input>
                </div>
                <div className="flex flex-col gap-2.5 relative items-start justify-start w-[377px] sm:w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-14 " : "after:h-0 "} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  <Text className="text-sm font-medium leading-[17px]">
                    GST (Optional)
                  </Text>
                  <Input
                    value={gst}
                    onChange={(e: string) => dispatch(setGst(e))}
                    name="zipcode"
                    placeholder="Enter GST Number"
                    className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                    wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                  ></Input>
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3">
              <AccordionTrigger>
                <div className="flex justify-between">
                  <div className="flex gap-2 items-center">
                    <div className="">
                      <Img src="/images/remit/beni.svg" alt="" />
                    </div>
                    <div className="">Other details</div>
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent className="flex flex-col gap-5 pl-10">
                <div className="flex flex-col gap-2.5 relative items-start justify-start w-full sm:w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-14 " : "after:h-24 after:border-blue-700 "} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  <Text className="text-sm font-medium leading-[17px]">
                    Type of property
                  </Text>
                  <RadioGroup
                    className="flex"
                    onValueChange={(e) => dispatch(setPropertyType(e))}
                    defaultValue="Owned"
                  >
                    <label htmlFor="Owned"
                      className={`${PropertyType === 'Owned' ? " focus-within:border-blue-700 border-black-label" : ""} flex w-auto items-center rounded-lg border p-3 space-x-2`}                      
                    >
                      <RadioGroupItem
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                        value="Owned"
                        id="Owned"
                      />
                      <p>Owned property</p>
                    </label>
                    <label 
                      className={`${PropertyType === 'Rented' ? " focus-within:border-blue-700 border-black-label" : ""} flex w-auto items-center rounded-lg border p-3 space-x-2`}
                      htmlFor="Rented" 
                      >
                      <RadioGroupItem 
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                        value="Rented" 
                        id="Rented" 
                      />
                      <p>Rented/Leased Property</p>
                    </label>
                  </RadioGroup>
                </div>
                <div className="flex flex-col gap-2.5 relative items-start justify-start w-full sm:w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${!Locationswitch ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  <Text className="text-sm font-medium leading-[17px]">
                    {PropertyType === "Rented"
                      ? "Upload Rent Agreement"
                      : "Upload Address Proof"}
                  </Text>
                </div>
                <div
                  className={`py-1.5 px-3.5 justify-between rounded-md w-full border flex ${files["address_proof"]?.name && "bg-[#F2F7FF]"}`}
                >
                  <div className="flex gap-3 items-center overflow-hidden">
                    <Img src="/images/remit/file.svg" alt="" />
                    <p className="truncate">
                      {files["address_proof"]?.name
                        ? files["address_proof"]?.name
                        : "No file choosen"}
                    </p>
                  </div>
                  <label
                    htmlFor="address_proof"
                    className="text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                  >
                    {files["address_proof"]?.name ? "Re-upload" : "Upload"}
                  </label>
                  <input
                    name="address_proof"
                    type="file"
                    id="address_proof"
                    className="hidden"
                    onChange={(event) =>
                      handleFileChange(event, "address_proof")
                    }
                    accept="image/*"
                  />
                </div>
                {Locationswitch && (
                  <div className="flex flex-col gap-5 relative items-start justify-start w-full sm:w-full">
                    <div
                      className={`flex items-center top-0 flex-col after:right-10  ${!havePremiseImages ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10 "
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      Do you have premises Images
                    </Text>
                    <RadioGroup
                      className="flex"
                      onValueChange={(e) => setHavePremiseImages(e === "Yes")}
                      defaultValue="No"
                    >
                      <div
                        style={{
                          borderColor: havePremiseImages ? "#165FE3" : "none",
                        }}
                        className="flex items-center rounded-lg border p-3 space-x-2"
                      >
                        <RadioGroupItem
                          className=" active:text-blue-700"
                          value="Yes"
                          id="Yes"
                        />
                        <label htmlFor="Yes">Yes</label>
                      </div>
                      <div
                        style={{
                          borderColor: !havePremiseImages ? "#165FE3" : "none",
                        }}
                        className="flex items-center rounded-lg border p-3 space-x-2"
                      >
                        <RadioGroupItem value="No" id="No" />
                        <label htmlFor="No">No</label>
                      </div>
                    </RadioGroup>
                    {havePremiseImages && (
                      <>
                        <div className="flex flex-col gap-2.5 relative items-start justify-start w-full sm:w-full">
                          <div
                            className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-14 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                          >
                            <img
                              className="relative -left-10 "
                              src={`images/overview/ellipse.svg`}
                              alt=""
                            />
                          </div>
                          <Text className="text-sm font-medium leading-[17px]">
                            Upload image of office front
                          </Text>
                          <div
                            className={`py-1.5 px-3.5 justify-between rounded-md w-full border flex ${files["office_front"]?.name && "bg-[#F2F7FF]"}`}
                          >
                            <div className="flex gap-3 items-center overflow-hidden">
                              <Img src="/images/remit/file.svg" alt="" />
                              <p className="truncate">
                                {files["office_front"]?.name
                                  ? files["office_front"]?.name
                                  : "No file choosen"}
                              </p>
                            </div>
                            <label
                              htmlFor="office_front"
                              className="text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                            >
                              {files["office_front"]?.name
                                ? "Re-upload"
                                : "Upload"}
                            </label>
                            <input
                              name="office_front"
                              type="file"
                              id="office_front"
                              className="hidden"
                              onChange={(event) =>
                                handleFileChange(event, "office_front")
                              }
                              accept="image/*"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col gap-2.5 relative items-start justify-start w-full sm:w-full">
                          <div
                            className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-0"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                          >
                            <img
                              className="relative -left-10 "
                              src={`images/overview/ellipse.svg`}
                              alt=""
                            />
                          </div>
                          <Text className="text-sm font-medium leading-[17px]">
                            Upload three image&apos;s of office interior
                          </Text>
                          <div
                            className={`py-1.5 px-3.5 justify-between rounded-md w-full border flex ${files["Office_Interior1"]?.name && "bg-[#F2F7FF]"}`}
                          >
                            <div className="flex gap-3 items-center overflow-hidden">
                              <Img src="/images/remit/file.svg" alt="" />
                              <p className="truncate">
                                {files["Office_Interior1"]?.name
                                  ? files["Office_Interior1"]?.name
                                  : "No file choosen"}
                              </p>
                            </div>
                            <label
                              htmlFor="Office_Interior1"
                              className="text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                            >
                              {files["Office_Interior1"]?.name
                                ? "Re-upload"
                                : "Upload"}
                            </label>
                            <input
                              name="Office_Interior1"
                              type="file"
                              multiple
                              id="Office_Interior1"
                              className="hidden"
                              onChange={(event) =>
                                handleFileChange(event, "Office_Interior1")
                              }
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Step4;
