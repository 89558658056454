import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

export default function PhotoUpload() {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const defaultValues = getValues();

  const handleFileChange = (file: File, onChange: (value: any) => void) => {
    if (!file.type.startsWith("image/")) {
      alert("Please upload only image files");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result as string);
      onChange(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const renderField = (
    name: string,
    label: string,
    isLast: boolean = false,
    defaultValue?: any,
    isOptional: boolean = true,
  ) => (
    <div className="flex flex-col items-center w-full">
      <label
        className="font-inter font-medium text-[#333333] w-full text-center"
        style={{
          paddingTop: "32px",
          fontWeight: "500px",
          fontSize: "18px",
          paddingBottom: "32px",
        }}
      >
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          ...(isOptional ? {} : { required: "This field is required" }),
        }}
        render={({ field: { onChange, value } }) => (
          <div className="w-full flex flex-col items-center">
            <div className="bg-gray-50 border-gray-300 w-full max-w-[400px] flex justify-center photo-upload-container">
              <div className="w-full max-h-[300px] sm:mx-2 flex items-center justify-center overflow-hidden rounded-lg">
                <img
                  className="max-w-full max-h-full w-full object-contain object-center"
                  src={
                    previewUrl ||
                    (value &&
                    typeof value === "string" &&
                    value !== "data:application/octet-stream;base64,"
                      ? value
                      : "/images/Selfie.png")
                  }
                  alt="Office photo preview"
                />
              </div>
            </div>

            <div
              className="flex flex-col items-center gap-2 photo-upload-actions"
              style={{ paddingTop: "32px" }}
            >
              <label
                htmlFor="photo_upload_input"
                className="px-4 py-2 bg-[#FFFFFF] text-[#165FE3] border border-[#165FE3] border-lg  rounded-lg cursor-pointer transition-all duration-300 ease-out text-center"
                style={{ fontWeight: "600", fontSize: "14px" }}
              >
                {previewUrl ||
                (value &&
                  typeof value === "string" &&
                  value !== "data:application/octet-stream;base64,")
                  ? "Re-upload"
                  : "Upload Photo"}
              </label>

              <input
                id="photo_upload_input"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={(e) => {
                  if (e.target.files?.[0]) {
                    handleFileChange(e.target.files[0], onChange);
                  }
                }}
              />
            </div>
            {errors[name] && (
              <span className="text-red-500 text-sm mt-2 text-center">
                {errors[name].message as string}
              </span>
            )}
          </div>
        )}
      />
    </div>
  );

  return (
    <form className="flex flex-col items-center max-h-[80vh] overflow-auto">
      <div className="flex flex-col w-full items-center">
        {renderField(
          "office_photo",
          "Click/upload a photo covering yourself and partner's office.",
          true,
          defaultValues?.office_photo,
        )}
      </div>
    </form>
  );
}
