import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { AxiosRequestConfig } from "axios";
import { Text } from "components";
import DashboardNavbar from "components/DashboardNavbar";
import Task from "components/Task/Task";
import { Search } from "lucide-react";
import { useDebounce } from "pages/CreateDeal/newdeal";
import {
  getRelativeDate,
  isValidIndianMobile,
} from "pages/Opportunities/utils/featureRule";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactSelect, {
  components,
  MenuProps,
  OptionProps,
  SingleValue,
  StylesConfig,
} from "react-select";
import {
  setContactPersonName,
  setContactPersonPhone,
  setPipelineType,
} from "redux/features/CreateOpportunity";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  getDashboardStats,
  GetDashboardStatsRequestType,
} from "service/apis/Dashboard";
import {
  searchContact,
  searchContact1,
  SearchContactReqType,
} from "service/apis/Opportunities";
import { Toaster } from "sonner";
import { formatIndianNumber } from "../../utils";
import {
  buttonData,
  ContactData,
  placeholderData,
  tabData,
} from "./dashboard-data";
import { RowDetailsSheet } from "pages/Opportunities/opp";

const CustomMenu: React.FC<MenuProps<ContactData, false>> = ({
  children,
  ...props
}) => {
  const navigate = useNavigate();
  const { pipelineType } = useAppSelector((state) => state.CreateOpportunity);

  const handleAddContact = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent default behavior
    e.stopPropagation(); // Stop event bubbling
    navigate(buttonData[pipelineType].href);
  };
  return (
    <components.Menu {...props}>
      <div className="bg-[#F9F9F9] h-[33px] p-2 w-full justify-end flex gap-2">
        <div className="flex items-center gap-1">
          <p className="bg-[#E1EBFC] p-1 rounded font-normal text-xs leading-[16.8px]">
            P
          </p>
          <p className="font-normal text-xs leading-[16.8px] text-[#999999]">
            Partner
          </p>
        </div>
        <div className="flex items-center gap-1">
          <p className="bg-[#E1EBFC] p-1 rounded font-normal text-xs leading-[16.8px]">
            C
          </p>
          <p className="font-normal text-xs leading-[16.8px] text-[#999999]">
            Corporate
          </p>
        </div>
      </div>
      {children}
      <div>
        <button
          className="bg-blue-700 w-full absolute text-white-A700 p-2 rounded-b-xl"
          onClick={handleAddContact}
          onTouchEnd={(e) => {
            e.preventDefault(); // Prevent default touch behavior
            handleAddContact(e as unknown as React.MouseEvent);
          }}
        >
          {buttonData[pipelineType].label}
        </button>
      </div>
    </components.Menu>
  );
};

const CustomOption: React.FC<OptionProps<ContactData, false>> = ({
  innerProps,
  label,
  data,
  isFocused,
}) => (
  <div
    {...innerProps}
    style={{
      padding: "8px 12px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
      backgroundColor: isFocused ? "#F3F7FF" : "inherit",
      color: data.status === "Blocked" ? "red" : "inherit",
    }}
  >
    <table style={{ width: "100%", borderCollapse: "collapse" }}>
      <tbody>
        <tr>
          <td style={{ width: "40%" }}>
            <div className=" first-letter:uppercase">
              {data.partnerType && !data?.companyName && (
                <span className="bg-[#E1EBFC] p-1 mr-2 rounded font-normal text-xs leading-[16.8px]">
                  P
                </span>
              )}
              {data.companyName && (
                <span className="bg-[#E1EBFC] p-1 mr-2 rounded font-normal text-xs leading-[16.8px]">
                  C
                </span>
              )}
              {data.partnerType && !data?.companyName
                ? data?.name
                : data?.companyName
                  ? data?.companyName
                  : data?.contactName}
            </div>
          </td>
          <td style={{ width: "27%", textAlign: "left" }}>
            <div>{}</div>
          </td>
          {/* <td style={{ width: '25%' }} className="">
            <div style={{ fontSize: '0.8em', color: '#666' }}>{data.location ? data?.location : "N/A"}</div>
          </td> */}
          <td style={{ width: "33%", textAlign: "right" }}>
            <div
              style={{
                display: "inline-block",
                padding: "2px 6px",
                borderRadius: "12px",
                fontSize: "0.8em",
                color: "black",
              }}
            >
              {data.partnerType
                ? data?.partnerType.split("_").join(" ")
                : data?.companyName
                  ? data?.type
                  : data?.contactType}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

function SearchAndTab() {
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<ContactData | null>(
    null,
  );
  const [options, setOptions] = useState<Partial<ContactData[]>>([]);
  const [companyDetails, setCompanyDetails] = useState<any>([]);

  const { pipelineType, contact_person_phone } = useAppSelector(
    (state) => state.CreateOpportunity,
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onTabChange = (value: string) => {
    dispatch(setPipelineType(value as "retail" | "corporate" | "partner"));
  };

  const handleChange = (newValue: SingleValue<ContactData>) => {
    setSelectedOption(() => newValue);
    if (!newValue) return;
    if (newValue?.companyName && newValue?.role === "lead") {
      navigate(`/leads/corporate/${newValue?.value}`);
      return;
    }
    if (newValue?.partnerType && !newValue?.companyName) {
      if (newValue?.role === "lead") {
        navigate(`/leads/partner/${newValue?.value}`);
      } else navigate(`/partnerdetails/${newValue?.value}`);
    } else if (!newValue?.companyName)
      navigate(`/leads/contact/${newValue?.value}`);
  };

  const fetchSearchResults = async () => {
    if (!inputValue) {
      return;
    }
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<SearchContactReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        searchQuery: inputValue,
      },
    };
    try {
      if (inputValue === "") return;
      const response = (await searchContact1(payload)) as any;
      if (response?.data?.length === 0) {
        setOptions(() => []);
        return;
      }
      const newOptions: ContactData[] = response?.data?.combined_record?.map(
        (item: any) => ({
          value: item?.id,
          label: item?.contactName,
          contactName: item?.contactName,
          contactPhone: item?.contactNumber,
          email: item?.contactEmail,
          role: item?.status,
          location: item?.contactAddress?.[0],
          status: item?.status,
          type: item?.contactPersonType,
          partnerType: item?.type,
          contactType: item?.contactPersonType,
          name: item?.name,
          phone: item?.phone,
          companyName: item?.company_name,
          lead_status: item?.lead_status,
        }),
      );
      setOptions(newOptions);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useDebounce(() => fetchSearchResults(), 500, [inputValue]);

  const customStyles: StylesConfig<ContactData> = {
    control: (provided, state) => ({
      ...provided,
      zIndex: 0,
      borderRadius: "1.75rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: "40px",
      borderColor: state.isFocused ? "#165FE3" : "#d2d2d2",
      boxShadow: "none",
      width: "100%",
    }),
    menu: (provided) => ({
      ...provided,
      padding: 0,
      maxHeight: "300px",
      display: inputValue ? "block" : "none",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      width: "100%",
      maxHeight: "240px",
    }),
    option: (provided) => ({
      ...provided,
      width: "100%",
      display: "flex",
      borderRadius: "12px",
      justifyContent: "space-between",
      background: "#000000",
    }),
    placeholder: (provided) => ({
      ...provided,
      "@media (max-width: 768px)": {
        fontSize: "16px",
      },
      "@media (max-width: 480px)": {
        fontSize: "13px",
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  return (
    <div className="py-10 px-5 flex items-center flex-col gap-8 rounded-xl bg-white-A700">
      {/* <Tabs
        className="bg-navbar-menu-bg w-fit flex rounded-lg gap-2"
        value={pipelineType}
        onValueChange={onTabChange}
      >
        <TabsList className="h-[44px]">
          {tabData
            .filter((tab) => true)
            .map((tab, index: number) => (
              <TabsTrigger
                key={index}
                value={tab.value}
                disabled={!tab.enabled}
                className={`py-3 px-5 text-sm h-[44px] sm:min-w-[100px] min-w-[120px] font-semibold ${tab.value === pipelineType ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg" : " text-black-label"}`}
              >
                {tab.label}
              </TabsTrigger>
            ))}
        </TabsList>
      </Tabs> */}
      <div className="flex gap-2 items-center w-full sm:max-w-xl max-w-2xl">
        <div className=" relative flex items-center w-full">
          <ReactSelect<ContactData>
            placeholder={"Search by name or mobile number"}
            styles={customStyles}
            components={{ Option: CustomOption, Menu: CustomMenu }}
            isClearable
            isSearchable
            name="company"
            onChange={handleChange}
            options={options as ContactData[]}
            onInputChange={(value) => {
              if (value.length === 0) {
                dispatch(setContactPersonPhone(""));
                dispatch(setContactPersonName(""));
                setOptions(() => []);
                console.log(options);
              }
              setInputValue(value);
              if (isValidIndianMobile(value))
                dispatch(setContactPersonPhone(value));
              else if (!value.match(/^\d+$/))
                dispatch(setContactPersonName(value));
            }}
            className="w-full"
            filterOption={null}
          />
          <Search className="absolute left-2 h-3.5" />
        </div>
      </div>
    </div>
  );
}

const DashboardPage: React.FC = () => {
  // skipcq: JS-0323, JS-0323
  const [state, setState] = useState<any>();
  const [, setLoading] = useState<boolean>(false);
  const [showStats, setShowStats] = useState<boolean>(false);
  const [tabSwitch, setTabSwitch] = useState<boolean>(false);
  const [showScrollIndicator, setShowScrollIndicator] = useState(true);
  const [loadImg, setLoadImg] = useState(true);
  const [isRowDetailsOpen, setIsRowDetailsOpen] = useState(false);

  const id = new URLSearchParams(window.location.search).get("id");
  const product = new URLSearchParams(window.location.search).get("product");
  const create_date = new URLSearchParams(window.location.search).get(
    "create_date",
  );

  const scrollRef = useRef<HTMLDivElement>(null);

  const handleScrollDown = () => {
    const scrollContainer = document.querySelector(".scroll-container");
    if (scrollContainer) {
      const scrollAmount = 500; // Adjust this value to control how far it scrolls
      scrollContainer.scrollBy({
        top: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    setShowScrollIndicator(false);
  };

  useEffect(() => {
    const scrollDiv = scrollRef.current;
    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const name = localStorage.getItem("name");

  //style for switch tab between stats and tasks
  const tabSwitchActiveStyle =
    "bg-[#165FE3] text-white-A700 rounded-lg py-3 px-4 gap-2.5";

  const getDashboardStatsData = () => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetDashboardStatsRequestType> = {
      headers: { Authorization: authToken },
    };

    getDashboardStats(payload)
      .then((res) => {
        setState(res.data);
        console.log(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const greeting = () => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) {
      return {
        greet: "Good Morning",
        image: "images/overview/day.png",
        color: "#5597B2",
      };
    } else if (hour >= 12 && hour < 18) {
      return {
        greet: "Good Afternoon",
        image: "images/overview/evening.png",
        color: "#FFA52D",
      };
    } else {
      return {
        greet: "Good Evening",
        image: "images/overview/night.png",
        color: "#FFA52D",
      };
    }
  };

  useEffect(() => {
    getDashboardStatsData();
  }, []);

  const recentTaskData = [
    {
      deal: "Deal 101",
      status: "Created Successfully",
      tag: "Remit",
      time: "20min ago",
    },
    {
      deal: "Deal 101",
      status: "Created Successfully",
      tag: "Remit",
      time: "20min ago",
    },
    {
      deal: "Deal 101",
      status: "Created Successfully",
      tag: "Remit",
      time: "20min ago",
    },
    {
      deal: "Deal 101",
      status: "Created Successfully",
      tag: "Remit",
      time: "20min ago",
    },
    {
      deal: "Deal 101",
      status: "Created Successfully",
      tag: "Remit",
      time: "20min ago",
    },
  ];

  const navLinks = {
    current: {
      label: "All Overview",
      icon: "",
    },
    left: [
      {
        label: "Remit",
        href: "/remit",
        icon: "images/overview/remit.svg",
        logo: "images/overview/remit-text.svg",
        enabled: true,
      },
      {
        label: "Forex",
        href: "/overview",
        icon: "images/overview/forex.svg",
        logo: "images/overview/forex-text.svg",
        enabled: false,
      },
      // {
      //   label: "Study Abroad",
      //   href: "/overview",
      //   icon: "images/overview/study.svg",
      //   logo: "images/overview/study-text.svg",
      //   enabled: false,
      // },
      {
        label: "Corporate",
        href: "/overview",
        icon: "images/overview/corporate.svg",
        logo: "images/overview/corporate-text.svg",
        enabled: false,
      },
    ],
    right: [
      {
        label: "Notification",
        visible: false,
        href: "#",
        icon: "images/overview/bell.svg",
        submenu: [],
        enabled: true,
      },
      // {
      //   label: "Settings",
      //   visible: false,
      //   href: "#",
      //   icon: "images/overview/settings.svg",
      //   submenu: [],
      //   enabled: true,
      // },
      {
        label: "Profile",
        visible: false,
        href: "#",
        icon: "images/overview/profile.svg",
        submenu: [
          {
            label: "My Profile",
            href: "/profile",
            icon: "images/overview/myprofile.svg",
          },
          {
            label: "Logout",
            href: "/logout",
            icon: "images/overview/logout.svg",
          },
        ],
        enabled: true,
      },
      // {
      //   label: "Help",
      //   visible: true,
      //   href: "#",
      //   icon: "images/overview/wrench.svg",
      //   submenu: [
      //     {
      //       label: "Documents",
      //       href: "#",
      //       icon: "images/overview/myprofile.svg",
      //     },
      //     {
      //       label: "Youtube Tutorials",
      //       href: "#",
      //       icon: "images/overview/myprofile.svg",
      //     },
      //     {
      //       label: "Contact Support",
      //       href: "#",
      //       icon: "images/sidebar_img/contact.svg",
      //     },
      //   ],
      //   enabled: true,
      // },
      // {
      //   label: "plus",
      //   visible: false,
      //   href: "#",
      //   icon: "images/overview/btn.svg",
      //   submenu: [
      //     {
      //       label: "Create Deals",
      //       href: "/createnewdeal",
      //       icon: "/images/sidebar_img/remit.svg",
      //     },
      //     {
      //       label: "Create Orders",
      //       href: "/createorder",
      //       icon: "/images/sidebar_img/remit.svg",
      //     },
      //     {
      //       label: "View Orders",
      //       href: "/remit/orders",
      //       icon: "/images/sidebar_img/remit.svg",
      //     },
      //     {
      //       label: "Create FX Quote",
      //       href: "/createforexQuotation",
      //       icon: "/images/sidebar_img/remit.svg",
      //     },
      //     {
      //       label: "Create Remit Quote",
      //       href: "/createRemittanceQuotation",
      //       icon: "/images/sidebar_img/remit.svg",
      //     },
      //   ],
      //   enabled: true,
      // },
    ],
  };

  useEffect(() => {
    setIsRowDetailsOpen(!!id && !!product && !!create_date);
  }, []);

  useEffect(() => {
    const img = new Image();
    img.src = greeting().image;
    img.onload = () => {
      setLoadImg(false);
    };
  }, [greeting().image]);
  return (
    // skipcq: JS-0415
    <>
      {id && product && create_date && (
        <RowDetailsSheet
          isOpen={isRowDetailsOpen}
          onOpenChange={setIsRowDetailsOpen}
          rowData={{
            id,
            product,
            createDate: getRelativeDate(Number(create_date)),
          }}
          getOpportunityList={() => {}}
        />
      )}
      <div className="bg-gray-100 flex h-full sm:flex-col md:flex-col flex-row font-inter sm:gap-5 md:gap-5 items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className="bg-gray-100 flex flex-1 gap-[15px] flex-col h-screen font-inter items-start justify-start w-full">
          <DashboardNavbar navLinks={navLinks} />
          <section className="flex w-full h-full flex-col flex-1 md:flex-col overflow-hidden gap-4 sm:gap-2.5">
            <SearchAndTab />
            <div className="flex h-full md:flex-col overflow-hidden gap-4 sm:gap-2.5">
              <div className="w-1/2 sm:gap-2.5 sm:flex sm:flex-col md:w-full bg-white-A700 sm:bg-transparent rounded-xl gap-7 sm:py-1 sm:px-0 py-3 px-4 ">
                {/* <div className="sm:flex hidden w-full bg-white-A700 rounded-xl p-1 gap-1">
                  <button
                    onClick={() => setTabSwitch(!tabSwitch)}
                    className={`w-full ${!tabSwitch && tabSwitchActiveStyle}`}
                  >
                    Stats
                  </button>
                  <button
                    onClick={() => setTabSwitch(!tabSwitch)}
                    className={`w-full ${tabSwitch && tabSwitchActiveStyle}`}
                  >
                    Tasks
                  </button>
                </div> */}
                <ScrollArea
                  className="scroll-smooth"
                  ref={scrollRef}
                  data-state="visible"
                  onScrollCapture={useCallback(
                    () => setShowScrollIndicator(false),
                    [],
                  )}
                >
                  <div
                    className={`${tabSwitch ? "sm:hidden" : "sm:flex flex-col gap-2.5"} relative sm:px-0 lg:h-[70vh] h-[75vh] scroll-container hide-scrollbar overflow-auto `}
                  >
                    <div className="flex font-inter items-start justify-start py-5 sm:py-0 w-full">
                      {
                        <div className="flex w-full sm:bg-white-A700 sm:rounded-xl flex-col items-center sm:p-3 justify-start gap-3.5">
                          <div className="flex w-full text-sm justify-between">
                            <span className="">Stats</span>
                            <span
                              role="button"
                              aria-hidden
                              onClick={() => setShowStats(!showStats)}
                              className="cursor-pointer text-[#165FE3]"
                            >
                              {!showStats ? "Hide" : "Show"}
                            </span>
                          </div>
                          {!showStats && (
                            <div className="flex border-b pb-5 flex-wrap justify-between gap-3">
                              {state?.stats?.map((item: any, index: number) => {
                                return (
                                  <div
                                    key={index}
                                    className=" w-[48%] bg-white-A700 rounded-xl border gap-[14px] py-4 px-5"
                                  >
                                    <div className="bg-white-A700 flex flex-1 flex-col gap-[5px] items-start justify-center rounded-[10px] w-full">
                                      <Text
                                        className="text-blue-700 text-sm w-full"
                                        size="txtInterSemiBold14"
                                      >
                                        {item.name}
                                      </Text>
                                      <Text
                                        className=" text-gray-900"
                                        size="txtInterSemiBold32"
                                      >
                                        {item.name === "Number of Orders"
                                          ? item.today
                                          : formatIndianNumber(
                                              parseFloat(item.today)?.toFixed(
                                                4,
                                              ),
                                            )}
                                      </Text>
                                      <div className="flex flex-row gap-[5px] items-center justify-between w-full">
                                        <Text
                                          className="text-gray-600_01 text-xs w-auto"
                                          size="txtInterRegular12Gray60001"
                                        >
                                          Today
                                        </Text>
                                        <Text
                                          className="text-gray-600_01 text-xs w-auto"
                                          size="txtInterSemiBold12"
                                        >
                                          {item.name === "Number of Orders"
                                            ? item.today
                                            : "₹" +
                                              formatIndianNumber(
                                                parseFloat(item.today)?.toFixed(
                                                  4,
                                                ),
                                              )}
                                        </Text>
                                      </div>
                                      <div className="flex flex-row gap-[5px] items-center justify-between w-full">
                                        <Text
                                          className="text-gray-600_01 text-xs w-auto"
                                          size="txtInterRegular12Gray60001"
                                        >
                                          This Week
                                        </Text>
                                        <Text
                                          className="text-gray-600_01 text-xs w-auto"
                                          size="txtInterSemiBold12"
                                        >
                                          {item.name === "Number of Orders"
                                            ? item.week
                                            : "₹" +
                                              formatIndianNumber(
                                                parseFloat(item.week)?.toFixed(
                                                  4,
                                                ),
                                              )}
                                        </Text>
                                      </div>
                                      <div className="flex flex-row gap-[5px] items-center justify-between w-full">
                                        <Text
                                          className="text-gray-600_01 text-xs w-auto"
                                          size="txtInterRegular12Gray60001"
                                        >
                                          This Month
                                        </Text>
                                        <Text
                                          className="text-gray-600_01 text-xs w-auto"
                                          size="txtInterSemiBold12"
                                        >
                                          {item.name === "Number of Orders"
                                            ? item.week
                                            : "₹" +
                                              formatIndianNumber(
                                                parseFloat(item.month)?.toFixed(
                                                  4,
                                                ),
                                              )}
                                        </Text>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                              {state?.deal_stats?.map(
                                (item: any, index: number) => {
                                  return (
                                    <div
                                      key={index}
                                      className="w-[48%] bg-white-A700 rounded-xl border gap-3 py-4 px-5"
                                    >
                                      <div className="bg-white-A700 flex flex-1 flex-col gap-[5px] items-start justify-center rounded-[10px] w-full">
                                        <Text
                                          className="text-blue-700 text-sm w-full"
                                          size="txtInterSemiBold14"
                                        >
                                          {item.name}
                                        </Text>
                                        <Text
                                          className="md:text-3xl sm:text-[28px] text-[32px] text-red-500 w-[99px]"
                                          size="txtInterSemiBold32"
                                        >
                                          {item.name === "Number of Orders"
                                            ? item.today
                                            : formatIndianNumber(
                                                parseFloat(item.today)?.toFixed(
                                                  4,
                                                ),
                                              )}
                                        </Text>
                                        <div className="flex flex-row gap-[5px] items-center justify-between w-full">
                                          <Text
                                            className="text-gray-600_01 text-xs w-auto"
                                            size="txtInterRegular12Gray60001"
                                          >
                                            Today
                                          </Text>
                                          <Text
                                            className="text-red-500 text-xs w-auto"
                                            size="txtInterSemiBold12"
                                          >
                                            {item.today}
                                          </Text>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                },
                              )}
                              {/* <div className="bg-white-A700 flex flex-1 flex-col gap-[5px] items-start justify-center p-5 rounded-[10px] w-full">
                              <Text
                                className="text-gray-600_01 text-sm w-full"
                                size="txtInterSemiBold14"
                              >
                                Gross Income
                              </Text>
                              <Text
                                className="md:text-3xl sm:text-[28px] text-[32px] text-gray-900 w-[99px]"
                                size="txtInterSemiBold32"
                              >
                                ₹25.1k
                              </Text>
                              <div className="flex flex-row gap-[5px] items-center justify-between w-full">
                                <Text
                                  className="text-gray-600_01 text-xs w-auto"
                                  size="txtInterRegular12Gray60001"
                                >
                                  This Week
                                </Text>
                                <Text
                                  className="text-gray-600_01 text-xs w-auto"
                                  size="txtInterSemiBold12"
                                >
                                  ₹ 3000
                                </Text>
                              </div>
                              <div className="flex flex-row gap-[5px] items-center justify-between w-full">
                                <Text
                                  className="text-gray-600_01 text-xs w-auto"
                                  size="txtInterRegular12Gray60001"
                                >
                                  This Month
                                </Text>
                                <Text
                                  className="text-gray-600_01 text-xs w-auto"
                                  size="txtInterSemiBold12"
                                >
                                  ₹ 3000
                                </Text>
                              </div>
                            </div> */}
                            </div>
                          )}
                          <div className="hidden flex-row w-[98%] items-center justify-center border border-[#BBBBBB] border-solid"></div>
                          <div className="hidden sm:flex-col md:gap-5 gap-[18px] sm:grid-cols-1 md:grid-cols-2 grid-cols-4 justify-center min-h-[auto] w-full">
                            {state?.deal_stats?.map(
                              (item: any, index: number) => {
                                return (
                                  <div
                                    key={index}
                                    className="w-[48%] bg-white-A700 rounded-xl border gap-[14px] py-4 px-5"
                                  >
                                    <div className="bg-white-A700 flex flex-1 flex-col gap-[5px] items-start justify-center rounded-[10px] w-full">
                                      <Text
                                        className="text-blue-700 text-sm w-full"
                                        size="txtInterSemiBold14"
                                      >
                                        {item.name}
                                      </Text>
                                      <Text
                                        className="md:text-3xl sm:text-[28px] text-[32px] text-gray-900 w-[99px]"
                                        size="txtInterSemiBold32"
                                      >
                                        {item.name === "Number of Orders"
                                          ? item.today
                                          : formatIndianNumber(
                                              parseFloat(item.today)?.toFixed(
                                                4,
                                              ),
                                            )}
                                      </Text>
                                      <div className="flex flex-row gap-[5px] items-center justify-between w-full">
                                        <Text
                                          className="text-gray-600_01 text-xs w-auto"
                                          size="txtInterRegular12Gray60001"
                                        >
                                          Today
                                        </Text>
                                        <Text
                                          className="text-gray-600_01 text-xs w-auto"
                                          size="txtInterSemiBold12"
                                        >
                                          {item.today}
                                        </Text>
                                      </div>
                                    </div>
                                  </div>
                                );
                              },
                            )}
                          </div>
                        </div>
                      }
                    </div>
                    {/* <div
                      className={`${tabSwitch && "sm:hidden"} gap-3.5 sm:bg-white-A700 sm:p-3 sm:rounded-xl flex flex-col`}
                    >
                      <div className="flex w-full text-sm justify-between">
                        <span className="">Recent Operations</span>
                        <span className="cursor-pointer text-[#165FE3]"></span>
                      </div>
                      <div className="border p-3 rounded-xl">
                        <ol className=" gap-8 flex flex-col">
                          {recentTaskData.map((data, key: number) => {
                            return (
                              <li key={crypto.randomUUID()}>
                                <div className="flex items-center justify-center font-medium w-full  ">
                                  <div
                                    className={`${key === recentTaskData.length - 1 && "after:border-none"} after:h-9 after:border-[#669CFF] after:relative after:content-[''] after:border-[0.6px] after:border-dashed after:right-3 after:top-9 after:block w-8 h-8 rounded-full flex justify-center items-center mr-3 text-sm text-white lg:w-10 lg:h-10`}
                                  >
                                    <img
                                      className=""
                                      src={`images/overview/${key === 0 ? "recent-step" : "recent-step-gray"}.svg`}
                                      alt=""
                                    />
                                  </div>
                                  <div className=" flex-1 text-sm font-normal items-center flex justify-between">
                                    <div className="flex justify-between items-center gap-4 sm:gap-2">
                                      <span className="text-[#333333] sm:w-28 font-medium">
                                        {data.deal}
                                        {"-"}
                                        {data.status}
                                      </span>
                                      <span className=" py-[6px] px-2 rounded-lg bg-[#FFE1E1] text-[#FF3E3E]">
                                        {data.tag}
                                      </span>
                                    </div>
                                    <span className="text-[#999999]">
                                      {data.time}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ol>
                      </div>
                    </div> */}
                    {showScrollIndicator && (
                      <div className=" absolute bottom-0 w-full">
                        <div className="bg-custom-stats-scroll-gradient h-56 sm:h-40">
                          <div
                            aria-hidden
                            onClick={handleScrollDown}
                            className=" cursor-pointer flex flex-col justify-center items-center"
                          >
                            <span className=" text-[#98BDFF] font-semibold text-xs">
                              Scroll down
                            </span>
                            <img src="images/overview/down.svg" alt="" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </ScrollArea>
              </div>
              {/* task section */}
              <div
                className={`${!tabSwitch && "sm:hidden"} overflow-y-scroll h-[90vh] sm:mx-0 bg-white-A700 rounded-xl flex-1 flex flex-col gap-4 sm:py-0 py-3 px-4`}
              >
                <Task />
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* {level != "1" &&
        (showPopup ? (
          <div className=" fixed bottom-4 right-6  h-[125.35px] flex w-[116px]  flex-col p-[8px] rounded-[12px] bg-[#FFFFFF] shadow-md ">
            <div className="absolute top-[10px] right-[10px]">
              <img
                className="w-[10px] h-[12px] cursor-pointer"
                src="images/img_cross.svg"
                onClick={handleClosePopup}
                alt="icon"
              ></img>
            </div>
            <div className="flex justify-center items-center flex-col gap-[8px]">
              <img
                className="cursor-pointer w-[57.35px] h-[57.35px] "
                src="images/user_popup.svg"
                onClick={handleOpenChecklist}
              />
              <Text
                className="text-xs text-center text-black-900 tracking-[0.16px] w-auto"
                size="txtInterMedium16Black900"
              >
                Getting Started with Maxx
              </Text>
            </div>
          </div>
        ) : (
          <div className=" fixed bottom-4 right-6  h-[81.35px] flex w-[81.35px]  flex-col p-[8px] rounded-[12px] ">
            <div className="flex justify-end">
              {" "}
              <img
                className="w-[10px] h-[12px] cursor-pointer"
                src="images/img_linetwo.svg"
                onClick={handleClosePopup}
              />
            </div>

            <img
              className="w-[57.35px] h-[57.35px] "
              src="images/user_popup.svg"
              onClick={handleOpenChecklist}
            />
          </div>
        ))}
      {showCheckList && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          onClick={() => setshowCheckList(false)}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          <OnboardingList />
        </div>
      )} */}
      <Toaster theme="dark" position="top-right" />
    </>
  );
};

export default DashboardPage;
