import { OpportunitiesType, Option } from "pages/Opportunities/types";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { useAppDispatch, useAppSelector } from "redux/hooks";

import { AxiosRequestConfig } from "axios";
import {
  FieldVisibility,
  pipelineFeatureRules,
} from "pages/Opportunities/utils/featureRule";
import {
  setAssignedTo,
  setMoneefloBranch,
  setProductList,
} from "redux/features/CreateOpportunity";
import { getChannel, GetChannelRequestType } from "service/api";
import {
  getBranchList,
  GetBranchRegistrationRequestType,
} from "service/apis/BranchRegistration";
import { getProducts } from "service/apis/Opportunities";
import {
  getRMByProductID,
  GetRMByProductIDRequestType,
} from "service/apis/PartnerRegistrationapi";
import { is } from "date-fns/locale";

interface ContactDetailsProps {
  errors: Record<string, string>;
}

const teamOptions: Option[] = [
  { value: "central operations", label: "Central Operations" },
];

const teamMemberOptions: Option[] = [
  { value: "kirti", label: "Kirti" },
  { value: "neha", label: "Neha" },
  { value: "shivani", label: "Shivani" },
];

export default function OpportunityDetails({ errors }: ContactDetailsProps) {
  const [typeOptions, setTypeOptions] = useState<Option[]>([]);
  const [assignedToOptions, setAssignedToOptions] = useState<Option[]>([]);
  const [branchList, setBranchList] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [selectedOption2, setSelectedOption2] = useState<Option | null>(null);
  const [opportunitiesMap, setOpportunitiesMap] = useState<OpportunitiesType[]>(
    [],
  );
  const [optionWithOwner, setOptionWithOwner] = useState<Option | null>(null);
  const [partnerLocationOption, setPartnerLocationOption] = useState<
    Option[] | null
  >(null);

  const {
    product_list,
    pipelineType,
    refferal_source,
    team_member,
    assigned_to,
    product_purpose,
    moneeflo_branch,
    product,
  } = useAppSelector((state) => state.CreateOpportunity);
  const dispatch = useAppDispatch();

  const fetchPartner = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetChannelRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        per_page: 1000,
      },
    };
    try {
      const res = (await getChannel(payload)) as any;
      console.log(res);
      if (res?.data?.items?.length === 0) {
        return;
      }
      const partner = res?.data?.items.filter(
        (item: any) =>
          (item?.status === "active" || item?.status === "pending") &&
          item?.approval_status === "approved",
      );
      const options = partner?.map((item: any) => ({
        value: item?.id,
        label: item?.name,
      }));
      return options;
    } catch (err) {
      console.error(err);
    }
  };

  const dottedBorderWidth = "after:h-28";
  const myself = localStorage.getItem("staff_id");

  const fetchProductList = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload = {
      headers: { Authorization: `Bearer ${authToken}` },
    };
    try {
      const response = await getProducts(payload);
      if (response?.data?.length === 0) {
        return;
      }
      const products = response?.data?.map((product: any) => ({
        value: product.id,
        label: product.name,
      }));
      setTypeOptions(() => {
        const filteredProducts = products.filter(
          (product) =>
            !product_list.some((item) => item.product_id === product.value),
        );
        return filteredProducts;
      });
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchRelationShipManager() {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    // API call
    const req: AxiosRequestConfig<GetRMByProductIDRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        product_id: product_purpose,
      },
    };

    try {
      const res = await getRMByProductID(req);

      if (res?.data?.length === 0) {
        return;
      }

      const options = res?.data?.map((item: any) => ({
        value: item.id,
        label: item.fname,
      }));

      const index = options.findIndex((item) => item?.value == myself);
      if (index > 0) {
        const item = options.splice(index, 1)[0];
        options.unshift(item);
      }
      setAssignedToOptions(options);
      dispatch(setAssignedTo(options[0]?.value));
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  }

  const handleClickAddAnother = () => {
    setOpportunitiesMap([
      ...opportunitiesMap,
      {
        product_id: selectedOption?.value,
        type: selectedOption?.label ?? "",
        assigned_to: selectedOption2?.label,
      },
    ]);
    dispatch(
      setProductList([
        ...product_list,
        {
          product_id: selectedOption?.value as string,
          subject: selectedOption?.label ?? "",
          assignedTo: selectedOption2?.value as string,
        },
      ]),
    );
    setTypeOptions(
      typeOptions.filter((item) => item.value !== selectedOption?.value),
    );
    setTypeOptions((prev) =>
      prev.filter((item) => item.value !== selectedOption?.value),
    );
    setSelectedOption(null);
    setSelectedOption2(optionWithOwner);
  };

  const fetchStaffBranches = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetBranchRegistrationRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        type: "",
      },
    };

    try {
      const res = await getBranchList(payload);
      if (res.status === 200) {
        const branches = res.data;
        const filterActiveBranches = branches.filter(
          (branch: any) => branch.status === "active",
        );
        const options = filterActiveBranches.map((branch: any) => ({
          value: branch.id,
          label: branch.name,
        }));
        dispatch(setMoneefloBranch(options[0].value));
        setBranchList(options);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fieldVisibility: Partial<FieldVisibility> =
    pipelineFeatureRules[pipelineType] || {};

  useEffect(() => {
    setAssignedToOptions([]);
    fetchProductList();
    fetchRelationShipManager();
    if (product === "forex") {
      fetchStaffBranches();
    }
  }, []);

  return (
    <form className="flex flex-col gap-10" onSubmit={() => {}}>
      <div className="flex relative top-0 flex-col sm:pl-0 pl-10 gap-10 w-full max-w-md ">
        {product === "forex" && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10 absolute ${product_purpose ? "after:h-28 after:border-blue-700" : "after:h-12"} after:relative  after:content-[''] after:border after:border-dashed`}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label htmlFor="assigned_to" className="text-sm">
              Search & select branch.
            </label>
            {product_purpose && (
              <ReactSelect
                className="w-full"
                placeholder="Search & Select here"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    zIndex: 0,
                    borderRadius: ".5rem",
                    padding: ".3rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderColor: state.isFocused ? "#165FE3" : "#333333",
                    boxShadow: "none",
                    height: "3rem",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 1000,
                  }),
                }}
                options={branchList}
                value={branchList.find(
                  (branch) => branch.value === moneeflo_branch,
                )}
                onChange={(val) => {
                  dispatch(setMoneefloBranch(val?.value as string));
                }}
                isSearchable
              />
            )}
          </div>
        )}
        {/* <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${dottedBorderWidth} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label htmlFor="product" className="text-sm">
            Select the Team.
          </label>
          <ReactSelect
            className="w-full"
            isDisabled
            placeholder="Select the team"
            menuPosition="fixed"
            styles={{
              control: (provided, state) => ({
                ...provided,
                zIndex: 0,
                borderRadius: ".5rem",
                padding: ".3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderColor: state.isFocused ? "#165FE3" : "#333333",
                boxShadow: "none",
                height: "3rem",
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 1000,
              }),
            }}
            defaultValue={teamOptions[0]}
            value={teamOptions[0]}
            options={teamOptions}
            name="product"
            id="product"
          />
        </div> */}
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label htmlFor="assigned_to" className="text-sm">
            Search and select the team member.
          </label>
          <ReactSelect
            className="w-full"
            placeholder="search and select the team member"
            menuPlacement="auto"
            menuPosition="fixed"
            defaultValue={teamMemberOptions[0]}
            styles={{
              control: (provided, state) => ({
                ...provided,
                zIndex: 0,
                borderRadius: ".5rem",
                padding: ".3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderColor: state.isFocused ? "#165FE3" : "#333333",
                boxShadow: "none",
                height: "3rem",
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 1000,
              }),
            }}
            value={
              assignedToOptions.find((item) => item?.value === assigned_to) ??
              null
            }
            onChange={(val) => dispatch(setAssignedTo(val?.value as string))}
            options={assignedToOptions}
            name="assigned_to"
            id="assigned_to"
          />
        </div>
      </div>
    </form>
  );
}
